import { ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@material-ui/core'
import React, { useCallback } from 'react'

export interface PermissionListItemProps {
  readonly checked: boolean
  readonly permission: string
  readonly children: React.ReactNode
  readonly onChange: (args: { permission: string; checked: boolean }) => void
}
export const PermissionListItem = ({ checked, permission, children, onChange }: PermissionListItemProps) => {
  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChange({ permission, checked: e.target.checked })
    },
    [permission, onChange]
  )
  return (
    <ListItem>
      <ListItemText primary={children} />
      <ListItemSecondaryAction>
        <Switch color="primary" checked={checked} onChange={handleChange} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
