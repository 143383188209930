import { useEffect } from 'react'
import { gotoAccessDenied } from '../goto-login'
import { useMe } from './useMe'
const Protected = ({
  allowedRoles,
  children
}: {
  allowedRoles: (
    'IDENTITYSERVER_SUPERUSER'
    | 'IDENTITYSERVER_APPLICATION_ADMIN'
    | 'IDENTITYSERVER_ORGANIZATION_ADMIN'
    | 'IDENTITYSERVER_LICENSE_ADMIN'
    | 'IDENTITYSERVER_USER_ADMIN'
    | 'IDENTITYSERVER_SERVICE_ADMIN'
    | 'IDENTITYSERVER_ROLE_ADMIN'
  )[]
  children: JSX.Element
}) => {
  const me = useMe()

  useEffect(() => {
    if (me.authenticated) {
      const permissions = me.permissions
      const isAllowedAccess = allowedRoles.some(role => me.permissions?.includes(role))
      if (permissions && !isAllowedAccess) {
        gotoAccessDenied()
      }
    }
  }, [me.authenticated])

  return children
}
export default Protected
