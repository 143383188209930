import { Box, Button, ButtonProps, CircularProgress, Fade, makeStyles, Paper, Popper, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React, { useRef, useState } from 'react'

export type ButtonAsyncClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>
export const ButtonAsync = ({
  children,
  disabled,
  onClick,
  enablePortal,
  showError,
  ...props
}: Omit<ButtonProps, 'onClick'> & { onClick: ButtonAsyncClickHandler; enablePortal?: boolean; showError?: boolean }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [errorActive, setErrorActive] = useState(false)
  const button = useRef<Element>()
  return (
    <>
      <Button
        disabled={disabled || loading}
        buttonRef={button}
        onClick={async (e) => {
          try {
            setLoading(true)
            setErrorActive(false)
            await onClick(e)
          } catch (ex) {
            setError(ex)
            setErrorActive(true)
          } finally {
            setLoading(false)
          }
        }}
        {...props}
      >
        {children}
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
      {showError ? (
        <Popper open={errorActive} placement="bottom" anchorEl={button.current} transition disablePortal={!enablePortal}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Box padding={2}>
                  <Typography>
                    <strong>Oops</strong>
                    <br />
                    <div className={classes.errorDetails}>{error?.message}</div>
                  </Typography>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      ) : null}
    </>
  )
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorDetails: {
    whiteSpace: `pre-wrap`,
  },
}))
