import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { gotoLogin } from './goto-login'
import { useMe } from './shared/useMe'
import { Wrapper } from './layout/wrapper'
import { ProgressMessage } from './shared/progress-message'

export const RedirectToHome = () => {
  const me = useMe()
  const requiresAuthentication = me.authenticated === false
  useEffect(() => {
    if (requiresAuthentication) {
      gotoLogin()
    }
  }, [requiresAuthentication])

  if (me.isOrganizationAdmin) {
    return <Navigate to={`/organizations`} replace />
  } else if (me.isUserAdmin) {
    return <Navigate to={`/organization/${me.organizationId}/tenant/${me.tenantId}/users`} replace />
  } else if (me.subjectId) {
    return <Navigate to={`/organization/${me.organizationId}/tenant/${me.tenantId}/apps-tiles`} replace />
  } else {
    return (
      <Wrapper title="Home">
        <ProgressMessage>One moment...</ProgressMessage>
      </Wrapper>
    )
  }
}
