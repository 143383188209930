import { Button } from '@material-ui/core'
import { MFieldInput, MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  namedOperations,
  useDeleteLicenseTemplateMutation,
  useEditLicenseTemplateMutation,
  useLicenseTemplatesQuery,
  usePermissionsForLicenseQuery,
  usePermissionsPerApplicationQuery,
} from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage, QueryErrorMessage } from '../shared/apollo'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldMultiSelect } from '../shared/mfield-multiselect'
import { RouterLink } from '../shared/router-link'
import { defined } from '../shared/typescript'
import { useLocalState } from '../utils'

type ApplicationForm = {
  name: string
  permissions: string[]
}

export const LicenseTemplateEditDialog = () => {
  const { t } = useTranslation()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const { templateId } = useParams() as { templateId: string }
  const navigate = useNavigate()
  const licenseQuery = useLicenseTemplatesQuery()
  const license = licenseQuery.data?.licenseTemplates?.find((x) => x?.id === templateId)

  const licPermissionsQuery = LicenseTemplateEditDialog.useLicensedPermissionsPerAppQuery()
  const licPermissions = licPermissionsQuery.data

  const [modify, modifyMutation] = useEditLicenseTemplateMutation()
  const handleSave = async (form: ApplicationForm) => {
    const updated = await modify({
      variables: {
        templateId: Number(match.params.templateId),
        name: form.name,
        permissions: form.permissions,
      },
    })

    if (updated.data?.editLicenseTemplate) {
      alerts.push(alertText.updated(`License Template`), `success`)
      handleClose()
    }
  }

  const [deleteTemplate, deleteTemplateState] = useDeleteLicenseTemplateMutation({ refetchQueries: [namedOperations.Query.LicenseTemplates] })

  const handleClose = () => {
    navigate(`/licensetemplates`, { replace: true })
  }

  const [initialValues] = useLocalState<ApplicationForm>(
    () => ({
      name: license?.name ?? ``,
      permissions: license?.permissions?.filter(defined) ?? [],
    }),
    [license]
  )

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSave}>
        <MPortalDialog.Dialog open onClose={handleClose}>
          <SavingSwitchPanel mutations={[modifyMutation, deleteTemplateState]}>
            <Form>
              <MPortalDialog.Header onClose={handleClose}>
                <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  Edit License Template
                </MFlexBlock>
              </MPortalDialog.Header>
              <MPortalDialog.Content>
                <QueryErrorMessage query={licenseQuery} />
                <MutationErrorMessage mutation={modifyMutation} />
                <MutationErrorMessage mutation={deleteTemplateState} />
                <Field component={MFieldConnector} name="name" label={t(`Template Name`)}>
                  <MFieldInput autoFocus autoComplete="off" />
                </Field>
                <Field component={MFieldConnector} name="permissions" label={t(`Included Permissions`)}>
                  <MFieldMultiSelect items={licPermissions ?? []} suggestions={licPermissions ?? []} label="" onSearch={() => {}} search="" />
                </Field>
              </MPortalDialog.Content>
              <MPortalDialog.Footer>
                <MFlexBlock justifyContent="space-between">
                  <Button component={RouterLink} color="primary" to={`/licensetemplate/${templateId}/delete`}>
                    Delete Template
                  </Button>
                  <MPortalDialog.SubmitButton>Edit</MPortalDialog.SubmitButton>
                </MFlexBlock>
              </MPortalDialog.Footer>
            </Form>
          </SavingSwitchPanel>
        </MPortalDialog.Dialog>
      </Formik>
    </>
  )
}

LicenseTemplateEditDialog.useLicensedPermissionsPerAppQuery = () => {
  const { t } = useTranslation()

  const licPermissionQuery = usePermissionsForLicenseQuery({})
  const licPermissions = licPermissionQuery.data?.licensedPermissions?.filter(defined).map((x) => ({ id: x.id ?? `-`, note: x.note ?? `-` }))

  const allPermissionsQuery = usePermissionsPerApplicationQuery()
  const allPermissions = allPermissionsQuery.data?.applications?.flatMap(
    (y) => y?.permissions?.filter(defined).map((x) => ({ id: x, name: t(x), group: t(y?.name ?? `-`) })) ?? []
  )

  const licensedPermissionsPerApp = licPermissions?.flatMap(
    (x) => allPermissions?.filter((y) => y.id === x.id).map((y) => ({ id: x.id, name: t(x.id), group: y?.group })) ?? []
  )

  return { data: licensedPermissionsPerApp }
}
