import { CardContent, Button } from '@material-ui/core'
import React from 'react'
import Alert from '@material-ui/lab/Alert'

export const CardErrorContent = ({ error, onRetry }: { error?: string; onRetry?: () => void }) => {
  return error ? (
    <CardContent>
      <Alert
        severity="error"
        action={
          onRetry && (
            <Button color="inherit" size="small" onClick={onRetry}>
              Retry
            </Button>
          )
        }
      >
        {error}
      </Alert>
    </CardContent>
  ) : null
}
