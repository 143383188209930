import { Box, Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { GlobalApplicationsQuery, namedOperations, useGlobalApplicationsQuery, useUpdateApplicationMutation } from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { CardErrorContent } from '../shared/card-error-content'
import { defined, NotOptional } from '../shared/typescript'
import { SetProperty, useLocalState } from '../utils'

type ApplicationForm = NotOptional<NotOptional<GlobalApplicationsQuery['applications']>[0]>
const emptyApplicationItem: ApplicationForm = { name: ``, isActive: true }

export const ApplicationEditDialog = () => {
  const { applicationId } = useParams() as { applicationId: string }
  const navigate = useNavigate()
  const applicationQuery = useGlobalApplicationsQuery()
  const application = applicationQuery.data?.applications?.find((x) => x?.id === applicationId)
  const [form, setForm] = useLocalState(application ?? emptyApplicationItem, [application])

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [updateApplication, updateApplicationMutation] = useUpdateApplicationMutation({
    refetchQueries: [namedOperations.Query.MyApplications, namedOperations.Query.GlobalApplications],
  })

  const save: ButtonAsyncClickHandler = async (e) => {
    e.preventDefault()

    const updated = await updateApplication({
      variables: {
        applicationId,
        name: form.name ?? `-`,
        publicUrl: form.publicUrl ?? `-`,
        logoUrl: form.logoUrl ?? '-',
        activeUntil: form.activeUntil,
        redirectUrls: form.redirectUris?.filter(defined) ?? [],
        postLogoutRedirectUrls: form.postLogoutRedirectUris?.filter(defined) ?? [],
        allowedCorsOrigins: form.allowedCorsOrigins?.filter(defined) ?? [],
        allowedScopes: form.allowedScopes?.filter(defined) ?? [],
        permissions: form.permissions?.filter(defined) ?? [],
      },
    })

    if (updated.data?.updateApplication) {
      alerts.push(alertText.updated(`Application`), `success`)
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/applications`, { replace: true })
  }

  return (
    <>
      <MPortalDialog.Dialog minWidth="sm" open onClose={handleClose}>
        <SavingSwitchPanel mutations={[updateApplicationMutation]}>
          <MPortalDialog.Form onSubmit={save} /*onReset={onReset}*/>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {application?.name ?? application?.id ?? `Loading`}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MPortalDialog.Content>
              <Box paddingX={2}>
                <CardErrorContent error={applicationQuery.error?.message} />
                <MutationErrorMessage mutation={updateApplicationMutation} />
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField fullWidth InputLabelProps={{ shrink: true }} label="Client ID" required value={form.id} disabled />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardDatePicker
                      fullWidth
                      label="Valid Period"
                      placeholder="Application Active Until"
                      format="yyyy-MM-dd"
                      value={form.activeUntil ?? null}
                      onChange={(v) => setForm(SetProperty(`activeUntil`, v as any /*TODO*/))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth InputLabelProps={{ shrink: true }} label="Secret" value={form.secret} disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="Name"
                      value={form.name}
                      onChange={(e) => setForm(SetProperty(`name`, e.target.value))}
                      autoFocus
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Logo URL"
                      value={form.logoUrl}
                      onChange={(e) => setForm(SetProperty(`logoUrl`, e.target.value))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Public URL"
                      value={form.publicUrl}
                      onChange={(e) => setForm(SetProperty(`publicUrl`, e.target.value))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Redirect uris"
                      value={form.redirectUris?.join(`\n`)}
                      onChange={(e) => setForm(SetProperty(`redirectUris`, e.target.value.split(/\r?\n/g)))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Post logout redirect uris"
                      value={form.postLogoutRedirectUris?.join(`\n`)}
                      onChange={(e) => setForm(SetProperty(`postLogoutRedirectUris`, e.target.value.split(/\r?\n/g)))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Allowed cors origins"
                      value={form.allowedCorsOrigins?.join(`\n`)}
                      onChange={(e) => setForm(SetProperty(`allowedCorsOrigins`, e.target.value.split(/\r?\n/g)))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Allowed scopes"
                      value={form.allowedScopes?.join(`\n`)}
                      onChange={(e) => setForm(SetProperty(`allowedScopes`, e.target.value.split(/\r?\n/g)))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Permissions"
                      value={form.permissions?.join(`\n`)}
                      onChange={(e) => setForm(SetProperty(`permissions`, e.target.value.split(/\r?\n/g)))}
                      fullWidth
                      multiline
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                  </Grid>
                </Grid>
              </Box>
            </MPortalDialog.Content>
            <MPortalDialog.Footer>
              <MFlexBlock justifyContent="flex-end">
                <MPortalDialog.SubmitButton>Edit Application</MPortalDialog.SubmitButton>
              </MFlexBlock>
            </MPortalDialog.Footer>
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
    </>
  )
}
