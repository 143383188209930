import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  UUID: any;
  /** The _Any scalar is used to pass representations of entities from external services into the root _entities field for execution. Validation of the _Any scalar is done by matching the __typename and @external fields defined in the schema. */
  _Any: any;
  /** Scalar representing a set of fields. */
  _FieldSet: unknown;
};








export type Account = {
  __typename?: 'Account';
  id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  subjectId: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
  adminOf?: Maybe<Array<Maybe<Organization>>>;
  memberOf?: Maybe<Array<Maybe<IdentityTenant>>>;
  accountId: Scalars['Int'];
  verifiedTime?: Maybe<Scalars['DateTime']>;
  verificationIdempotent?: Maybe<Scalars['String']>;
  invitationTime?: Maybe<Scalars['DateTime']>;
  invitationIdempotent?: Maybe<Scalars['String']>;
  resetPasswordTime?: Maybe<Scalars['DateTime']>;
  resetPasswordIdempotent?: Maybe<Scalars['String']>;
  audits?: Maybe<Array<Maybe<AccountAudit>>>;
  externals?: Maybe<Array<Maybe<AccountExternal>>>;
  tenantUsers?: Maybe<Array<Maybe<TenantUser>>>;
  administratorOf?: Maybe<Array<Maybe<OrganizationOwner>>>;
};

export type AccountAudit = {
  __typename?: 'AccountAudit';
  accountAuditId: Scalars['Int'];
  accountId: Scalars['Int'];
  account?: Maybe<Account>;
  time: Scalars['DateTime'];
  action: AccountAuditAction;
};

export enum AccountAuditAction {
  LoginFail = 'LOGIN_FAIL',
  LoginSuccess = 'LOGIN_SUCCESS'
}

export type AccountExternal = {
  __typename?: 'AccountExternal';
  accountExternalId: Scalars['Int'];
  creationTime: Scalars['DateTime'];
  updateTime: Scalars['DateTime'];
  provider: Scalars['String'];
  providerSubjectId: Scalars['String'];
  accountId: Scalars['Int'];
  account?: Maybe<Account>;
  claims?: Maybe<Array<Maybe<AccountExternalClaim>>>;
};

export type AccountExternalClaim = {
  __typename?: 'AccountExternalClaim';
  accountExternalClaimId: Scalars['Int'];
  type: Scalars['String'];
  value: Scalars['String'];
  accountExternalId: Scalars['Int'];
  accountExternal?: Maybe<AccountExternal>;
};

export type AccountFilterInput = {
  and?: Maybe<Array<AccountFilterInput>>;
  or?: Maybe<Array<AccountFilterInput>>;
  email_contains?: Maybe<OnlyAllowContainsFilterInput>;
};

export type Application = {
  __typename?: 'Application';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  activeUntil?: Maybe<Scalars['DateTime']>;
  secret?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedCorsOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  postLogoutRedirectUris?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectUris?: Maybe<Array<Maybe<Scalars['String']>>>;
  applicationId: Scalars['Int'];
  uniqueId: Scalars['String'];
  grantType?: Maybe<Scalars['String']>;
  tenants?: Maybe<Array<Maybe<TenantApplication>>>;
};

export type ApplicationFilterInput = {
  and?: Maybe<Array<ApplicationFilterInput>>;
  or?: Maybe<Array<ApplicationFilterInput>>;
  id?: Maybe<OnlyAllowEqualsFilterInput>;
  name_contains?: Maybe<OnlyAllowContainsFilterInput>;
  secret_neq?: Maybe<OnlyAllowNotEqualsFilterInput>;
};

export type ApplicationPermission = {
  __typename?: 'ApplicationPermission';
  applicationId: Scalars['Int'];
  application?: Maybe<Application>;
  permissionId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type CosmosResource = {
  __typename?: 'CosmosResource';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  blocked: Scalars['Boolean'];
  initials?: Maybe<Scalars['String']>;
  skillIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId?: Maybe<Scalars['String']>;
  tenandId?: Maybe<Scalars['String']>;
  removed: Scalars['Boolean'];
};

export type DataPermissionInput = {
  locationIds?: Maybe<Array<Scalars['Int']>>;
  genusIds?: Maybe<Array<Scalars['Int']>>;
  jobTypeIds?: Maybe<Array<Scalars['Int']>>;
  activityIds?: Maybe<Array<Scalars['Int']>>;
};

export enum DataRecordType {
  Location = 'LOCATION',
  Genus = 'GENUS',
  JobType = 'JOB_TYPE',
  Activity = 'ACTIVITY'
}


export type IdentityServiceTokenCreated = {
  __typename?: 'IdentityServiceTokenCreated';
  secret?: Maybe<Scalars['String']>;
  token?: Maybe<TenantServiceToken>;
};

export type IdentitySubject = {
  __typename?: 'IdentitySubject';
  id?: Maybe<Scalars['ID']>;
  identity?: Maybe<Account>;
};

export type IdentityTenant = {
  __typename?: 'IdentityTenant';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  licenses?: Maybe<Array<Maybe<License>>>;
  loginMethods?: Maybe<Array<Maybe<IdentityTenantLoginMethod>>>;
  userCount: Scalars['Int'];
  users?: Maybe<TenantUserCollectionSegment>;
  roleCount: Scalars['Int'];
  roles?: Maybe<Array<Maybe<TenantRole>>>;
  serviceCount: Scalars['Int'];
  services?: Maybe<Array<Maybe<TenantService>>>;
  applications?: Maybe<Array<Maybe<TenantApplication>>>;
  licenseInUseResolver?: Maybe<LicenseSetDto>;
  tenantId: Scalars['Int'];
  uniqueId: Scalars['String'];
  organizationId: Scalars['Int'];
};


export type IdentityTenantUsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TenantUserFilterInput>;
};

export type IdentityTenantLoginMethod = {
  __typename?: 'IdentityTenantLoginMethod';
  id?: Maybe<Scalars['ID']>;
  method: Scalars['String'];
  tenant?: Maybe<IdentityTenant>;
  tenantLoginMethodId: Scalars['Int'];
  uniqueID: Scalars['String'];
  tenantId: Scalars['Int'];
};

export type License = {
  __typename?: 'License';
  id?: Maybe<Scalars['ID']>;
  activeUntil: Scalars['DateTime'];
  maxUsers: Scalars['Int'];
  permits?: Maybe<Array<Maybe<LicensePermit>>>;
  tenant?: Maybe<IdentityTenant>;
  title: Scalars['String'];
  isActive: Scalars['Boolean'];
  licenseId: Scalars['Int'];
  tenantId: Scalars['Int'];
};

export type LicenseMutation = {
  __typename?: 'LicenseMutation';
  allow?: Maybe<License>;
  deny?: Maybe<License>;
  update?: Maybe<License>;
  remove?: Maybe<License>;
};


export type LicenseMutationAllowArgs = {
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type LicenseMutationDenyArgs = {
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type LicenseMutationUpdateArgs = {
  title?: Maybe<Scalars['String']>;
  maxUsers: Scalars['Int'];
  activeUntil: Scalars['DateTime'];
};

export type LicensePermissionDto = {
  __typename?: 'LicensePermissionDto';
  id?: Maybe<Scalars['ID']>;
  permission?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  inUse: Scalars['Int'];
};

export type LicensePermissionRequirement = {
  __typename?: 'LicensePermissionRequirement';
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  permissionId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export type LicensePermit = {
  __typename?: 'LicensePermit';
  id?: Maybe<Scalars['ID']>;
  license?: Maybe<License>;
  permissionId: Scalars['String'];
  permissionNote?: Maybe<Scalars['String']>;
  licenseId: Scalars['Int'];
  permission?: Maybe<Permission>;
};

export type LicenseSetDto = {
  __typename?: 'LicenseSetDto';
  id?: Maybe<Scalars['ID']>;
  permissions?: Maybe<Array<Maybe<LicensePermissionDto>>>;
  tenant?: Maybe<IdentityTenant>;
};

export type LicenseTemplate = {
  __typename?: 'LicenseTemplate';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  licenseTemplateId: Scalars['Int'];
  permissionList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LicenseUsage = {
  __typename?: 'LicenseUsage';
  used: Scalars['Int'];
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword: Scalars['Boolean'];
  setLicensedPermissions: Scalars['Boolean'];
  createLicenseTemplate?: Maybe<LicenseTemplate>;
  editLicenseTemplate?: Maybe<LicenseTemplate>;
  deleteLicenseTemplate: Scalars['Boolean'];
  createOrganization?: Maybe<Organization>;
  createApplication?: Maybe<Application>;
  updateApplication?: Maybe<Application>;
  tenant?: Maybe<TenantMutation>;
  license?: Maybe<LicenseMutation>;
  organization?: Maybe<OrganizationMutation>;
  resetPassword: Scalars['Boolean'];
  resetPasswordResponse: Scalars['Boolean'];
  updateCosmosResource?: Maybe<CosmosResource>;
  updatePostgresResource?: Maybe<PostgresResource>;
};


export type MutationChangePasswordArgs = {
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
};


export type MutationSetLicensedPermissionsArgs = {
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateLicenseTemplateArgs = {
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationEditLicenseTemplateArgs = {
  templateId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteLicenseTemplateArgs = {
  templateId: Scalars['Int'];
};


export type MutationCreateOrganizationArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateApplicationArgs = {
  name?: Maybe<Scalars['String']>;
  activeUntil?: Maybe<Scalars['DateTime']>;
  publicUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  redirectUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  postLogoutRedirectUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedCorsOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateApplicationArgs = {
  applicationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  activeUntil?: Maybe<Scalars['DateTime']>;
  logoUrl?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  redirectUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  postLogoutRedirectUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedCorsOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedScopes?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationTenantArgs = {
  tenantId?: Maybe<Scalars['String']>;
};


export type MutationLicenseArgs = {
  licenseId: Scalars['Int'];
};


export type MutationOrganizationArgs = {
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordResponseArgs = {
  token?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationUpdateCosmosResourceArgs = {
  tenantId?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  newResourceId?: Maybe<Scalars['String']>;
  subjectId?: Maybe<Scalars['String']>;
};


export type MutationUpdatePostgresResourceArgs = {
  tenantId?: Maybe<Scalars['String']>;
  resourceId: Scalars['Int'];
  subjectId?: Maybe<Scalars['String']>;
};

export type My = {
  __typename?: 'My';
  account?: Maybe<Account>;
  user?: Maybe<TenantUser>;
  tenant?: Maybe<IdentityTenant>;
  client?: Maybe<Application>;
};

export type OnlyAllowContainsFilterInput = {
  and?: Maybe<Array<OnlyAllowContainsFilterInput>>;
  or?: Maybe<Array<OnlyAllowContainsFilterInput>>;
  contains?: Maybe<Scalars['String']>;
};

export type OnlyAllowEqualsFilterInput = {
  and?: Maybe<Array<OnlyAllowEqualsFilterInput>>;
  or?: Maybe<Array<OnlyAllowEqualsFilterInput>>;
  eq?: Maybe<Scalars['String']>;
};

export type OnlyAllowNotEqualsFilterInput = {
  and?: Maybe<Array<OnlyAllowNotEqualsFilterInput>>;
  or?: Maybe<Array<OnlyAllowNotEqualsFilterInput>>;
  neq?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  admins?: Maybe<Array<Maybe<Account>>>;
  tenants?: Maybe<Array<Maybe<IdentityTenant>>>;
  organizationId: Scalars['Int'];
  uniqueId: Scalars['String'];
  owners?: Maybe<Array<Maybe<OrganizationOwner>>>;
};

export type OrganizationFilterInput = {
  and?: Maybe<Array<OrganizationFilterInput>>;
  or?: Maybe<Array<OrganizationFilterInput>>;
  id?: Maybe<OnlyAllowEqualsFilterInput>;
};

export type OrganizationMutation = {
  __typename?: 'OrganizationMutation';
  update?: Maybe<Organization>;
  delete: Scalars['Boolean'];
  createTenant?: Maybe<IdentityTenant>;
  deleteTenant: Scalars['Boolean'];
};


export type OrganizationMutationUpdateArgs = {
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type OrganizationMutationCreateTenantArgs = {
  name?: Maybe<Scalars['String']>;
};


export type OrganizationMutationDeleteTenantArgs = {
  tenantId?: Maybe<Scalars['String']>;
};

export type OrganizationOwner = {
  __typename?: 'OrganizationOwner';
  accountId: Scalars['Int'];
  account?: Maybe<Account>;
  organizationId: Scalars['Int'];
  organization?: Maybe<Organization>;
};

export type Permission = {
  __typename?: 'Permission';
  permissionId: Scalars['String'];
  applications?: Maybe<Array<Maybe<ApplicationPermission>>>;
  roles?: Maybe<Array<Maybe<TenantRolePermission>>>;
  licensedPermissionRequirements?: Maybe<Array<Maybe<LicensePermissionRequirement>>>;
};

export type PostgresResource = {
  __typename?: 'PostgresResource';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  my?: Maybe<My>;
  licensedPermissions?: Maybe<Array<Maybe<LicensePermissionRequirement>>>;
  licenseTemplates?: Maybe<Array<Maybe<LicenseTemplate>>>;
  applications?: Maybe<Array<Maybe<Application>>>;
  tenants?: Maybe<Array<Maybe<IdentityTenant>>>;
  users?: Maybe<TenantUserCollectionSegment>;
  licenseUsage?: Maybe<LicenseUsage>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  dataPermissions?: Maybe<Array<Scalars['Int']>>;
  cosmosResources?: Maybe<Array<Maybe<CosmosResource>>>;
  postgresResources?: Maybe<Array<Maybe<PostgresResource>>>;
  _service: _Service;
  _entities: Array<Maybe<_Entity>>;
};


export type QueryApplicationsArgs = {
  where?: Maybe<ApplicationFilterInput>;
};


export type QueryTenantsArgs = {
  where?: Maybe<TenantFilterInput>;
};


export type QueryUsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  email_contains?: Maybe<Scalars['String']>;
  where?: Maybe<TenantUserFilterInput>;
};


export type QueryAccountsArgs = {
  where?: Maybe<AccountFilterInput>;
};


export type QueryOrganizationsArgs = {
  where?: Maybe<OrganizationFilterInput>;
};


export type QueryDataPermissionsArgs = {
  dataRecordType?: Maybe<Scalars['String']>;
};


export type QueryCosmosResourcesArgs = {
  tenantId?: Maybe<Scalars['String']>;
};


export type QueryPostgresResourcesArgs = {
  tenantId?: Maybe<Scalars['String']>;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};

export type RoleDto = {
  __typename?: 'RoleDto';
  tenantId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  users: Scalars['Int'];
  lastModified: Scalars['DateTime'];
};

export type TenantApplication = {
  __typename?: 'TenantApplication';
  id?: Maybe<Scalars['ID']>;
  activeUntil?: Maybe<Scalars['DateTime']>;
  application?: Maybe<Application>;
  isActive: Scalars['Boolean'];
  tenantId: Scalars['Int'];
  tenant?: Maybe<IdentityTenant>;
  applicationId: Scalars['Int'];
};

export type TenantFilterInput = {
  and?: Maybe<Array<TenantFilterInput>>;
  or?: Maybe<Array<TenantFilterInput>>;
  id?: Maybe<OnlyAllowEqualsFilterInput>;
};

export type TenantMutation = {
  __typename?: 'TenantMutation';
  createLicense?: Maybe<License>;
  createRole?: Maybe<RoleDto>;
  editRole?: Maybe<RoleDto>;
  deleteRole: Scalars['Boolean'];
  createService?: Maybe<TenantService>;
  editService?: Maybe<TenantService>;
  createServiceToken?: Maybe<IdentityServiceTokenCreated>;
  revokeServiceToken?: Maybe<TenantServiceToken>;
  updateUser?: Maybe<TenantUser>;
  update?: Maybe<IdentityTenant>;
  deleteUser: Scalars['Boolean'];
  deleteLicense: Scalars['Boolean'];
};


export type TenantMutationCreateLicenseArgs = {
  title?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TenantMutationCreateRoleArgs = {
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TenantMutationEditRoleArgs = {
  roleId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataPermissions?: Maybe<DataPermissionInput>;
};


export type TenantMutationDeleteRoleArgs = {
  roleId?: Maybe<Scalars['String']>;
};


export type TenantMutationCreateServiceArgs = {
  name?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TenantMutationEditServiceArgs = {
  serviceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TenantMutationCreateServiceTokenArgs = {
  serviceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type TenantMutationRevokeServiceTokenArgs = {
  tokenId?: Maybe<Scalars['String']>;
};


export type TenantMutationUpdateUserArgs = {
  email: Scalars['String'];
  activeUntil?: Maybe<Scalars['DateTime']>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataPermissions?: Maybe<DataPermissionInput>;
};


export type TenantMutationUpdateArgs = {
  name?: Maybe<Scalars['String']>;
  applicationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  loginMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TenantMutationDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type TenantMutationDeleteLicenseArgs = {
  organizationId?: Maybe<Scalars['String']>;
};

export type TenantRole = {
  __typename?: 'TenantRole';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  permissions?: Maybe<Array<Maybe<TenantRolePermission>>>;
  users?: Maybe<Array<Maybe<TenantUser>>>;
  tenantRoleId: Scalars['Int'];
  uniqueId: Scalars['String'];
  tenantId: Scalars['Int'];
  tenant?: Maybe<IdentityTenant>;
  services?: Maybe<Array<Maybe<TenantServiceRole>>>;
  dataPermissions?: Maybe<Array<Maybe<TenantRoleDataPermission>>>;
};

export type TenantRoleDataPermission = {
  __typename?: 'TenantRoleDataPermission';
  tenantRoleId: Scalars['Int'];
  tenantRole?: Maybe<TenantRole>;
  dataRecordType: DataRecordType;
  dataRecordId: Scalars['Int'];
};

export type TenantRolePermission = {
  __typename?: 'TenantRolePermission';
  id?: Maybe<Scalars['ID']>;
  licenseUsed?: Maybe<Scalars['Int']>;
  licenseAvailable?: Maybe<Scalars['Int']>;
  licenseTotal?: Maybe<Scalars['Int']>;
  tenantRoleId: Scalars['Int'];
  tenantRole?: Maybe<TenantRole>;
  permissionId?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
};

export type TenantService = {
  __typename?: 'TenantService';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  activeUntil?: Maybe<Scalars['DateTime']>;
  tokens?: Maybe<Array<Maybe<TenantServiceToken>>>;
  isActive: Scalars['Boolean'];
  roles?: Maybe<Array<Maybe<TenantRole>>>;
  tenantServiceId: Scalars['Int'];
  uniqueId: Scalars['String'];
  tenantId: Scalars['Int'];
  tenant?: Maybe<IdentityTenant>;
};

export type TenantServiceRole = {
  __typename?: 'TenantServiceRole';
  tenantServiceId: Scalars['Int'];
  service?: Maybe<TenantService>;
  tenantRoleId: Scalars['Int'];
  role?: Maybe<TenantRole>;
};

export type TenantServiceToken = {
  __typename?: 'TenantServiceToken';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  uniqueId: Scalars['String'];
  secret: Scalars['String'];
  tenantServiceId: Scalars['Int'];
  service?: Maybe<TenantService>;
};

export type TenantUser = {
  __typename?: 'TenantUser';
  id?: Maybe<Scalars['ID']>;
  account?: Maybe<Account>;
  tenant?: Maybe<IdentityTenant>;
  activeUntil?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  updatedDate: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  roles?: Maybe<Array<Maybe<TenantRole>>>;
  tenantUserId: Scalars['Int'];
  accountId: Scalars['Int'];
  tenantId: Scalars['Int'];
  claims?: Maybe<Array<Maybe<TenantUserClaim>>>;
  dataPermissions?: Maybe<Array<Maybe<TenantUserDataPermission>>>;
};

export type TenantUserClaim = {
  __typename?: 'TenantUserClaim';
  tenantUserClaimId: Scalars['Int'];
  type: Scalars['String'];
  value: Scalars['String'];
  tenantUserId: Scalars['Int'];
  user?: Maybe<TenantUser>;
};

export type TenantUserCollectionSegment = {
  __typename?: 'TenantUserCollectionSegment';
  items?: Maybe<Array<Maybe<TenantUser>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type TenantUserDataPermission = {
  __typename?: 'TenantUserDataPermission';
  tenantUserId: Scalars['Int'];
  user?: Maybe<TenantUser>;
  dataRecordType: DataRecordType;
  dataRecordId: Scalars['Int'];
};

export type TenantUserFilterInput = {
  and?: Maybe<Array<TenantUserFilterInput>>;
  or?: Maybe<Array<TenantUserFilterInput>>;
  tenant?: Maybe<TenantFilterInput>;
  userId?: Maybe<OnlyAllowEqualsFilterInput>;
};



/** Union of all types that key directive applied. This information is needed by the Apollo federation gateway. */
export type _Entity = Application | IdentitySubject | Account | LicenseTemplate | Organization | TenantRole | TenantService | TenantApplication | IdentityTenantLoginMethod | TenantRolePermission | IdentityTenant | TenantUser | CosmosResource | PostgresResource;


/** This type provides a field named sdl: String! which exposes the SDL of the service's schema. This SDL (schema definition language) is a printed version of the service's schema including the annotations of federation directives. This SDL does not include the additions of the federation spec. */
export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type AppsQueryVariables = Exact<{ [key: string]: never; }>;


export type AppsQuery = (
  { __typename?: 'Query' }
  & { my?: Maybe<(
    { __typename?: 'My' }
    & { tenant?: Maybe<(
      { __typename?: 'IdentityTenant' }
      & Pick<IdentityTenant, 'id'>
      & { applications?: Maybe<Array<Maybe<(
        { __typename?: 'TenantApplication' }
        & Pick<TenantApplication, 'id'>
        & { application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'id' | 'name' | 'publicUrl' | 'logoUrl'>
        )> }
      )>>> }
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { my?: Maybe<(
    { __typename?: 'My' }
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'email' | 'subjectId'>
      & { memberOf?: Maybe<Array<Maybe<(
        { __typename?: 'IdentityTenant' }
        & Pick<IdentityTenant, 'id' | 'name'>
      )>>> }
    )>, user?: Maybe<(
      { __typename?: 'TenantUser' }
      & Pick<TenantUser, 'id'>
      & { roles?: Maybe<Array<Maybe<(
        { __typename?: 'TenantRole' }
        & Pick<TenantRole, 'id'>
        & { permissions?: Maybe<Array<Maybe<(
          { __typename?: 'TenantRolePermission' }
          & Pick<TenantRolePermission, 'id'>
        )>>> }
      )>>>, tenant?: Maybe<(
        { __typename?: 'IdentityTenant' }
        & Pick<IdentityTenant, 'id' | 'name'>
        & { organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type MyApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyApplicationsQuery = (
  { __typename?: 'Query' }
  & { my?: Maybe<(
    { __typename?: 'My' }
    & { user?: Maybe<(
      { __typename?: 'TenantUser' }
      & Pick<TenantUser, 'id'>
      & { tenant?: Maybe<(
        { __typename?: 'IdentityTenant' }
        & Pick<IdentityTenant, 'id'>
        & { applications?: Maybe<Array<Maybe<(
          { __typename?: 'TenantApplication' }
          & Pick<TenantApplication, 'id'>
          & { application?: Maybe<(
            { __typename?: 'Application' }
            & Pick<Application, 'id' | 'name' | 'publicUrl' | 'logoUrl'>
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type SpecificTenantQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type SpecificTenantQuery = (
  { __typename?: 'Query' }
  & { tenants?: Maybe<Array<Maybe<(
    { __typename?: 'IdentityTenant' }
    & Pick<IdentityTenant, 'id' | 'name'>
    & { loginMethods?: Maybe<Array<Maybe<(
      { __typename?: 'IdentityTenantLoginMethod' }
      & Pick<IdentityTenantLoginMethod, 'id' | 'method'>
    )>>>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )>>> }
);

export type SpecificTenantRolesQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type SpecificTenantRolesQuery = (
  { __typename?: 'Query' }
  & { tenants?: Maybe<Array<Maybe<(
    { __typename?: 'IdentityTenant' }
    & Pick<IdentityTenant, 'id' | 'name'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'TenantRole' }
      & Pick<TenantRole, 'id' | 'name' | 'createdDate' | 'updatedDate'>
      & { permissions?: Maybe<Array<Maybe<(
        { __typename?: 'TenantRolePermission' }
        & Pick<TenantRolePermission, 'id' | 'licenseAvailable' | 'licenseUsed' | 'licenseTotal'>
      )>>>, users?: Maybe<Array<Maybe<(
        { __typename?: 'TenantUser' }
        & Pick<TenantUser, 'id'>
      )>>> }
    )>>> }
  )>>> }
);

export type SpecificTenantServicesQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type SpecificTenantServicesQuery = (
  { __typename?: 'Query' }
  & { tenants?: Maybe<Array<Maybe<(
    { __typename?: 'IdentityTenant' }
    & Pick<IdentityTenant, 'id'>
    & { services?: Maybe<Array<Maybe<(
      { __typename?: 'TenantService' }
      & Pick<TenantService, 'id' | 'name' | 'activeUntil' | 'isActive'>
      & { roles?: Maybe<Array<Maybe<(
        { __typename?: 'TenantRole' }
        & Pick<TenantRole, 'id'>
      )>>>, tokens?: Maybe<Array<Maybe<(
        { __typename?: 'TenantServiceToken' }
        & Pick<TenantServiceToken, 'id' | 'active' | 'name'>
      )>>> }
    )>>> }
  )>>> }
);

export type SpecificTenantApplicationsQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type SpecificTenantApplicationsQuery = (
  { __typename?: 'Query' }
  & { tenants?: Maybe<Array<Maybe<(
    { __typename?: 'IdentityTenant' }
    & Pick<IdentityTenant, 'id'>
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'TenantApplication' }
      & Pick<TenantApplication, 'id' | 'isActive'>
      & { application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'id' | 'name' | 'permissions'>
      )> }
    )>>> }
  )>>> }
);

export type SpecificTenantUsersQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<TenantUserFilterInput>;
}>;


export type SpecificTenantUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'TenantUserCollectionSegment' }
    & Pick<TenantUserCollectionSegment, 'totalCount'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'TenantUser' }
      & Pick<TenantUser, 'id' | 'activeUntil' | 'isActive' | 'createdDate' | 'updatedDate'>
      & { account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'email' | 'subjectId'>
      )>, tenant?: Maybe<(
        { __typename?: 'IdentityTenant' }
        & Pick<IdentityTenant, 'id'>
      )>, roles?: Maybe<Array<Maybe<(
        { __typename?: 'TenantRole' }
        & Pick<TenantRole, 'id' | 'name'>
      )>>> }
    )>>>, pageInfo: (
      { __typename?: 'CollectionSegmentInfo' }
      & Pick<CollectionSegmentInfo, 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type GlobalApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'name' | 'publicUrl' | 'logoUrl' | 'secret' | 'permissions' | 'activeUntil' | 'isActive' | 'allowedCorsOrigins' | 'allowedScopes' | 'postLogoutRedirectUris' | 'redirectUris'>
  )>>> }
);

export type MyTenantApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTenantApplicationsQuery = (
  { __typename?: 'Query' }
  & { organizations?: Maybe<Array<Maybe<(
    { __typename?: 'Organization' }
    & { tenants?: Maybe<Array<Maybe<(
      { __typename?: 'IdentityTenant' }
      & { applications?: Maybe<Array<Maybe<(
        { __typename?: 'TenantApplication' }
        & Pick<TenantApplication, 'id' | 'isActive'>
        & { application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'id' | 'name' | 'permissions' | 'activeUntil' | 'isActive' | 'allowedCorsOrigins' | 'allowedScopes' | 'postLogoutRedirectUris' | 'redirectUris'>
        )> }
      )>>> }
    )>>> }
  )>>> }
);

export type KnownEmailsQueryVariables = Exact<{
  text: Scalars['String'];
}>;


export type KnownEmailsQuery = (
  { __typename?: 'Query' }
  & { accounts?: Maybe<Array<Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email'>
  )>>> }
);

export type KnownApplicationsQueryVariables = Exact<{
  text: Scalars['String'];
}>;


export type KnownApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'name'>
  )>>> }
);

export type MyOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrganizationsQuery = (
  { __typename?: 'Query' }
  & { organizations?: Maybe<Array<Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { admins?: Maybe<Array<Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'email'>
    )>>>, tenants?: Maybe<Array<Maybe<(
      { __typename?: 'IdentityTenant' }
      & Pick<IdentityTenant, 'id' | 'name' | 'roleCount' | 'userCount' | 'serviceCount'>
    )>>> }
  )>>> }
);

export type AllTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTenantsQuery = (
  { __typename?: 'Query' }
  & { tenants?: Maybe<Array<Maybe<(
    { __typename?: 'IdentityTenant' }
    & Pick<IdentityTenant, 'id' | 'name' | 'roleCount' | 'userCount' | 'serviceCount'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )>>> }
);

export type AllOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllOrganizationsQuery = (
  { __typename?: 'Query' }
  & { organizations?: Maybe<Array<Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>>> }
);

export type CosmosResourcesQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type CosmosResourcesQuery = (
  { __typename?: 'Query' }
  & { cosmosResources?: Maybe<Array<Maybe<(
    { __typename?: 'CosmosResource' }
    & Pick<CosmosResource, 'id' | 'code' | 'name' | 'externalUserId'>
  )>>> }
);

export type UpdateCosmosResourceMutationVariables = Exact<{
  resourceId: Scalars['String'];
  newResourceId: Scalars['String'];
  tenantId: Scalars['String'];
  subjectId: Scalars['String'];
}>;


export type UpdateCosmosResourceMutation = (
  { __typename?: 'Mutation' }
  & { updateCosmosResource?: Maybe<(
    { __typename?: 'CosmosResource' }
    & Pick<CosmosResource, 'id' | 'code' | 'name' | 'externalUserId'>
  )> }
);

export type PostgresResourcesQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type PostgresResourcesQuery = (
  { __typename?: 'Query' }
  & { postgresResources?: Maybe<Array<Maybe<(
    { __typename?: 'PostgresResource' }
    & Pick<PostgresResource, 'id' | 'name' | 'externalUserId'>
  )>>> }
);

export type UpdatePostgresResourceMutationVariables = Exact<{
  resourceId: Scalars['Int'];
  tenantId: Scalars['String'];
  subjectId: Scalars['String'];
}>;


export type UpdatePostgresResourceMutation = (
  { __typename?: 'Mutation' }
  & { updatePostgresResource?: Maybe<(
    { __typename?: 'PostgresResource' }
    & Pick<PostgresResource, 'id' | 'name' | 'externalUserId'>
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  tenantId: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { createRole?: Maybe<(
      { __typename?: 'RoleDto' }
      & Pick<RoleDto, 'id'>
    )> }
  )> }
);

export type EditRoleMutationVariables = Exact<{
  tenantId: Scalars['String'];
  roleId: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type EditRoleMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { editRole?: Maybe<(
      { __typename?: 'RoleDto' }
      & Pick<RoleDto, 'id'>
    )> }
  )> }
);

export type DeleteRoleMutationVariables = Exact<{
  tenantId: Scalars['String'];
  roleId: Scalars['String'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & Pick<TenantMutation, 'deleteRole'>
  )> }
);

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { admins?: Maybe<Array<Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'email'>
    )>>> }
  )> }
);

export type EditOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  name: Scalars['String'];
  owners: Array<Scalars['String']> | Scalars['String'];
}>;


export type EditOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { organization?: Maybe<(
    { __typename?: 'OrganizationMutation' }
    & { update?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { organization?: Maybe<(
    { __typename?: 'OrganizationMutation' }
    & Pick<OrganizationMutation, 'delete'>
  )> }
);

export type CreateTenantMutationVariables = Exact<{
  organizationId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateTenantMutation = (
  { __typename?: 'Mutation' }
  & { organization?: Maybe<(
    { __typename?: 'OrganizationMutation' }
    & { createTenant?: Maybe<(
      { __typename?: 'IdentityTenant' }
      & Pick<IdentityTenant, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateTenantMutationVariables = Exact<{
  tenantId: Scalars['String'];
  name: Scalars['String'];
  applicationIds: Array<Scalars['String']> | Scalars['String'];
  loginMethods: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateTenantMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { update?: Maybe<(
      { __typename?: 'IdentityTenant' }
      & Pick<IdentityTenant, 'id' | 'name'>
      & { applications?: Maybe<Array<Maybe<(
        { __typename?: 'TenantApplication' }
        & Pick<TenantApplication, 'id'>
      )>>>, loginMethods?: Maybe<Array<Maybe<(
        { __typename?: 'IdentityTenantLoginMethod' }
        & Pick<IdentityTenantLoginMethod, 'id' | 'method'>
      )>>> }
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  tenantId: Scalars['String'];
  email: Scalars['String'];
  roleIds: Array<Scalars['String']> | Scalars['String'];
  activeUntil?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { updateUser?: Maybe<(
      { __typename?: 'TenantUser' }
      & Pick<TenantUser, 'id' | 'isActive'>
      & { roles?: Maybe<Array<Maybe<(
        { __typename?: 'TenantRole' }
        & Pick<TenantRole, 'id' | 'name'>
      )>>> }
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResetPasswordResponseMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordResponseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPasswordResponse'>
);

export type CreateApplicationMutationVariables = Exact<{
  name: Scalars['String'];
  activeUntil?: Maybe<Scalars['DateTime']>;
  publicUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  redirectUrls: Array<Scalars['String']> | Scalars['String'];
  postLogoutRedirectUrls: Array<Scalars['String']> | Scalars['String'];
  allowedCorsOrigins: Array<Scalars['String']> | Scalars['String'];
  allowedScopes: Array<Scalars['String']> | Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { createApplication?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
  )> }
);

export type UpdateApplicationMutationVariables = Exact<{
  applicationId: Scalars['String'];
  name: Scalars['String'];
  publicUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  activeUntil?: Maybe<Scalars['DateTime']>;
  redirectUrls: Array<Scalars['String']> | Scalars['String'];
  postLogoutRedirectUrls: Array<Scalars['String']> | Scalars['String'];
  allowedCorsOrigins: Array<Scalars['String']> | Scalars['String'];
  allowedScopes: Array<Scalars['String']> | Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateApplication?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
  )> }
);

export type LicensesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensesQuery = (
  { __typename?: 'Query' }
  & { tenants?: Maybe<Array<Maybe<(
    { __typename?: 'IdentityTenant' }
    & Pick<IdentityTenant, 'id'>
    & { licenses?: Maybe<Array<Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id' | 'activeUntil' | 'isActive' | 'maxUsers' | 'title'>
      & { permits?: Maybe<Array<Maybe<(
        { __typename?: 'LicensePermit' }
        & Pick<LicensePermit, 'id' | 'permissionNote' | 'permissionId'>
      )>>>, tenant?: Maybe<(
        { __typename?: 'IdentityTenant' }
        & Pick<IdentityTenant, 'id' | 'name'>
        & { organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'id' | 'name'>
        )> }
      )> }
    )>>> }
  )>>> }
);

export type LicenseTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseTemplatesQuery = (
  { __typename?: 'Query' }
  & { licenseTemplates?: Maybe<Array<Maybe<(
    { __typename?: 'LicenseTemplate' }
    & Pick<LicenseTemplate, 'id' | 'name' | 'permissions' | 'createdDate' | 'updatedDate'>
  )>>> }
);

export type CreateLicenseMutationVariables = Exact<{
  tenantId: Scalars['String'];
  title: Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateLicenseMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { createLicense?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id'>
    )> }
  )> }
);

export type CreateLicenseTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateLicenseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createLicenseTemplate?: Maybe<(
    { __typename?: 'LicenseTemplate' }
    & Pick<LicenseTemplate, 'id'>
  )> }
);

export type EditLicenseTemplateMutationVariables = Exact<{
  templateId: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type EditLicenseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { editLicenseTemplate?: Maybe<(
    { __typename?: 'LicenseTemplate' }
    & Pick<LicenseTemplate, 'id' | 'name' | 'permissions' | 'createdDate' | 'updatedDate'>
  )> }
);

export type DeleteLicenseTemplateMutationVariables = Exact<{
  templateId: Scalars['Int'];
}>;


export type DeleteLicenseTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLicenseTemplate'>
);

export type Modify1LicenseMutationVariables = Exact<{
  licenseId: Scalars['Int'];
  allowPermissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type Modify1LicenseMutation = (
  { __typename?: 'Mutation' }
  & { license?: Maybe<(
    { __typename?: 'LicenseMutation' }
    & { allow?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id'>
    )> }
  )> }
);

export type Modify2LicenseMutationVariables = Exact<{
  licenseId: Scalars['Int'];
  denyPermissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type Modify2LicenseMutation = (
  { __typename?: 'Mutation' }
  & { license?: Maybe<(
    { __typename?: 'LicenseMutation' }
    & { deny?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id'>
    )> }
  )> }
);

export type Modify3LicenseMutationVariables = Exact<{
  licenseId: Scalars['Int'];
  title: Scalars['String'];
  maxUsers: Scalars['Int'];
  activeUntil: Scalars['DateTime'];
}>;


export type Modify3LicenseMutation = (
  { __typename?: 'Mutation' }
  & { license?: Maybe<(
    { __typename?: 'LicenseMutation' }
    & { update?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id' | 'activeUntil' | 'isActive' | 'maxUsers' | 'title'>
      & { permits?: Maybe<Array<Maybe<(
        { __typename?: 'LicensePermit' }
        & Pick<LicensePermit, 'id' | 'permissionNote' | 'permissionId'>
      )>>> }
    )> }
  )> }
);

export type ModifyLicenseMutationVariables = Exact<{
  licenseId: Scalars['Int'];
  title: Scalars['String'];
  maxUsers: Scalars['Int'];
  activeUntil: Scalars['DateTime'];
  allowPermissions: Array<Scalars['String']> | Scalars['String'];
  denyPermissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type ModifyLicenseMutation = (
  { __typename?: 'Mutation' }
  & { a?: Maybe<(
    { __typename?: 'LicenseMutation' }
    & { allow?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id'>
    )> }
  )>, b?: Maybe<(
    { __typename?: 'LicenseMutation' }
    & { deny?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id'>
    )> }
  )>, final?: Maybe<(
    { __typename?: 'LicenseMutation' }
    & { update?: Maybe<(
      { __typename?: 'License' }
      & Pick<License, 'id' | 'activeUntil' | 'isActive' | 'maxUsers' | 'title'>
      & { permits?: Maybe<Array<Maybe<(
        { __typename?: 'LicensePermit' }
        & Pick<LicensePermit, 'id' | 'permissionNote' | 'permissionId'>
      )>>> }
    )> }
  )> }
);

export type PermissionsForLicenseQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsForLicenseQuery = (
  { __typename?: 'Query' }
  & { licensedPermissions?: Maybe<Array<Maybe<(
    { __typename?: 'LicensePermissionRequirement' }
    & Pick<LicensePermissionRequirement, 'id' | 'note'>
  )>>> }
);

export type PermissionsPerApplicationQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsPerApplicationQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'name' | 'permissions'>
  )>>> }
);

export type SetLicensedPermissionsMutationVariables = Exact<{
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type SetLicensedPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setLicensedPermissions'>
);

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { success: Mutation['changePassword'] }
);

export type CreateServiceMutationVariables = Exact<{
  tenantId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateServiceMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { createService?: Maybe<(
      { __typename?: 'TenantService' }
      & Pick<TenantService, 'id'>
    )> }
  )> }
);

export type EditServiceMutationVariables = Exact<{
  tenantId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type EditServiceMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { editService?: Maybe<(
      { __typename?: 'TenantService' }
      & Pick<TenantService, 'id' | 'name' | 'isActive'>
      & { roles?: Maybe<Array<Maybe<(
        { __typename?: 'TenantRole' }
        & Pick<TenantRole, 'id' | 'name'>
      )>>> }
    )> }
  )> }
);

export type CreateServiceTokenMutationVariables = Exact<{
  tenantId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type CreateServiceTokenMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { createServiceToken?: Maybe<(
      { __typename?: 'IdentityServiceTokenCreated' }
      & Pick<IdentityServiceTokenCreated, 'secret'>
      & { token?: Maybe<(
        { __typename?: 'TenantServiceToken' }
        & Pick<TenantServiceToken, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type RevokeServiceTokenMutationVariables = Exact<{
  tenantId?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
}>;


export type RevokeServiceTokenMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & { revokeServiceToken?: Maybe<(
      { __typename?: 'TenantServiceToken' }
      & Pick<TenantServiceToken, 'id' | 'active'>
    )> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  tenantId: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & Pick<TenantMutation, 'deleteUser'>
  )> }
);

export type DeleteLicenseMutationVariables = Exact<{
  organizationId: Scalars['String'];
  tenantId: Scalars['String'];
}>;


export type DeleteLicenseMutation = (
  { __typename?: 'Mutation' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantMutation' }
    & Pick<TenantMutation, 'deleteLicense'>
  )> }
);

export type DeleteTenantMutationVariables = Exact<{
  organizationId: Scalars['String'];
  tenantId: Scalars['String'];
}>;


export type DeleteTenantMutation = (
  { __typename?: 'Mutation' }
  & { organization?: Maybe<(
    { __typename?: 'OrganizationMutation' }
    & Pick<OrganizationMutation, 'deleteTenant'>
  )> }
);

export const namedOperations = {
  Query: {
    Apps: 'Apps',
    Me: 'Me',
    MyApplications: 'MyApplications',
    SpecificTenant: 'SpecificTenant',
    SpecificTenantRoles: 'SpecificTenantRoles',
    SpecificTenantServices: 'SpecificTenantServices',
    SpecificTenantApplications: 'SpecificTenantApplications',
    SpecificTenantUsers: 'SpecificTenantUsers',
    GlobalApplications: 'GlobalApplications',
    MyTenantApplications: 'MyTenantApplications',
    KnownEmails: 'KnownEmails',
    KnownApplications: 'KnownApplications',
    MyOrganizations: 'MyOrganizations',
    AllTenants: 'AllTenants',
    AllOrganizations: 'AllOrganizations',
    cosmosResources: 'cosmosResources',
    postgresResources: 'postgresResources',
    Licenses: 'Licenses',
    LicenseTemplates: 'LicenseTemplates',
    PermissionsForLicense: 'PermissionsForLicense',
    PermissionsPerApplication: 'PermissionsPerApplication'
  },
  Mutation: {
    updateCosmosResource: 'updateCosmosResource',
    updatePostgresResource: 'updatePostgresResource',
    createRole: 'createRole',
    editRole: 'editRole',
    deleteRole: 'deleteRole',
    createOrganization: 'createOrganization',
    editOrganization: 'editOrganization',
    deleteOrganization: 'deleteOrganization',
    createTenant: 'createTenant',
    updateTenant: 'updateTenant',
    updateUser: 'updateUser',
    resetPassword: 'resetPassword',
    resetPasswordResponse: 'resetPasswordResponse',
    createApplication: 'createApplication',
    updateApplication: 'updateApplication',
    createLicense: 'createLicense',
    createLicenseTemplate: 'createLicenseTemplate',
    editLicenseTemplate: 'editLicenseTemplate',
    deleteLicenseTemplate: 'deleteLicenseTemplate',
    modify1License: 'modify1License',
    modify2License: 'modify2License',
    modify3License: 'modify3License',
    modifyLicense: 'modifyLicense',
    setLicensedPermissions: 'setLicensedPermissions',
    changePassword: 'changePassword',
    createService: 'createService',
    editService: 'editService',
    createServiceToken: 'createServiceToken',
    revokeServiceToken: 'revokeServiceToken',
    deleteUser: 'deleteUser',
    deleteLicense: 'deleteLicense',
    deleteTenant: 'deleteTenant'
  }
}

export const AppsDocument = gql`
    query Apps {
  my {
    tenant {
      id
      applications {
        id
        application {
          id
          name
          publicUrl
          logoUrl
        }
      }
    }
  }
}
    `;

/**
 * __useAppsQuery__
 *
 * To run a query within a React component, call `useAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppsQuery(baseOptions?: Apollo.QueryHookOptions<AppsQuery, AppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppsQuery, AppsQueryVariables>(AppsDocument, options);
      }
export function useAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppsQuery, AppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppsQuery, AppsQueryVariables>(AppsDocument, options);
        }
export type AppsQueryHookResult = ReturnType<typeof useAppsQuery>;
export type AppsLazyQueryHookResult = ReturnType<typeof useAppsLazyQuery>;
export type AppsQueryResult = Apollo.QueryResult<AppsQuery, AppsQueryVariables>;
export const MeDocument = gql`
    query Me {
  my {
    account {
      id
      email
      subjectId
      memberOf {
        id
        name
      }
    }
    user {
      id
      roles {
        id
        permissions {
          id
        }
      }
      tenant {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyApplicationsDocument = gql`
    query MyApplications {
  my {
    user {
      id
      tenant {
        id
        applications {
          id
          application {
            id
            name
            publicUrl
            logoUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyApplicationsQuery__
 *
 * To run a query within a React component, call `useMyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<MyApplicationsQuery, MyApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyApplicationsQuery, MyApplicationsQueryVariables>(MyApplicationsDocument, options);
      }
export function useMyApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyApplicationsQuery, MyApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyApplicationsQuery, MyApplicationsQueryVariables>(MyApplicationsDocument, options);
        }
export type MyApplicationsQueryHookResult = ReturnType<typeof useMyApplicationsQuery>;
export type MyApplicationsLazyQueryHookResult = ReturnType<typeof useMyApplicationsLazyQuery>;
export type MyApplicationsQueryResult = Apollo.QueryResult<MyApplicationsQuery, MyApplicationsQueryVariables>;
export const SpecificTenantDocument = gql`
    query SpecificTenant($tenantId: String!) {
  tenants(where: {id: {eq: $tenantId}}) {
    id
    name
    loginMethods {
      id
      method
    }
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useSpecificTenantQuery__
 *
 * To run a query within a React component, call `useSpecificTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useSpecificTenantQuery(baseOptions: Apollo.QueryHookOptions<SpecificTenantQuery, SpecificTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificTenantQuery, SpecificTenantQueryVariables>(SpecificTenantDocument, options);
      }
export function useSpecificTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificTenantQuery, SpecificTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificTenantQuery, SpecificTenantQueryVariables>(SpecificTenantDocument, options);
        }
export type SpecificTenantQueryHookResult = ReturnType<typeof useSpecificTenantQuery>;
export type SpecificTenantLazyQueryHookResult = ReturnType<typeof useSpecificTenantLazyQuery>;
export type SpecificTenantQueryResult = Apollo.QueryResult<SpecificTenantQuery, SpecificTenantQueryVariables>;
export const SpecificTenantRolesDocument = gql`
    query SpecificTenantRoles($tenantId: String!) {
  tenants(where: {id: {eq: $tenantId}}) {
    id
    name
    roles {
      id
      name
      permissions {
        id
        licenseAvailable
        licenseUsed
        licenseTotal
      }
      createdDate
      updatedDate
      users {
        id
      }
    }
  }
}
    `;

/**
 * __useSpecificTenantRolesQuery__
 *
 * To run a query within a React component, call `useSpecificTenantRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificTenantRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificTenantRolesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useSpecificTenantRolesQuery(baseOptions: Apollo.QueryHookOptions<SpecificTenantRolesQuery, SpecificTenantRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificTenantRolesQuery, SpecificTenantRolesQueryVariables>(SpecificTenantRolesDocument, options);
      }
export function useSpecificTenantRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificTenantRolesQuery, SpecificTenantRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificTenantRolesQuery, SpecificTenantRolesQueryVariables>(SpecificTenantRolesDocument, options);
        }
export type SpecificTenantRolesQueryHookResult = ReturnType<typeof useSpecificTenantRolesQuery>;
export type SpecificTenantRolesLazyQueryHookResult = ReturnType<typeof useSpecificTenantRolesLazyQuery>;
export type SpecificTenantRolesQueryResult = Apollo.QueryResult<SpecificTenantRolesQuery, SpecificTenantRolesQueryVariables>;
export const SpecificTenantServicesDocument = gql`
    query SpecificTenantServices($tenantId: String!) {
  tenants(where: {id: {eq: $tenantId}}) {
    id
    services {
      id
      name
      activeUntil
      isActive
      roles {
        id
      }
      tokens {
        id
        active
        name
      }
    }
  }
}
    `;

/**
 * __useSpecificTenantServicesQuery__
 *
 * To run a query within a React component, call `useSpecificTenantServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificTenantServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificTenantServicesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useSpecificTenantServicesQuery(baseOptions: Apollo.QueryHookOptions<SpecificTenantServicesQuery, SpecificTenantServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificTenantServicesQuery, SpecificTenantServicesQueryVariables>(SpecificTenantServicesDocument, options);
      }
export function useSpecificTenantServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificTenantServicesQuery, SpecificTenantServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificTenantServicesQuery, SpecificTenantServicesQueryVariables>(SpecificTenantServicesDocument, options);
        }
export type SpecificTenantServicesQueryHookResult = ReturnType<typeof useSpecificTenantServicesQuery>;
export type SpecificTenantServicesLazyQueryHookResult = ReturnType<typeof useSpecificTenantServicesLazyQuery>;
export type SpecificTenantServicesQueryResult = Apollo.QueryResult<SpecificTenantServicesQuery, SpecificTenantServicesQueryVariables>;
export const SpecificTenantApplicationsDocument = gql`
    query SpecificTenantApplications($tenantId: String!) {
  tenants(where: {id: {eq: $tenantId}}) {
    id
    applications {
      id
      isActive
      application {
        id
        name
        permissions
      }
    }
  }
}
    `;

/**
 * __useSpecificTenantApplicationsQuery__
 *
 * To run a query within a React component, call `useSpecificTenantApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificTenantApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificTenantApplicationsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useSpecificTenantApplicationsQuery(baseOptions: Apollo.QueryHookOptions<SpecificTenantApplicationsQuery, SpecificTenantApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificTenantApplicationsQuery, SpecificTenantApplicationsQueryVariables>(SpecificTenantApplicationsDocument, options);
      }
export function useSpecificTenantApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificTenantApplicationsQuery, SpecificTenantApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificTenantApplicationsQuery, SpecificTenantApplicationsQueryVariables>(SpecificTenantApplicationsDocument, options);
        }
export type SpecificTenantApplicationsQueryHookResult = ReturnType<typeof useSpecificTenantApplicationsQuery>;
export type SpecificTenantApplicationsLazyQueryHookResult = ReturnType<typeof useSpecificTenantApplicationsLazyQuery>;
export type SpecificTenantApplicationsQueryResult = Apollo.QueryResult<SpecificTenantApplicationsQuery, SpecificTenantApplicationsQueryVariables>;
export const SpecificTenantUsersDocument = gql`
    query SpecificTenantUsers($skip: Int!, $take: Int!, $search: String, $filter: TenantUserFilterInput) {
  users(skip: $skip, take: $take, email_contains: $search, where: $filter) {
    totalCount
    items {
      id
      account {
        id
        email
        subjectId
      }
      tenant {
        id
      }
      activeUntil
      isActive
      createdDate
      updatedDate
      roles {
        id
        name
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useSpecificTenantUsersQuery__
 *
 * To run a query within a React component, call `useSpecificTenantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificTenantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificTenantUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSpecificTenantUsersQuery(baseOptions: Apollo.QueryHookOptions<SpecificTenantUsersQuery, SpecificTenantUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificTenantUsersQuery, SpecificTenantUsersQueryVariables>(SpecificTenantUsersDocument, options);
      }
export function useSpecificTenantUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificTenantUsersQuery, SpecificTenantUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificTenantUsersQuery, SpecificTenantUsersQueryVariables>(SpecificTenantUsersDocument, options);
        }
export type SpecificTenantUsersQueryHookResult = ReturnType<typeof useSpecificTenantUsersQuery>;
export type SpecificTenantUsersLazyQueryHookResult = ReturnType<typeof useSpecificTenantUsersLazyQuery>;
export type SpecificTenantUsersQueryResult = Apollo.QueryResult<SpecificTenantUsersQuery, SpecificTenantUsersQueryVariables>;
export const GlobalApplicationsDocument = gql`
    query GlobalApplications {
  applications {
    id
    name
    publicUrl
    logoUrl
    secret
    permissions
    activeUntil
    isActive
    allowedCorsOrigins
    allowedScopes
    postLogoutRedirectUris
    redirectUris
  }
}
    `;

/**
 * __useGlobalApplicationsQuery__
 *
 * To run a query within a React component, call `useGlobalApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<GlobalApplicationsQuery, GlobalApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalApplicationsQuery, GlobalApplicationsQueryVariables>(GlobalApplicationsDocument, options);
      }
export function useGlobalApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalApplicationsQuery, GlobalApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalApplicationsQuery, GlobalApplicationsQueryVariables>(GlobalApplicationsDocument, options);
        }
export type GlobalApplicationsQueryHookResult = ReturnType<typeof useGlobalApplicationsQuery>;
export type GlobalApplicationsLazyQueryHookResult = ReturnType<typeof useGlobalApplicationsLazyQuery>;
export type GlobalApplicationsQueryResult = Apollo.QueryResult<GlobalApplicationsQuery, GlobalApplicationsQueryVariables>;
export const MyTenantApplicationsDocument = gql`
    query MyTenantApplications {
  organizations {
    tenants {
      applications {
        id
        isActive
        application {
          id
          name
          permissions
          activeUntil
          isActive
          allowedCorsOrigins
          allowedScopes
          postLogoutRedirectUris
          redirectUris
        }
      }
    }
  }
}
    `;

/**
 * __useMyTenantApplicationsQuery__
 *
 * To run a query within a React component, call `useMyTenantApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTenantApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTenantApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTenantApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<MyTenantApplicationsQuery, MyTenantApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTenantApplicationsQuery, MyTenantApplicationsQueryVariables>(MyTenantApplicationsDocument, options);
      }
export function useMyTenantApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTenantApplicationsQuery, MyTenantApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTenantApplicationsQuery, MyTenantApplicationsQueryVariables>(MyTenantApplicationsDocument, options);
        }
export type MyTenantApplicationsQueryHookResult = ReturnType<typeof useMyTenantApplicationsQuery>;
export type MyTenantApplicationsLazyQueryHookResult = ReturnType<typeof useMyTenantApplicationsLazyQuery>;
export type MyTenantApplicationsQueryResult = Apollo.QueryResult<MyTenantApplicationsQuery, MyTenantApplicationsQueryVariables>;
export const KnownEmailsDocument = gql`
    query KnownEmails($text: String!) {
  accounts(where: {email_contains: {contains: $text}}) {
    id
    email
  }
}
    `;

/**
 * __useKnownEmailsQuery__
 *
 * To run a query within a React component, call `useKnownEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnownEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnownEmailsQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useKnownEmailsQuery(baseOptions: Apollo.QueryHookOptions<KnownEmailsQuery, KnownEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnownEmailsQuery, KnownEmailsQueryVariables>(KnownEmailsDocument, options);
      }
export function useKnownEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnownEmailsQuery, KnownEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnownEmailsQuery, KnownEmailsQueryVariables>(KnownEmailsDocument, options);
        }
export type KnownEmailsQueryHookResult = ReturnType<typeof useKnownEmailsQuery>;
export type KnownEmailsLazyQueryHookResult = ReturnType<typeof useKnownEmailsLazyQuery>;
export type KnownEmailsQueryResult = Apollo.QueryResult<KnownEmailsQuery, KnownEmailsQueryVariables>;
export const KnownApplicationsDocument = gql`
    query KnownApplications($text: String!) {
  applications(where: {name_contains: {contains: $text}}) {
    id
    name
  }
}
    `;

/**
 * __useKnownApplicationsQuery__
 *
 * To run a query within a React component, call `useKnownApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnownApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnownApplicationsQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useKnownApplicationsQuery(baseOptions: Apollo.QueryHookOptions<KnownApplicationsQuery, KnownApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnownApplicationsQuery, KnownApplicationsQueryVariables>(KnownApplicationsDocument, options);
      }
export function useKnownApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnownApplicationsQuery, KnownApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnownApplicationsQuery, KnownApplicationsQueryVariables>(KnownApplicationsDocument, options);
        }
export type KnownApplicationsQueryHookResult = ReturnType<typeof useKnownApplicationsQuery>;
export type KnownApplicationsLazyQueryHookResult = ReturnType<typeof useKnownApplicationsLazyQuery>;
export type KnownApplicationsQueryResult = Apollo.QueryResult<KnownApplicationsQuery, KnownApplicationsQueryVariables>;
export const MyOrganizationsDocument = gql`
    query MyOrganizations {
  organizations {
    id
    name
    admins {
      id
      email
    }
    tenants {
      id
      name
      roleCount
      userCount
      serviceCount
    }
  }
}
    `;

/**
 * __useMyOrganizationsQuery__
 *
 * To run a query within a React component, call `useMyOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<MyOrganizationsQuery, MyOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyOrganizationsQuery, MyOrganizationsQueryVariables>(MyOrganizationsDocument, options);
      }
export function useMyOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyOrganizationsQuery, MyOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyOrganizationsQuery, MyOrganizationsQueryVariables>(MyOrganizationsDocument, options);
        }
export type MyOrganizationsQueryHookResult = ReturnType<typeof useMyOrganizationsQuery>;
export type MyOrganizationsLazyQueryHookResult = ReturnType<typeof useMyOrganizationsLazyQuery>;
export type MyOrganizationsQueryResult = Apollo.QueryResult<MyOrganizationsQuery, MyOrganizationsQueryVariables>;
export const AllTenantsDocument = gql`
    query AllTenants {
  tenants {
    id
    name
    roleCount
    userCount
    serviceCount
    organization {
      id
    }
  }
}
    `;

/**
 * __useAllTenantsQuery__
 *
 * To run a query within a React component, call `useAllTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTenantsQuery(baseOptions?: Apollo.QueryHookOptions<AllTenantsQuery, AllTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTenantsQuery, AllTenantsQueryVariables>(AllTenantsDocument, options);
      }
export function useAllTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTenantsQuery, AllTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTenantsQuery, AllTenantsQueryVariables>(AllTenantsDocument, options);
        }
export type AllTenantsQueryHookResult = ReturnType<typeof useAllTenantsQuery>;
export type AllTenantsLazyQueryHookResult = ReturnType<typeof useAllTenantsLazyQuery>;
export type AllTenantsQueryResult = Apollo.QueryResult<AllTenantsQuery, AllTenantsQueryVariables>;
export const AllOrganizationsDocument = gql`
    query AllOrganizations {
  organizations {
    id
    name
  }
}
    `;

/**
 * __useAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, options);
      }
export function useAllOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, options);
        }
export type AllOrganizationsQueryHookResult = ReturnType<typeof useAllOrganizationsQuery>;
export type AllOrganizationsLazyQueryHookResult = ReturnType<typeof useAllOrganizationsLazyQuery>;
export type AllOrganizationsQueryResult = Apollo.QueryResult<AllOrganizationsQuery, AllOrganizationsQueryVariables>;
export const CosmosResourcesDocument = gql`
    query cosmosResources($tenantId: String!) {
  cosmosResources(tenantId: $tenantId) {
    id
    code
    name
    externalUserId
  }
}
    `;

/**
 * __useCosmosResourcesQuery__
 *
 * To run a query within a React component, call `useCosmosResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCosmosResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCosmosResourcesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCosmosResourcesQuery(baseOptions: Apollo.QueryHookOptions<CosmosResourcesQuery, CosmosResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CosmosResourcesQuery, CosmosResourcesQueryVariables>(CosmosResourcesDocument, options);
      }
export function useCosmosResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CosmosResourcesQuery, CosmosResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CosmosResourcesQuery, CosmosResourcesQueryVariables>(CosmosResourcesDocument, options);
        }
export type CosmosResourcesQueryHookResult = ReturnType<typeof useCosmosResourcesQuery>;
export type CosmosResourcesLazyQueryHookResult = ReturnType<typeof useCosmosResourcesLazyQuery>;
export type CosmosResourcesQueryResult = Apollo.QueryResult<CosmosResourcesQuery, CosmosResourcesQueryVariables>;
export const UpdateCosmosResourceDocument = gql`
    mutation updateCosmosResource($resourceId: String!, $newResourceId: String!, $tenantId: String!, $subjectId: String!) {
  updateCosmosResource(
    resourceId: $resourceId
    newResourceId: $newResourceId
    tenantId: $tenantId
    subjectId: $subjectId
  ) {
    id
    code
    name
    externalUserId
  }
}
    `;
export type UpdateCosmosResourceMutationFn = Apollo.MutationFunction<UpdateCosmosResourceMutation, UpdateCosmosResourceMutationVariables>;

/**
 * __useUpdateCosmosResourceMutation__
 *
 * To run a mutation, you first call `useUpdateCosmosResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCosmosResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCosmosResourceMutation, { data, loading, error }] = useUpdateCosmosResourceMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      newResourceId: // value for 'newResourceId'
 *      tenantId: // value for 'tenantId'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useUpdateCosmosResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCosmosResourceMutation, UpdateCosmosResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCosmosResourceMutation, UpdateCosmosResourceMutationVariables>(UpdateCosmosResourceDocument, options);
      }
export type UpdateCosmosResourceMutationHookResult = ReturnType<typeof useUpdateCosmosResourceMutation>;
export type UpdateCosmosResourceMutationResult = Apollo.MutationResult<UpdateCosmosResourceMutation>;
export type UpdateCosmosResourceMutationOptions = Apollo.BaseMutationOptions<UpdateCosmosResourceMutation, UpdateCosmosResourceMutationVariables>;
export const PostgresResourcesDocument = gql`
    query postgresResources($tenantId: String!) {
  postgresResources(tenantId: $tenantId) {
    id
    name
    externalUserId
  }
}
    `;

/**
 * __usePostgresResourcesQuery__
 *
 * To run a query within a React component, call `usePostgresResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostgresResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostgresResourcesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function usePostgresResourcesQuery(baseOptions: Apollo.QueryHookOptions<PostgresResourcesQuery, PostgresResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostgresResourcesQuery, PostgresResourcesQueryVariables>(PostgresResourcesDocument, options);
      }
export function usePostgresResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostgresResourcesQuery, PostgresResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostgresResourcesQuery, PostgresResourcesQueryVariables>(PostgresResourcesDocument, options);
        }
export type PostgresResourcesQueryHookResult = ReturnType<typeof usePostgresResourcesQuery>;
export type PostgresResourcesLazyQueryHookResult = ReturnType<typeof usePostgresResourcesLazyQuery>;
export type PostgresResourcesQueryResult = Apollo.QueryResult<PostgresResourcesQuery, PostgresResourcesQueryVariables>;
export const UpdatePostgresResourceDocument = gql`
    mutation updatePostgresResource($resourceId: Int!, $tenantId: String!, $subjectId: String!) {
  updatePostgresResource(
    resourceId: $resourceId
    tenantId: $tenantId
    subjectId: $subjectId
  ) {
    id
    name
    externalUserId
  }
}
    `;
export type UpdatePostgresResourceMutationFn = Apollo.MutationFunction<UpdatePostgresResourceMutation, UpdatePostgresResourceMutationVariables>;

/**
 * __useUpdatePostgresResourceMutation__
 *
 * To run a mutation, you first call `useUpdatePostgresResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostgresResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostgresResourceMutation, { data, loading, error }] = useUpdatePostgresResourceMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      tenantId: // value for 'tenantId'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useUpdatePostgresResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostgresResourceMutation, UpdatePostgresResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostgresResourceMutation, UpdatePostgresResourceMutationVariables>(UpdatePostgresResourceDocument, options);
      }
export type UpdatePostgresResourceMutationHookResult = ReturnType<typeof useUpdatePostgresResourceMutation>;
export type UpdatePostgresResourceMutationResult = Apollo.MutationResult<UpdatePostgresResourceMutation>;
export type UpdatePostgresResourceMutationOptions = Apollo.BaseMutationOptions<UpdatePostgresResourceMutation, UpdatePostgresResourceMutationVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($tenantId: String!, $name: String!, $permissions: [String!]!) {
  tenant(tenantId: $tenantId) {
    createRole(name: $name, permissions: $permissions) {
      id
    }
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const EditRoleDocument = gql`
    mutation editRole($tenantId: String!, $roleId: String!, $name: String!, $permissions: [String!]!) {
  tenant(tenantId: $tenantId) {
    editRole(roleId: $roleId, name: $name, permissions: $permissions) {
      id
    }
  }
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($tenantId: String!, $roleId: String!) {
  tenant(tenantId: $tenantId) {
    deleteRole(roleId: $roleId)
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($name: String!) {
  createOrganization(name: $name) {
    id
    name
    admins {
      id
      email
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const EditOrganizationDocument = gql`
    mutation editOrganization($organizationId: String!, $name: String!, $owners: [String!]!) {
  organization(organizationId: $organizationId) {
    update(name: $name, owners: $owners) {
      id
      name
    }
  }
}
    `;
export type EditOrganizationMutationFn = Apollo.MutationFunction<EditOrganizationMutation, EditOrganizationMutationVariables>;

/**
 * __useEditOrganizationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationMutation, { data, loading, error }] = useEditOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      owners: // value for 'owners'
 *   },
 * });
 */
export function useEditOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<EditOrganizationMutation, EditOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOrganizationMutation, EditOrganizationMutationVariables>(EditOrganizationDocument, options);
      }
export type EditOrganizationMutationHookResult = ReturnType<typeof useEditOrganizationMutation>;
export type EditOrganizationMutationResult = Apollo.MutationResult<EditOrganizationMutation>;
export type EditOrganizationMutationOptions = Apollo.BaseMutationOptions<EditOrganizationMutation, EditOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($organizationId: String!) {
  organization(organizationId: $organizationId) {
    delete
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const CreateTenantDocument = gql`
    mutation createTenant($organizationId: String!, $name: String!) {
  organization(organizationId: $organizationId) {
    createTenant(name: $name) {
      id
      name
    }
  }
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation updateTenant($tenantId: String!, $name: String!, $applicationIds: [String!]!, $loginMethods: [String!]!) {
  tenant(tenantId: $tenantId) {
    update(
      name: $name
      applicationIds: $applicationIds
      loginMethods: $loginMethods
    ) {
      id
      name
      applications {
        id
      }
      loginMethods {
        id
        method
      }
    }
  }
}
    `;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      name: // value for 'name'
 *      applicationIds: // value for 'applicationIds'
 *      loginMethods: // value for 'loginMethods'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($tenantId: String!, $email: String!, $roleIds: [String!]!, $activeUntil: DateTime) {
  tenant(tenantId: $tenantId) {
    updateUser(email: $email, roleIds: $roleIds, activeUntil: $activeUntil) {
      id
      isActive
      roles {
        id
        name
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      email: // value for 'email'
 *      roleIds: // value for 'roleIds'
 *      activeUntil: // value for 'activeUntil'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetPasswordResponseDocument = gql`
    mutation resetPasswordResponse($token: String!, $password: String!) {
  resetPasswordResponse(token: $token, password: $password)
}
    `;
export type ResetPasswordResponseMutationFn = Apollo.MutationFunction<ResetPasswordResponseMutation, ResetPasswordResponseMutationVariables>;

/**
 * __useResetPasswordResponseMutation__
 *
 * To run a mutation, you first call `useResetPasswordResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordResponseMutation, { data, loading, error }] = useResetPasswordResponseMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordResponseMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordResponseMutation, ResetPasswordResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordResponseMutation, ResetPasswordResponseMutationVariables>(ResetPasswordResponseDocument, options);
      }
export type ResetPasswordResponseMutationHookResult = ReturnType<typeof useResetPasswordResponseMutation>;
export type ResetPasswordResponseMutationResult = Apollo.MutationResult<ResetPasswordResponseMutation>;
export type ResetPasswordResponseMutationOptions = Apollo.BaseMutationOptions<ResetPasswordResponseMutation, ResetPasswordResponseMutationVariables>;
export const CreateApplicationDocument = gql`
    mutation createApplication($name: String!, $activeUntil: DateTime, $publicUrl: String, $logoUrl: String, $redirectUrls: [String!]!, $postLogoutRedirectUrls: [String!]!, $allowedCorsOrigins: [String!]!, $allowedScopes: [String!]!, $permissions: [String!]!) {
  createApplication(
    name: $name
    publicUrl: $publicUrl
    logoUrl: $logoUrl
    activeUntil: $activeUntil
    redirectUrls: $redirectUrls
    postLogoutRedirectUrls: $postLogoutRedirectUrls
    allowedCorsOrigins: $allowedCorsOrigins
    allowedScopes: $allowedScopes
    permissions: $permissions
  ) {
    id
  }
}
    `;
export type CreateApplicationMutationFn = Apollo.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      activeUntil: // value for 'activeUntil'
 *      publicUrl: // value for 'publicUrl'
 *      logoUrl: // value for 'logoUrl'
 *      redirectUrls: // value for 'redirectUrls'
 *      postLogoutRedirectUrls: // value for 'postLogoutRedirectUrls'
 *      allowedCorsOrigins: // value for 'allowedCorsOrigins'
 *      allowedScopes: // value for 'allowedScopes'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, options);
      }
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<CreateApplicationMutation, CreateApplicationMutationVariables>;
export const UpdateApplicationDocument = gql`
    mutation updateApplication($applicationId: String!, $name: String!, $publicUrl: String, $logoUrl: String, $activeUntil: DateTime, $redirectUrls: [String!]!, $postLogoutRedirectUrls: [String!]!, $allowedCorsOrigins: [String!]!, $allowedScopes: [String!]!, $permissions: [String!]!) {
  updateApplication(
    applicationId: $applicationId
    name: $name
    publicUrl: $publicUrl
    logoUrl: $logoUrl
    activeUntil: $activeUntil
    redirectUrls: $redirectUrls
    postLogoutRedirectUrls: $postLogoutRedirectUrls
    allowedCorsOrigins: $allowedCorsOrigins
    allowedScopes: $allowedScopes
    permissions: $permissions
  ) {
    id
  }
}
    `;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<UpdateApplicationMutation, UpdateApplicationMutationVariables>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      name: // value for 'name'
 *      publicUrl: // value for 'publicUrl'
 *      logoUrl: // value for 'logoUrl'
 *      activeUntil: // value for 'activeUntil'
 *      redirectUrls: // value for 'redirectUrls'
 *      postLogoutRedirectUrls: // value for 'postLogoutRedirectUrls'
 *      allowedCorsOrigins: // value for 'allowedCorsOrigins'
 *      allowedScopes: // value for 'allowedScopes'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useUpdateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument, options);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export const LicensesDocument = gql`
    query Licenses {
  tenants {
    id
    licenses {
      id
      activeUntil
      isActive
      maxUsers
      permits {
        id
        permissionNote
        permissionId
      }
      tenant {
        id
        name
        organization {
          id
          name
        }
      }
      title
    }
  }
}
    `;

/**
 * __useLicensesQuery__
 *
 * To run a query within a React component, call `useLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicensesQuery(baseOptions?: Apollo.QueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensesQuery, LicensesQueryVariables>(LicensesDocument, options);
      }
export function useLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensesQuery, LicensesQueryVariables>(LicensesDocument, options);
        }
export type LicensesQueryHookResult = ReturnType<typeof useLicensesQuery>;
export type LicensesLazyQueryHookResult = ReturnType<typeof useLicensesLazyQuery>;
export type LicensesQueryResult = Apollo.QueryResult<LicensesQuery, LicensesQueryVariables>;
export const LicenseTemplatesDocument = gql`
    query LicenseTemplates {
  licenseTemplates {
    id
    name
    permissions
    createdDate
    updatedDate
  }
}
    `;

/**
 * __useLicenseTemplatesQuery__
 *
 * To run a query within a React component, call `useLicenseTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<LicenseTemplatesQuery, LicenseTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseTemplatesQuery, LicenseTemplatesQueryVariables>(LicenseTemplatesDocument, options);
      }
export function useLicenseTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseTemplatesQuery, LicenseTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseTemplatesQuery, LicenseTemplatesQueryVariables>(LicenseTemplatesDocument, options);
        }
export type LicenseTemplatesQueryHookResult = ReturnType<typeof useLicenseTemplatesQuery>;
export type LicenseTemplatesLazyQueryHookResult = ReturnType<typeof useLicenseTemplatesLazyQuery>;
export type LicenseTemplatesQueryResult = Apollo.QueryResult<LicenseTemplatesQuery, LicenseTemplatesQueryVariables>;
export const CreateLicenseDocument = gql`
    mutation createLicense($tenantId: String!, $title: String!, $permissions: [String!]!) {
  tenant(tenantId: $tenantId) {
    createLicense(title: $title, permissions: $permissions) {
      id
    }
  }
}
    `;
export type CreateLicenseMutationFn = Apollo.MutationFunction<CreateLicenseMutation, CreateLicenseMutationVariables>;

/**
 * __useCreateLicenseMutation__
 *
 * To run a mutation, you first call `useCreateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseMutation, { data, loading, error }] = useCreateLicenseMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      title: // value for 'title'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateLicenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseMutation, CreateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseMutation, CreateLicenseMutationVariables>(CreateLicenseDocument, options);
      }
export type CreateLicenseMutationHookResult = ReturnType<typeof useCreateLicenseMutation>;
export type CreateLicenseMutationResult = Apollo.MutationResult<CreateLicenseMutation>;
export type CreateLicenseMutationOptions = Apollo.BaseMutationOptions<CreateLicenseMutation, CreateLicenseMutationVariables>;
export const CreateLicenseTemplateDocument = gql`
    mutation createLicenseTemplate($name: String!, $permissions: [String!]!) {
  createLicenseTemplate(name: $name, permissions: $permissions) {
    id
  }
}
    `;
export type CreateLicenseTemplateMutationFn = Apollo.MutationFunction<CreateLicenseTemplateMutation, CreateLicenseTemplateMutationVariables>;

/**
 * __useCreateLicenseTemplateMutation__
 *
 * To run a mutation, you first call `useCreateLicenseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseTemplateMutation, { data, loading, error }] = useCreateLicenseTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateLicenseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseTemplateMutation, CreateLicenseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseTemplateMutation, CreateLicenseTemplateMutationVariables>(CreateLicenseTemplateDocument, options);
      }
export type CreateLicenseTemplateMutationHookResult = ReturnType<typeof useCreateLicenseTemplateMutation>;
export type CreateLicenseTemplateMutationResult = Apollo.MutationResult<CreateLicenseTemplateMutation>;
export type CreateLicenseTemplateMutationOptions = Apollo.BaseMutationOptions<CreateLicenseTemplateMutation, CreateLicenseTemplateMutationVariables>;
export const EditLicenseTemplateDocument = gql`
    mutation editLicenseTemplate($templateId: Int!, $name: String!, $permissions: [String!]!) {
  editLicenseTemplate(
    templateId: $templateId
    name: $name
    permissions: $permissions
  ) {
    id
    name
    permissions
    createdDate
    updatedDate
  }
}
    `;
export type EditLicenseTemplateMutationFn = Apollo.MutationFunction<EditLicenseTemplateMutation, EditLicenseTemplateMutationVariables>;

/**
 * __useEditLicenseTemplateMutation__
 *
 * To run a mutation, you first call `useEditLicenseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLicenseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLicenseTemplateMutation, { data, loading, error }] = useEditLicenseTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      name: // value for 'name'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useEditLicenseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<EditLicenseTemplateMutation, EditLicenseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLicenseTemplateMutation, EditLicenseTemplateMutationVariables>(EditLicenseTemplateDocument, options);
      }
export type EditLicenseTemplateMutationHookResult = ReturnType<typeof useEditLicenseTemplateMutation>;
export type EditLicenseTemplateMutationResult = Apollo.MutationResult<EditLicenseTemplateMutation>;
export type EditLicenseTemplateMutationOptions = Apollo.BaseMutationOptions<EditLicenseTemplateMutation, EditLicenseTemplateMutationVariables>;
export const DeleteLicenseTemplateDocument = gql`
    mutation deleteLicenseTemplate($templateId: Int!) {
  deleteLicenseTemplate(templateId: $templateId)
}
    `;
export type DeleteLicenseTemplateMutationFn = Apollo.MutationFunction<DeleteLicenseTemplateMutation, DeleteLicenseTemplateMutationVariables>;

/**
 * __useDeleteLicenseTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseTemplateMutation, { data, loading, error }] = useDeleteLicenseTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteLicenseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseTemplateMutation, DeleteLicenseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseTemplateMutation, DeleteLicenseTemplateMutationVariables>(DeleteLicenseTemplateDocument, options);
      }
export type DeleteLicenseTemplateMutationHookResult = ReturnType<typeof useDeleteLicenseTemplateMutation>;
export type DeleteLicenseTemplateMutationResult = Apollo.MutationResult<DeleteLicenseTemplateMutation>;
export type DeleteLicenseTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseTemplateMutation, DeleteLicenseTemplateMutationVariables>;
export const Modify1LicenseDocument = gql`
    mutation modify1License($licenseId: Int!, $allowPermissions: [String!]!) {
  license(licenseId: $licenseId) {
    allow(permissions: $allowPermissions) {
      id
    }
  }
}
    `;
export type Modify1LicenseMutationFn = Apollo.MutationFunction<Modify1LicenseMutation, Modify1LicenseMutationVariables>;

/**
 * __useModify1LicenseMutation__
 *
 * To run a mutation, you first call `useModify1LicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModify1LicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modify1LicenseMutation, { data, loading, error }] = useModify1LicenseMutation({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *      allowPermissions: // value for 'allowPermissions'
 *   },
 * });
 */
export function useModify1LicenseMutation(baseOptions?: Apollo.MutationHookOptions<Modify1LicenseMutation, Modify1LicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Modify1LicenseMutation, Modify1LicenseMutationVariables>(Modify1LicenseDocument, options);
      }
export type Modify1LicenseMutationHookResult = ReturnType<typeof useModify1LicenseMutation>;
export type Modify1LicenseMutationResult = Apollo.MutationResult<Modify1LicenseMutation>;
export type Modify1LicenseMutationOptions = Apollo.BaseMutationOptions<Modify1LicenseMutation, Modify1LicenseMutationVariables>;
export const Modify2LicenseDocument = gql`
    mutation modify2License($licenseId: Int!, $denyPermissions: [String!]!) {
  license(licenseId: $licenseId) {
    deny(permissions: $denyPermissions) {
      id
    }
  }
}
    `;
export type Modify2LicenseMutationFn = Apollo.MutationFunction<Modify2LicenseMutation, Modify2LicenseMutationVariables>;

/**
 * __useModify2LicenseMutation__
 *
 * To run a mutation, you first call `useModify2LicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModify2LicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modify2LicenseMutation, { data, loading, error }] = useModify2LicenseMutation({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *      denyPermissions: // value for 'denyPermissions'
 *   },
 * });
 */
export function useModify2LicenseMutation(baseOptions?: Apollo.MutationHookOptions<Modify2LicenseMutation, Modify2LicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Modify2LicenseMutation, Modify2LicenseMutationVariables>(Modify2LicenseDocument, options);
      }
export type Modify2LicenseMutationHookResult = ReturnType<typeof useModify2LicenseMutation>;
export type Modify2LicenseMutationResult = Apollo.MutationResult<Modify2LicenseMutation>;
export type Modify2LicenseMutationOptions = Apollo.BaseMutationOptions<Modify2LicenseMutation, Modify2LicenseMutationVariables>;
export const Modify3LicenseDocument = gql`
    mutation modify3License($licenseId: Int!, $title: String!, $maxUsers: Int!, $activeUntil: DateTime!) {
  license(licenseId: $licenseId) {
    update(title: $title, maxUsers: $maxUsers, activeUntil: $activeUntil) {
      id
      activeUntil
      isActive
      maxUsers
      title
      permits {
        id
        permissionNote
        permissionId
      }
    }
  }
}
    `;
export type Modify3LicenseMutationFn = Apollo.MutationFunction<Modify3LicenseMutation, Modify3LicenseMutationVariables>;

/**
 * __useModify3LicenseMutation__
 *
 * To run a mutation, you first call `useModify3LicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModify3LicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modify3LicenseMutation, { data, loading, error }] = useModify3LicenseMutation({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *      title: // value for 'title'
 *      maxUsers: // value for 'maxUsers'
 *      activeUntil: // value for 'activeUntil'
 *   },
 * });
 */
export function useModify3LicenseMutation(baseOptions?: Apollo.MutationHookOptions<Modify3LicenseMutation, Modify3LicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Modify3LicenseMutation, Modify3LicenseMutationVariables>(Modify3LicenseDocument, options);
      }
export type Modify3LicenseMutationHookResult = ReturnType<typeof useModify3LicenseMutation>;
export type Modify3LicenseMutationResult = Apollo.MutationResult<Modify3LicenseMutation>;
export type Modify3LicenseMutationOptions = Apollo.BaseMutationOptions<Modify3LicenseMutation, Modify3LicenseMutationVariables>;
export const ModifyLicenseDocument = gql`
    mutation modifyLicense($licenseId: Int!, $title: String!, $maxUsers: Int!, $activeUntil: DateTime!, $allowPermissions: [String!]!, $denyPermissions: [String!]!) {
  a: license(licenseId: $licenseId) {
    allow(permissions: $allowPermissions) {
      id
    }
  }
  b: license(licenseId: $licenseId) {
    deny(permissions: $denyPermissions) {
      id
    }
  }
  final: license(licenseId: $licenseId) {
    update(title: $title, maxUsers: $maxUsers, activeUntil: $activeUntil) {
      id
      activeUntil
      isActive
      maxUsers
      title
      permits {
        id
        permissionNote
        permissionId
      }
    }
  }
}
    `;
export type ModifyLicenseMutationFn = Apollo.MutationFunction<ModifyLicenseMutation, ModifyLicenseMutationVariables>;

/**
 * __useModifyLicenseMutation__
 *
 * To run a mutation, you first call `useModifyLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyLicenseMutation, { data, loading, error }] = useModifyLicenseMutation({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *      title: // value for 'title'
 *      maxUsers: // value for 'maxUsers'
 *      activeUntil: // value for 'activeUntil'
 *      allowPermissions: // value for 'allowPermissions'
 *      denyPermissions: // value for 'denyPermissions'
 *   },
 * });
 */
export function useModifyLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ModifyLicenseMutation, ModifyLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyLicenseMutation, ModifyLicenseMutationVariables>(ModifyLicenseDocument, options);
      }
export type ModifyLicenseMutationHookResult = ReturnType<typeof useModifyLicenseMutation>;
export type ModifyLicenseMutationResult = Apollo.MutationResult<ModifyLicenseMutation>;
export type ModifyLicenseMutationOptions = Apollo.BaseMutationOptions<ModifyLicenseMutation, ModifyLicenseMutationVariables>;
export const PermissionsForLicenseDocument = gql`
    query PermissionsForLicense {
  licensedPermissions {
    id
    note
  }
}
    `;

/**
 * __usePermissionsForLicenseQuery__
 *
 * To run a query within a React component, call `usePermissionsForLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsForLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsForLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsForLicenseQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsForLicenseQuery, PermissionsForLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsForLicenseQuery, PermissionsForLicenseQueryVariables>(PermissionsForLicenseDocument, options);
      }
export function usePermissionsForLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsForLicenseQuery, PermissionsForLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsForLicenseQuery, PermissionsForLicenseQueryVariables>(PermissionsForLicenseDocument, options);
        }
export type PermissionsForLicenseQueryHookResult = ReturnType<typeof usePermissionsForLicenseQuery>;
export type PermissionsForLicenseLazyQueryHookResult = ReturnType<typeof usePermissionsForLicenseLazyQuery>;
export type PermissionsForLicenseQueryResult = Apollo.QueryResult<PermissionsForLicenseQuery, PermissionsForLicenseQueryVariables>;
export const PermissionsPerApplicationDocument = gql`
    query PermissionsPerApplication {
  applications {
    id
    name
    permissions
  }
}
    `;

/**
 * __usePermissionsPerApplicationQuery__
 *
 * To run a query within a React component, call `usePermissionsPerApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsPerApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsPerApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsPerApplicationQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsPerApplicationQuery, PermissionsPerApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsPerApplicationQuery, PermissionsPerApplicationQueryVariables>(PermissionsPerApplicationDocument, options);
      }
export function usePermissionsPerApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsPerApplicationQuery, PermissionsPerApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsPerApplicationQuery, PermissionsPerApplicationQueryVariables>(PermissionsPerApplicationDocument, options);
        }
export type PermissionsPerApplicationQueryHookResult = ReturnType<typeof usePermissionsPerApplicationQuery>;
export type PermissionsPerApplicationLazyQueryHookResult = ReturnType<typeof usePermissionsPerApplicationLazyQuery>;
export type PermissionsPerApplicationQueryResult = Apollo.QueryResult<PermissionsPerApplicationQuery, PermissionsPerApplicationQueryVariables>;
export const SetLicensedPermissionsDocument = gql`
    mutation setLicensedPermissions($permissions: [String!]!) {
  setLicensedPermissions(permissions: $permissions)
}
    `;
export type SetLicensedPermissionsMutationFn = Apollo.MutationFunction<SetLicensedPermissionsMutation, SetLicensedPermissionsMutationVariables>;

/**
 * __useSetLicensedPermissionsMutation__
 *
 * To run a mutation, you first call `useSetLicensedPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLicensedPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLicensedPermissionsMutation, { data, loading, error }] = useSetLicensedPermissionsMutation({
 *   variables: {
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useSetLicensedPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<SetLicensedPermissionsMutation, SetLicensedPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLicensedPermissionsMutation, SetLicensedPermissionsMutationVariables>(SetLicensedPermissionsDocument, options);
      }
export type SetLicensedPermissionsMutationHookResult = ReturnType<typeof useSetLicensedPermissionsMutation>;
export type SetLicensedPermissionsMutationResult = Apollo.MutationResult<SetLicensedPermissionsMutation>;
export type SetLicensedPermissionsMutationOptions = Apollo.BaseMutationOptions<SetLicensedPermissionsMutation, SetLicensedPermissionsMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!) {
  success: changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateServiceDocument = gql`
    mutation createService($tenantId: String, $name: String, $roleIds: [String!]) {
  tenant(tenantId: $tenantId) {
    createService(name: $name, roleIds: $roleIds) {
      id
    }
  }
}
    `;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      name: // value for 'name'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;
export const EditServiceDocument = gql`
    mutation editService($tenantId: String, $serviceId: String, $name: String, $roleIds: [String!]) {
  tenant(tenantId: $tenantId) {
    editService(serviceId: $serviceId, name: $name, roleIds: $roleIds) {
      id
      name
      isActive
      roles {
        id
        name
      }
    }
  }
}
    `;
export type EditServiceMutationFn = Apollo.MutationFunction<EditServiceMutation, EditServiceMutationVariables>;

/**
 * __useEditServiceMutation__
 *
 * To run a mutation, you first call `useEditServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editServiceMutation, { data, loading, error }] = useEditServiceMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      serviceId: // value for 'serviceId'
 *      name: // value for 'name'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useEditServiceMutation(baseOptions?: Apollo.MutationHookOptions<EditServiceMutation, EditServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditServiceMutation, EditServiceMutationVariables>(EditServiceDocument, options);
      }
export type EditServiceMutationHookResult = ReturnType<typeof useEditServiceMutation>;
export type EditServiceMutationResult = Apollo.MutationResult<EditServiceMutation>;
export type EditServiceMutationOptions = Apollo.BaseMutationOptions<EditServiceMutation, EditServiceMutationVariables>;
export const CreateServiceTokenDocument = gql`
    mutation createServiceToken($tenantId: String, $serviceId: String, $name: String) {
  tenant(tenantId: $tenantId) {
    createServiceToken(name: $name, serviceId: $serviceId) {
      secret
      token {
        id
        name
      }
    }
  }
}
    `;
export type CreateServiceTokenMutationFn = Apollo.MutationFunction<CreateServiceTokenMutation, CreateServiceTokenMutationVariables>;

/**
 * __useCreateServiceTokenMutation__
 *
 * To run a mutation, you first call `useCreateServiceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceTokenMutation, { data, loading, error }] = useCreateServiceTokenMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      serviceId: // value for 'serviceId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateServiceTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceTokenMutation, CreateServiceTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceTokenMutation, CreateServiceTokenMutationVariables>(CreateServiceTokenDocument, options);
      }
export type CreateServiceTokenMutationHookResult = ReturnType<typeof useCreateServiceTokenMutation>;
export type CreateServiceTokenMutationResult = Apollo.MutationResult<CreateServiceTokenMutation>;
export type CreateServiceTokenMutationOptions = Apollo.BaseMutationOptions<CreateServiceTokenMutation, CreateServiceTokenMutationVariables>;
export const RevokeServiceTokenDocument = gql`
    mutation revokeServiceToken($tenantId: String, $tokenId: String) {
  tenant(tenantId: $tenantId) {
    revokeServiceToken(tokenId: $tokenId) {
      id
      active
    }
  }
}
    `;
export type RevokeServiceTokenMutationFn = Apollo.MutationFunction<RevokeServiceTokenMutation, RevokeServiceTokenMutationVariables>;

/**
 * __useRevokeServiceTokenMutation__
 *
 * To run a mutation, you first call `useRevokeServiceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeServiceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeServiceTokenMutation, { data, loading, error }] = useRevokeServiceTokenMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useRevokeServiceTokenMutation(baseOptions?: Apollo.MutationHookOptions<RevokeServiceTokenMutation, RevokeServiceTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeServiceTokenMutation, RevokeServiceTokenMutationVariables>(RevokeServiceTokenDocument, options);
      }
export type RevokeServiceTokenMutationHookResult = ReturnType<typeof useRevokeServiceTokenMutation>;
export type RevokeServiceTokenMutationResult = Apollo.MutationResult<RevokeServiceTokenMutation>;
export type RevokeServiceTokenMutationOptions = Apollo.BaseMutationOptions<RevokeServiceTokenMutation, RevokeServiceTokenMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($tenantId: String!, $userId: Int!) {
  tenant(tenantId: $tenantId) {
    deleteUser(userId: $userId)
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteLicenseDocument = gql`
    mutation deleteLicense($organizationId: String!, $tenantId: String!) {
  tenant(tenantId: $tenantId) {
    deleteLicense(organizationId: $organizationId)
  }
}
    `;
export type DeleteLicenseMutationFn = Apollo.MutationFunction<DeleteLicenseMutation, DeleteLicenseMutationVariables>;

/**
 * __useDeleteLicenseMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseMutation, { data, loading, error }] = useDeleteLicenseMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useDeleteLicenseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseMutation, DeleteLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseMutation, DeleteLicenseMutationVariables>(DeleteLicenseDocument, options);
      }
export type DeleteLicenseMutationHookResult = ReturnType<typeof useDeleteLicenseMutation>;
export type DeleteLicenseMutationResult = Apollo.MutationResult<DeleteLicenseMutation>;
export type DeleteLicenseMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseMutation, DeleteLicenseMutationVariables>;
export const DeleteTenantDocument = gql`
    mutation deleteTenant($organizationId: String!, $tenantId: String!) {
  organization(organizationId: $organizationId) {
    deleteTenant(tenantId: $tenantId)
  }
}
    `;
export type DeleteTenantMutationFn = Apollo.MutationFunction<DeleteTenantMutation, DeleteTenantMutationVariables>;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useDeleteTenantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMutation, DeleteTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMutation, DeleteTenantMutationVariables>(DeleteTenantDocument, options);
      }
export type DeleteTenantMutationHookResult = ReturnType<typeof useDeleteTenantMutation>;
export type DeleteTenantMutationResult = Apollo.MutationResult<DeleteTenantMutation>;
export type DeleteTenantMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMutation, DeleteTenantMutationVariables>;