import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RoleEditorState {
  id: string
  name: string
  applications: Array<{
    id: string
    title: string
    permissions: Array<{
      id: string
      checked: boolean
    }>
  }>
}
export const initialState: RoleEditorState = {
  id: ``,
  name: ``,
  applications: [],
}

export const { actions, reducer } = createSlice({
  name: `permission`,
  initialState,
  reducers: {
    load: (_, action: PayloadAction<RoleEditorState>) => {
      return action.payload
    },
    changeName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    toggleOne: (state, action: PayloadAction<{ permission: string; checked: boolean }>) => {
      for (const permission of state.applications.flatMap((x) => x.permissions)) {
        if (action.payload.permission === permission.id) {
          permission.checked = action.payload.checked
        }
      }
    },
    toggleMultiple: (state, action: PayloadAction<Array<{ permission: string; checked: boolean }>>) => {
      for (const permission of state.applications.flatMap((x) => x.permissions)) {
        for (const newValue of action.payload) {
          if (newValue.permission === permission.id) {
            permission.checked = newValue.checked
          }
        }
      }
    },
  },
})
