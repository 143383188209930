import { MFlexBlock, MPortalDialog, MPortalDialogProps } from '@mprise/react-ui'
import { Form, useFormikContext } from 'formik'
import React, { useCallback } from 'react'

export const FormikDebug = () => {
  const f = useFormikContext()
  const grabbed = { values: f.values, touched: f.touched, errors: f.errors }
  return <pre>{JSON.stringify(grabbed, undefined, 4)}</pre>
}

export const FormikDialog = (props: Omit<MPortalDialogProps, 'onSubmit' | 'onReset'>) => {
  const formik = useFormikContext()
  const handleSubmit = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      formik.submitForm()
    },
    [formik.submitForm]
  )
  const handleReset = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      formik.resetForm()
    },
    [formik.resetForm]
  )
  return (
    <MPortalDialog.Dialog minWidth={props.minWidth} open={props.open} onClose={props.onClose}>
      <Form>
        <MPortalDialog.Header onClose={props.onClose}>
          <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            {props.title}
          </MFlexBlock>
        </MPortalDialog.Header>
        <MPortalDialog.Content>{props.children}</MPortalDialog.Content>
        <MPortalDialog.Footer>
          <MFlexBlock justifyContent="flex-end">
            <MPortalDialog.SubmitButton>{props.submit}</MPortalDialog.SubmitButton>
          </MFlexBlock>
        </MPortalDialog.Footer>
      </Form>
    </MPortalDialog.Dialog>
  )
}

 