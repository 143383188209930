import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useMField } from '@mprise/react-ui'
import React from 'react'

export const MFieldCheckbox = ({ label }: { label: React.ReactNode }) => {
  const f = useMField()

  return (
    <FormControlLabel
      label={label}
      control={<Checkbox color="primary" name={f.name ?? undefined} checked={f.value} onChange={(_, checked) => f.onChange?.(checked, _)} />}
    />
  )
}
