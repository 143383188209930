import { PropTypes } from '@material-ui/core'
import { useMField } from '@mprise/react-ui'
import React from 'react'
import { ListBoxItem, MultiSelectListBox } from './multiselect-listbox'

export const MFieldMultiSelect = ({
  margin,
  suggestions,
  disabled,
  label,
  items,
  search,
  onSearch,
}: {
  margin?: PropTypes.Margin
  suggestions: ListBoxItem[]
  disabled?: boolean
  label: string
  items: ListBoxItem[]
  search: string
  onSearch: (text: string) => void
}) => {
  const f = useMField()
  return (
    <MultiSelectListBox
      margin={margin}
      suggestions={suggestions}
      disabled={disabled}
      label={label}
      items={items}
      selected={f.value}
      onChange={(x) => f.onChange?.(x)}
      search={search}
      onSearch={onSearch}
    />
  )
}
