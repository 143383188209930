import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TenantSelectState {
  dialog: boolean
  selected?: { organizationId: string; tenantId: string }
  tenants: Array<{
    id: string | null | undefined
    displayName: string | null | undefined
    organizationId: string | null | undefined
  }>
}
export const initialState: TenantSelectState = {
  dialog: false,
  tenants: [],
}

export const { actions, reducer } = createSlice({
  name: `tenantSelect`,
  initialState,
  reducers: {
    load: (state, action: PayloadAction<TenantSelectState['tenants']>) => {
      state.tenants = action.payload
    },
    select: (state, action: PayloadAction<{ organizationId: string; tenantId: string }>) => {
      state.selected = action.payload
      state.dialog = false
    },
    open: (state) => {
      state.dialog = true
    },
  },
})

export const selectTenantName = (state: TenantSelectState) => state.tenants.filter((x) => x.id === state.selected).map((x) => x.displayName)[0]
export const selectTenantSearch = (state: TenantSelectState, search: string) => {
  const predicate = createSearchRegExp(search)
  return search ? state.tenants.filter((x) => predicate(x.displayName ?? `-`)) : state.tenants
}

const createSearchRegExp = (text: string) => {
  const regex = new RegExp(text.replace(/[-[\]{}()*+?.,\\^$|]/g, `\\$&`), `i`)
  const predicate = (text: string) => Boolean(text.match(regex))
  return predicate
}
