import { Box, Card, CardHeader } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { Wrapper } from '../layout/wrapper'
import { CardErrorContent } from '../shared/card-error-content'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'
import { ActiveStatus } from '../shared/active-status'
import { useLicensesQuery } from '../graphql/generated'
import { FormattedDate, MFlex } from '@mprise/react-ui'
import { IconConfigure } from '../icons'
import { defined } from '../shared/typescript'
import Protected from '../shared/protected-route'

export const LicenseListRoute = () => {
  const licenseQuery = useLicensesQuery()
  const rows = licenseQuery.data?.tenants
    ?.flatMap((x) => x?.licenses ?? [])
    ?.filter(defined)
    .map((x) => ({
      url: `/license/${x.id}`,
      id: x.id,
      organizationName: x.tenant?.organization?.name,
      tenantName: x.tenant?.name,
      activeUntil: x.activeUntil,
      isActive: x.isActive,
      maxUsers: x.maxUsers,
      title: x.title,
    }))

  const loading = licenseQuery.loading
  const error = licenseQuery.error
  const refresh = () => {
    licenseQuery.refetch()
  }

  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_LICENSE_ADMIN','IDENTITYSERVER_ORGANIZATION_ADMIN']}>
    <Wrapper title="Licenses">
      <Box margin={2}>
        <Card>
          <CardHeader title="Licenses" />
          <CardErrorContent onRetry={refresh} error={error?.message} />
          <DataTable
            toolbar={
              <MFlex gap={2}>
                <TableRouteButton startIcon={<AddIcon />} to={`/licenses/new`} children="Add New License" />
                <TableRouteButton startIcon={<IconConfigure />} to={`/licenses/configure`} children="Configure Permissions" />
              </MFlex>
            }
            columns={[
              { field: `organizationName`, headerName: `Organization`, flex: 1 },
              { field: `tenantName`, headerName: `Tenant`, flex: 2 },
              { field: `title`, headerName: `Title`, flex: 2 },
              { field: `maxUsers`, headerName: `Max. Users`, flex: 1 },
              {
                field: 'isActive',
                headerName: `Active Until`,
                flex: 2,
                renderCell: (row) => (
                  <ActiveStatus
                    label={<FormattedDate date={new Date(row.row.activeUntil /* HACK: remove date constructor when string is working again */)} />}
                    active={row.row.isActive}
                  />
                ),
              },
            ]}
            data={rows ?? []}
            refresh={refresh}
            loading={loading}
          />
        </Card>
      </Box>
    </Wrapper>
    </Protected>
  )
}
