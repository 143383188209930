import { MBlock, MText, MTextColor } from '@mprise/react-ui'
import { Formik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { namedOperations, useDeleteLicenseTemplateMutation, useLicenseTemplatesQuery } from '../graphql/generated'
import { FormikDialog } from '../shared/react-formik-dialog'
import { MutationErrorMessage, QueryErrorMessage } from '../shared/apollo'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'

export const LicenseTemplateDeleteDialog = () => {
  const { templateId } = useParams() as { templateId: string }
  const navigate = useNavigate()
  const licenseQuery = useLicenseTemplatesQuery()
  const license = licenseQuery.data?.licenseTemplates?.find((x) => x?.id === templateId)

  const [deleteTemplate, deleteTemplateState] = useDeleteLicenseTemplateMutation({ refetchQueries: [namedOperations.Query.LicenseTemplates] })
  const handleDeleteTemplate = async () => {
    if (await deleteTemplate({ variables: { templateId: Number(templateId) } })) {
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/licensetemplates`, { replace: true })
  }

  return (
    <Formik initialValues={{}} onSubmit={handleDeleteTemplate}>
      <FormikDialog title="Delete License Template" submit="Delete" onClose={handleClose} open>
        <SavingSwitchPanel mutations={[deleteTemplateState]}>
          <QueryErrorMessage query={licenseQuery} />
          <MutationErrorMessage mutation={deleteTemplateState} />
          <MBlock padding={5}>
            <MText block textVariant="content" textColor={MTextColor.dark}>
              Are you sure you would like to remove{' '}
              <MText inline textVariant="content bold" textColor={MTextColor.dark}>
                {license?.name ?? `#${templateId}`}
              </MText>
              ?
            </MText>
          </MBlock>
        </SavingSwitchPanel>
      </FormikDialog>
    </Formik>
  )
}
