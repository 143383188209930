import * as yup from 'yup'

// here we hide the weirdness of yup typings

export { yup }

export const yupObject = <T extends {}>(fields: Record<keyof T, yup.AnySchema>): yup.ObjectSchema<T> => {
  return yup.object().shape<T>(fields as any)
}

export const yupObjectArray = <T extends {}>(fields: Record<keyof T, yup.AnySchema>): yup.ArraySchema<yup.SchemaOf<T>> => {
  return yup.array().of(
    yup
      .object()
      .shape<T>(fields as any)
      .required()
  )
}

export const yupStringArray = <T extends yup.StringSchema>(item: T) => {
  return yup.array().of(item.required())
}

export const yupEnum = <E,>(enumeration: E): yup.StringSchema<string | undefined> => {
  return yup.string().oneOf(Object.values(enumeration))
}

export const yupEnumArray = <E,>(enumeration: E): yup.ArraySchema<yup.StringSchema> => {
  return yup.array().of(yup.string().oneOf(Object.values(enumeration)).required())
}
