import { CssBaseline } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AppRoutes } from './route-root'

export const App = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const {
    i18n: { language }
  } = useTranslation()

  return (
    <>
      <Helmet defaultTitle='Agriware' titleTemplate='%s | Agriware' htmlAttributes={{ lang: language }} />
      <CssBaseline />
      <AppRoutes />
    </>
  )
}
