import { Box, Breadcrumbs, Card, CardHeader, Link } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSpecificTenantQuery, useSpecificTenantServicesQuery } from '../../graphql/generated'
import { IconBreadcrumbDivider } from '../../icons'
import { Wrapper } from '../../layout/wrapper'
import { ActiveStatus } from '../../shared/active-status'
import { CardErrorContent } from '../../shared/card-error-content'
import Protected from '../../shared/protected-route'
import { RouterLink } from '../../shared/router-link'
import { defined, NotOptional } from '../../shared/typescript'
import { TableRouteButton } from '../../table/button'
import { DataTable } from '../../table/table'
import { ServiceTokenRevokeDialog } from './revoke'

export const ServiceTokenListRoute = () => {
  const { organizationId, tenantId, serviceId } = useParams() as { organizationId: string; tenantId: string; serviceId: string }
  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const tenant = tenantQuery.data?.tenants?.[0]
  const tenantServicesQuery = useSpecificTenantServicesQuery({ variables: { tenantId } })
  const service = tenantServicesQuery.data?.tenants?.[0]?.services?.find((x) => x?.id === serviceId)
  const tokens = service?.tokens
  const rows = tokens?.filter(defined).map((x) => ({
    id: x.id,
    tokenPartialId: `\u2026${x.id?.substr(-6)}`,
    serviceId: serviceId,
    displayName: x.name,
    status: x.active ? `Enabled` : `Revoked`,
    isEnabled: x.active,
  }))
  type Row = NotOptional<typeof rows>[0]
  const refresh = () => {
    tenantServicesQuery.refetch()
  }
  const [revokeToken, setRevokeToken] = useState<{ id: string; name: string } | null>(null)
  const handleCloseRevoke = () => {
    setRevokeToken(null)
    refresh()
  }
  const handleOpenRevoke = (id: string, name: string) => setRevokeToken({ id, name })

  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_SERVICE_ADMIN','IDENTITYSERVER_ORGANIZATION_ADMIN']}>
    <Wrapper title="Service Tokens">
      <ServiceTokenRevokeDialog
        open={!!revokeToken}
        onClose={handleCloseRevoke}
        tenantId={tenantId}
        tokenId={revokeToken?.id ?? ``}
        tokenName={revokeToken?.name ?? ``}
      />
      <Box margin={2}>
        <Card>
          <CardHeader
            title={
              <Breadcrumbs separator={<IconBreadcrumbDivider fontSize="small" />}>
                <Link color="textPrimary" component={RouterLink} to={`/organizations`}>
                  {tenant?.organization?.name ?? `Organization`}
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/organization/${organizationId}/tenants`}>
                  {tenant?.name ?? `Tenant`}
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/organization/${organizationId}/tenant/${tenantId}/services`}>
                  {service?.name}
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/organization/${organizationId}/tenant/${tenantId}/service/${serviceId}/tokens`}>
                  Tokens
                </Link>
              </Breadcrumbs>
            }
          />
          <CardErrorContent onRetry={refresh} error={tenantServicesQuery.error?.message} />
          <DataTable
            toolbar={
              <TableRouteButton
                startIcon={<AddIcon />}
                to={`/organization/${organizationId}/tenant/${tenantId}/service/${serviceId}/tokens/new`}
                children="Add New Token"
              />
            }
            columns={[
              { field: 'tokenPartialId', headerName: `Token ID`, flex: 1 },
              { field: 'displayName', headerName: `Name`, flex: 2 },
              { field: 'status', headerName: `Status`, flex: 1, renderCell: (cell) => <ActiveStatus active={cell.row.isEnabled} label={cell.row.status} /> },
            ]}
            actions={[
              (row) => {
                return {
                  iconProps: { color: `action` },
                  icon: `delete`,
                  tooltip: `Revoke`,
                  hidden: !row.isEnabled,
                  onClick: (row) => {
                    if (!Array.isArray(row) && row.id) {
                      handleOpenRevoke(row.id, row.displayName)
                    }
                  },
                }
              },
            ]}
            data={rows ?? []}
            refresh={refresh}
            loading={tenantServicesQuery.loading}
          />
        </Card>
      </Box>
    </Wrapper>
    </Protected>
  )
}
