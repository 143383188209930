import { Box, Card, CardActions, CardContent, Grid, makeStyles, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React from 'react'
import { CardErrorContent } from './card-error-content'

export const Form = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box margin={2}>
      <Grid xs={12} lg={6}>
        <form noValidate autoComplete="off">
          <Card>{children}</Card>
        </form>
      </Grid>
    </Box>
  )
}

export const FormTitle = ({
  primary,
  secondary,
  action,
  small,
}: {
  primary: React.ReactNode
  secondary?: React.ReactNode
  action?: React.ReactNode
  small?: boolean
}) => {
  return (
    <Grid container direction="row">
      <Box margin={2} flex={1}>
        <Typography component="h2" variant={small ? `subtitle1` : `h5`} color="textPrimary">
          {primary}
        </Typography>
        {secondary && (
          <Typography component="h3" variant={small ? `subtitle2` : `h6`} color="textSecondary">
            {secondary}
          </Typography>
        )}
      </Box>
      {action && <Box margin={2}>{action}</Box>}
    </Grid>
  )
}

export const FormCaption = ({
  title,
  action,
  icon,
  onClick,
}: {
  title: React.ReactNode
  action?: React.ReactNode
  icon?: React.ReactNode
  onClick?: React.MouseEventHandler
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} onClick={onClick}>
      <Grid container direction="row" alignItems="center">
        {icon && <Box marginLeft={1}>{icon}</Box>}
        <Box margin={1} flex={1}>
          <Typography component="h3" variant={`h5`} color="textPrimary">
            {title}
          </Typography>
        </Box>
        {action && <Box marginRight={1}>{action}</Box>}
      </Grid>
    </Box>
  )
}

export const FormContent = CardContent
export const FormActions = CardActions
export const FormErrorContent = CardErrorContent

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: grey[50],
  },
}))
