import { MFieldDate, MFieldInput, MFieldInteger, MHeader, MText, MTextColor } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  namedOperations,
  useCreateLicenseMutation,
  useCreateTenantMutation,
  useGlobalApplicationsQuery,
  useKnownApplicationsLazyQuery,
  useLicenseTemplatesQuery,
  useModify3LicenseMutation,
  useAllTenantsQuery,
  useUpdateTenantMutation,
} from '../graphql/generated'
import { FormikDialog } from '../shared/react-formik-dialog'
import { useSelectiveSearch } from '../organization/SelectiveSearch'
import { MutationErrorMessage, QueryErrorMessage } from '../shared/apollo'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldMultiSelect } from '../shared/mfield-multiselect'
import { defined } from '../shared/typescript'
import { Alerts } from '../shared/alerts'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { knownLoginMethods, useLoginMethodSearch } from './login-methods'
import { Box } from '@material-ui/core'

const nextYear = () => {
  var date = new Date()
  date.setFullYear(date.getFullYear() + 1)
  return date
}

type TenantForm = {
  name: string
  activeUntil: string | Date
  maxUsers: number
  licenses: Array<string>
  applicationIds: Array<string>
  loginMethods: Array<string>
}
const emptyTenantForm: TenantForm = {
  name: ``,
  activeUntil: nextYear(),
  maxUsers: 100,
  licenses: [],
  applicationIds: [],
  loginMethods: [],
}

export const TenantNewDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const { organizationId } = useParams() as { organizationId: string }
  const licenseQuery = TenantNewDialog.useLicenseTemplatesQuery()
  const licenseSearch = TenantNewDialog.useLicenseTemplatesSearch()

  const applicationQuery = useGlobalApplicationsQuery()
  const applications = applicationQuery.data?.applications
  const applicationSearch = TenantNewDialog.useApplicationSearch()

  const loginMethodSearch = useLoginMethodSearch()

  const [createTenant, createTenantMutation] = useCreateTenantMutation({ refetchQueries: [namedOperations.Query.Me, namedOperations.Query.AllTenants] })
  const [modifyTenant, modifyTenantMutation] = useUpdateTenantMutation()
  const [createLicense, createLicenseMutation] = useCreateLicenseMutation()
  const [modifyLicense, modifyLicenseMutation] = useModify3LicenseMutation({ refetchQueries: [namedOperations.Query.Licenses] })

  const handleSave = async (form: TenantForm) => {
    const formLicenses = form.licenses.map((x) => licenseQuery.data?.find((y) => y?.id === x)).filter(defined)
    const formApplications = form.applicationIds.map((x) => applicationQuery.data?.applications?.find((y) => y?.id === x)).filter(defined)

    const permissions = unique(formLicenses.flatMap((x) => x.permissions ?? [])).filter(defined)
    const applicationsByPermissions =
      applications
        ?.filter((app) => hasOverlap(permissions, app?.permissions ?? []))
        .map((app) => app?.id)
        .filter(defined) ?? []
    const formApplicationIds = formApplications.map((x) => x.id).filter(defined)
    const applicationIds = unique([...applicationsByPermissions, ...formApplicationIds])

    let tenantId = createTenantMutation.data?.organization?.createTenant?.id ?? `?`
    if (!createTenantMutation.data) {
      const tenant = await createTenant({ variables: { organizationId: organizationId, name: form.name } })
      if (tenant.data?.organization?.createTenant) {
        tenantId = tenant.data?.organization?.createTenant?.id ?? `?`
      }
    }
    if (!modifyTenantMutation.data) {
      const modified = await modifyTenant({ variables: { tenantId, applicationIds, name: form.name, loginMethods: form.loginMethods } })
      if (modified.errors) {
        return
      }
    }
    let licenseId = createLicenseMutation.data?.tenant?.createLicense?.id ?? `?`
    if (!createLicenseMutation.data) {
      const license = await createLicense({
        variables: { title: `Initial license ${form.name}`, tenantId, permissions },
      })
      if (license.errors) {
        return
      }
      licenseId = license.data?.tenant?.createLicense?.id ?? `?`
    }
    if (!modifyLicenseMutation.data) {
      const modified = await modifyLicense({
        variables: {
          licenseId: Number(licenseId),
          title: `Initial license ${form.name}`,
          activeUntil: form.activeUntil as any,
          maxUsers: form.maxUsers,
        },
      })
      if (modified.errors) {
        return
      }
    }
    alerts.push(alertText.created(`Tenant`), `success`)
    navigate(`/organization/${organizationId}/tenant/${tenantId}/users`, { replace: true })
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenants`, { replace: true })
  }

  return (
    <>
      <Formik initialValues={emptyTenantForm} enableReinitialize onSubmit={handleSave}>
        <FormikDialog title="New Tenant" submit="Create Tenant" onClose={handleClose} open>
          <Box minWidth={400}>
            <SavingSwitchPanel mutations={[createTenantMutation, modifyTenantMutation, createLicenseMutation, modifyLicenseMutation]}>
              <QueryErrorMessage query={licenseQuery} />
              <QueryErrorMessage query={applicationQuery} />
              <MutationErrorMessage mutation={[createTenantMutation, modifyTenantMutation, createLicenseMutation, modifyLicenseMutation]} />
              <MHeader>
                <MText block textVariant="header" textColor={MTextColor.header}>
                  General
                </MText>
              </MHeader>
              <Field component={MFieldConnector} name="name" label={t(`Name`)}>
                <MFieldInput autoFocus autoComplete="off" />
              </Field>
              <MHeader>
                <MText block textVariant="header" textColor={MTextColor.header}>
                  License
                </MText>
              </MHeader>
              <Field component={MFieldConnector} name="activeUntil" label={t(`Active Until`)}>
                <MFieldDate />
              </Field>
              <Field component={MFieldConnector} name="maxUsers" label={t(`Maximum Users`)}>
                <MFieldInteger min={0} />
              </Field>
              <Field component={MFieldConnector} name="licenses" label={t(`Templates`)}>
                <MFieldMultiSelect
                  margin="dense"
                  label=""
                  items={
                    licenseQuery.data?.filter(defined).map((x) => ({
                      id: x.id ?? `TODO`,
                      name: x.name ?? x.id ?? `-`,
                      secondary: (x.name ? x.id : undefined) ?? undefined,
                    })) ?? []
                  }
                  {...licenseSearch}
                />
              </Field>

              <MHeader>
                <MText block textVariant="header" textColor={MTextColor.header}>
                  Additional Applications
                </MText>
              </MHeader>

              <Field component={MFieldConnector} name="applicationIds" label={t(`Applications`)}>
                <MFieldMultiSelect
                  margin="dense"
                  label=""
                  items={
                    applications?.filter(defined).map((x) => ({
                      id: x.id ?? `TODO`,
                      name: x.name ?? x.id ?? `-`,
                      secondary: (x.name ? x.id : undefined) ?? undefined,
                    })) ?? []
                  }
                  {...applicationSearch}
                />
              </Field>

              <MHeader>
                <MText block textVariant="header" textColor={MTextColor.header}>
                  Allowed Login Methods
                </MText>
              </MHeader>

              <Field component={MFieldConnector} name="loginMethods" label={t(`Login Methods`)}>
                <MFieldMultiSelect margin="dense" label="" items={knownLoginMethods} {...loginMethodSearch} />
              </Field>
            </SavingSwitchPanel>
          </Box>
        </FormikDialog>
      </Formik>
    </>
  )
}
TenantNewDialog.useLicenseTemplatesQuery = () => {
  const query = useLicenseTemplatesQuery()
  const data = query.data?.licenseTemplates
  return { ...query, data }
}

TenantNewDialog.useLicenseTemplatesSearch = () => {
  const query = TenantNewDialog.useLicenseTemplatesQuery()
  const [search, setSearch] = useState(``)
  const suggestions =
    useSelectiveSearch(search, query.data ?? [], (x) => x?.name)
      .filter(defined)
      .map((x) => ({ id: x.id ?? ``, name: x.name ?? `` })) ?? []

  return {
    search,
    onSearch: setSearch,
    suggestions: suggestions,
  }
}

TenantNewDialog.useApplicationSearch = () => {
  const [doQuery, query] = useKnownApplicationsLazyQuery()
  const [search, setSearch] = useState(``)
  useEffect(() => {
    doQuery({ variables: { text: search } })
  }, [search])
  const suggesions = query.data?.applications?.filter(defined).map((x) => ({ id: x.id ?? ``, name: x.name ?? `` })) ?? []

  return {
    search,
    onSearch: setSearch,
    suggestions: suggesions,
  }
}

const unique = <T extends unknown>(items: T[]) => Array.from(new Set(items).values())
const hasOverlap = <T extends unknown>(l: T[], r: T[]) => l.some((x) => r.includes(x))
