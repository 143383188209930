import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { MFlexBlock, MPortalDialog, MText, MTextColor } from '@mprise/react-ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { namedOperations, useDeleteTenantMutation, useSpecificTenantRolesQuery, useLicensesQuery } from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'

export const TenantDeleteDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }

  const tenantQuery = useSpecificTenantRolesQuery({ variables: { tenantId }})
  const licenseQuery = useLicensesQuery()

  const [hasLicenseAvailable, setHasLicenseAvailable] = useState<boolean>(true)
  const alerts = Alerts.useAlert()
  const [deleteTenant, deleteTenantMutation] = useDeleteTenantMutation()

  useEffect(() => {
    if (tenantQuery.data?.tenants && licenseQuery.data?.tenants) {
      for (const tenant of tenantQuery.data.tenants) {
        const tenantLicense = licenseQuery.data.tenants.find(t => t?.id === tenant?.id)

        const hasActiveLicense = tenantLicense?.licenses?.some(license => license?.isActive)

        if (hasActiveLicense) {
          setHasLicenseAvailable(true)
          return
        }
      }
      setHasLicenseAvailable(false)
    }
  }, [tenantQuery, licenseQuery])

  const save: ButtonAsyncClickHandler = async (e) => {
    e.preventDefault()

    if (!hasLicenseAvailable) {
      await deleteTenant({
        refetchQueries: [namedOperations.Query.MyOrganizations],
        variables: {
          organizationId: organizationId,
          tenantId: tenantId
        },
      }).then(result => {
        if (result.data?.organization?.deleteTenant) {
          alerts.push(`Tenant deleted.`, `success`)
          handleClose()
        }
      }).catch(e => {
        console.error(e)
        return
      })
    }
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenants`, { replace: true })
  }

  return (
      <MPortalDialog.Dialog minWidth="sm" open onClose={handleClose}>
        <SavingSwitchPanel mutations={[deleteTenantMutation]}>
          <MPortalDialog.Form onSubmit={save}>
          <Box className={classes.lineColorOverride}>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t('LABEL_DELETE_TENANT')}
              </MFlexBlock>
            </MPortalDialog.Header>
          </Box>
          {licenseQuery.loading || tenantQuery.loading
            ? <MPortalDialog.Content>
              <MFlexBlock padding={[2, 3]} alignItems='center' gap={2}>
                <CircularProgress size={24} />
                <MText inline textVariant="content" textColor={MTextColor.dark}>
                  {'Loading...'}
                </MText>
              </MFlexBlock>
            </MPortalDialog.Content>
            : <>
              <MPortalDialog.Content>
                <Box paddingX={2}>
                  <MutationErrorMessage mutation={deleteTenantMutation} />
                  {hasLicenseAvailable
                    ? <MText block textVariant="content bold" textColor={MTextColor.dark}>
                      Deactivate all licenses in this tenant before deleting.
                    </MText>
                    : <MText block textVariant="content" textColor={MTextColor.dark}>
                      Are you sure you want to delete tenant
                      <b style={{ fontWeight: 900 }}>{` '${tenantQuery.data?.tenants?.[0]?.name}' `}</b>
                      and all its data? This action cannot be undone!
                    </MText>
                  }
                </Box>
              </MPortalDialog.Content>
              <MPortalDialog.Footer>
                <MFlexBlock justifyContent="flex-end">
                  {!hasLicenseAvailable && <Box className={classes.buttonColorOverride}>
                    <MPortalDialog.SubmitButton>{t('LABEL_DELETE_TENANT')}</MPortalDialog.SubmitButton>
                  </Box>}
                </MFlexBlock>
              </MPortalDialog.Footer>
            </>}
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  lineColorOverride: {
    '& > div:first-child': {
      borderBottom: '4px solid #ff3333',
    }
  },
  buttonColorOverride: {
    '& > button': {
      backgroundColor: '#ff3333',
    },
    '& > button:hover': {
      backgroundColor: 'darkred',
    }
  }
}))