import { Box, CircularProgress, createStyles, Fade, Icon, IconButton, IconProps, Input, makeStyles, PropTypes, Theme, Typography } from '@material-ui/core'
import { DataGrid, GridCellParams, GridColDef, GridRowParams } from '@material-ui/data-grid'
import { MAvatar, MFlex, MFlexBlock, NextPageView } from '@mprise/react-ui'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { SwitchTransition } from 'react-transition-group'
import { IconRefresh, IconSearch } from '../icons'
import { LoadingSwitchPanel } from '../organization/loading-switch-panel'
import { Collector, useSelectiveSearch } from '../organization/SelectiveSearch'

export type ActionDefinition<T> = (row: T) => { iconProps: IconProps; icon: string; tooltip: string; onClick: (row: T) => void }
/**
 * Rows with a `url` property will be clickable
 */
export const DataTable = <T extends object>({
  columns,
  data,
  refresh,
  toolbar,
  actions,
  loading,
  onRowClick,
  onNextPage,
  onSearch,
  hasNextPage,

}: {
  columns: Array<GridColDef>
  data: T[]
  actions?: Array<ActionDefinition<T>>
  onRowClick?: (row: T) => void
  onNextPage?: (cursor: string) => void
  onSearch?: (searchText: string) => void
  hasNextPage?: boolean
  refresh: () => void
  loading: boolean
  toolbar?: React.ReactNode
  totalItems?: number
  paginationMode?: 'server' | 'client'
  page?: number
  pageSize?: number
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const canRowClick = onRowClick || (Array.isArray(data) && data.some(x => 'url' in x))

  const actionWidth = (actions?.length ?? 0) * 48 + 20
  const actionColumn: GridColDef = {
    field: `action`,
    headerName: ` `,
    align: `center`,
    width: actionWidth,
    renderCell: makeActionRenderer(actions ?? [])
  }

  const handleRowClick = (e: GridRowParams) => {
    onRowClick?.(e.row as T)

    const url = Object(e.row).url
    if (url) {
      navigate(url)
    }
  }

  const [search, setSearch] = useState(``)
  const searchCollectors = columns.map(
    (x): Collector<T> => {
      return y => Object(y)[x.field]?.toString() ?? null
    }
  )

  const filtered = useSelectiveSearch<T>(search, data, ...searchCollectors)

  const handleSearch = (text: string) => {
    setSearch(text)
    onSearch && onSearch(text)
  }
  return (
    <>
      <Box display='flex'>
        <Box display='flex' flex={1}>
          {toolbar && (
            <Box marginX={3} marginY={2}>
              {toolbar}
            </Box>
          )}
        </Box>
        <Box display='flex' alignItems='flex-end'>
          <MFlexBlock margin={[3, 2]} gap={1}>
            <Input margin='none' startAdornment={<IconSearch />} type='search' value={search} onChange={e => handleSearch(e.target.value)} />
            <IconButton onClick={refresh}>
              <RefreshOrLoadingIcon loading={loading} />
            </IconButton>
          </MFlexBlock>
        </Box>
      </Box>

      <LoadingSwitchPanel loading={data.length === 0 && loading}>
        <DataGrid
          className={classes.root}
          autoHeight
          rows={filtered}
          disableColumnFilter
          columns={actions ? columns.concat(actionColumn) : columns}
          disableColumnMenu
          disableColumnSelector
          components={{
            Footer(props) {
              return onNextPage && (hasNextPage) ? (
                <MFlex style={{ minHeight: '20px' }}>
                  <NextPageView cursor={String(data.length ?? 0)} fetch={onNextPage} />
                </MFlex>
              ) : null
            },

          }}
          // autoPageSize
          disableSelectionOnClick
          density='comfortable'
          onRowClick={canRowClick ? handleRowClick : undefined}
        />

      </LoadingSwitchPanel>
    </>
  )
}

const RefreshOrLoadingIcon = ({ loading }: { loading: Boolean }) => {
  return (
    <SwitchTransition>
      <Fade key={Number(loading)} timeout={100}>
        {loading ? <CircularProgress color='inherit' size='1em' /> : <IconRefresh />}
      </Fade>
    </SwitchTransition>
  )
}

const makeActionRenderer = <T,>(actions: Array<ActionDefinition<T>>) => {
  return (e: GridCellParams) => {
    const buttons = actions!.map(x => {
      const description = x(e.row as T)
      return (
        <IconButton
          key={description.icon}
          color={description.iconProps.color as PropTypes.Color}
          title={description.tooltip}
          onClick={ev => {
            ev.stopPropagation()
            description.onClick(e.row as T)
          }}
        >
          <Icon {...description.iconProps}>{description.icon}</Icon>
        </IconButton>
      )
    })
    return <MFlex>{buttons}</MFlex>
  }
}

DataTable.useAvatarColumn = (Icon: React.ComponentType, fieldNameOfId: string = `id`): GridColDef => {
  const renderAvatarCell = useCallback(
    (e: GridCellParams) => {
      return (
        <MAvatar.Badge seed={e.row[fieldNameOfId] ?? ``}>
          <Icon />
        </MAvatar.Badge>
      )
    },
    [Icon]
  )

  return {
    field: `avatar`,
    headerName: ` `,
    width: 64,
    sortable: false,
    align: `center`,
    renderCell: renderAvatarCell
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 0
    }
  })
)
