// formik compares contents of form and considers initialValues to be unchanged
// this code is meant as repeatable fix and marker for locations where this occurs

export interface FormikCompareFix {
  __formikKey: number
}

let nextFormikKey = 0
export const nextFormikCompareFix = (): FormikCompareFix => ({ __formikKey: ++nextFormikKey })
export const withFormikCompareFix = <T extends {}>(input: T): T & FormikCompareFix => ({ ...input, __formikKey: ++nextFormikKey })
export const withoutFormikCompareFix = <T extends {}>({ __formikKey, ...rest }: T & FormikCompareFix): T => rest as any
