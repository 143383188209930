import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import jsonwebtoken from 'jsonwebtoken'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetPasswordResponseMutation } from '../graphql/generated'
import { LockedLayout } from './layout'
import { MutationErrorMessage } from '../shared/apollo'

export const LoginInvitationPage = () => {
  const token = window.location.hash.replace(`#token=`, ``)
  const jwt = jsonwebtoken.decode(token)
  const email = typeof jwt === `object` ? jwt?.['email'] : null

  const navigate = useNavigate()
  const [doResetPassword, resetPasswordMutation] = useResetPasswordResponseMutation()
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const token = form.elements.namedItem(`token`) as HTMLInputElement
    const password = form.elements.namedItem(`password`) as HTMLInputElement

    const res = await doResetPassword({
      variables: {
        token: token.value,
        password: password.value,
      },
    }).catch(() => null)
    if (res?.data?.resetPasswordResponse) {
      navigate(`/login`)
    }
  }

  if (resetPasswordMutation.loading) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Set up a new account</LockedLayout.Header>
        <LockedLayout.Loading>Applying changes...</LockedLayout.Loading>
      </LockedLayout>
    )
  }

  if (resetPasswordMutation?.data?.resetPasswordResponse === false) {
    return (
      <LockedLayout>
        <LockedLayout.Header>Unable to set your password</LockedLayout.Header>
        <LockedLayout.Message>Try to login with your previously set email</LockedLayout.Message>
        <LockedLayout.Actions
          left={{ type: `url`, url: `/forgot-password`, children: `Forgot Password` }}
          right={{ type: `url`, url: `/login`, children: `Log In` }}
        />
      </LockedLayout>
    )
  }

  return (
    <StyledForm method="POST" onSubmit={onSubmit}>
      <LockedLayout>
        <MutationErrorMessage mutation={resetPasswordMutation} />
        <LockedLayout.Header>Set up a new account</LockedLayout.Header>
        <input type="hidden" name="token" value={token} />
        <LockedLayout.Row>
          <TextField
            type="email"
            label="Email"
            name="email"
            placeholder="email@address.com"
            value={email}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </LockedLayout.Row>
        <LockedLayout.Row>
          <TextField label="Password" name="password" type="password" fullWidth InputLabelProps={{ shrink: true }} />
        </LockedLayout.Row>
        {resetPasswordMutation.error && (
          <LockedLayout.Row>
            <Alert severity="error">{resetPasswordMutation.error.message}</Alert>
          </LockedLayout.Row>
        )}
        <LockedLayout.Actions left={{ type: `url`, url: `/login`, children: `Back` }} right={{ type: `submit`, children: `Continue` }} />
      </LockedLayout>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: contents;
`
