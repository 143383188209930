import { LocalDate } from '@js-joda/core'
import { Box, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useMField } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useKnownEmailsLazyQuery, useSpecificTenantUsersQuery, useUpdateUserMutation } from '../graphql/generated'
import { FormikDialog } from '../shared/react-formik-dialog'
import { MutationErrorMessage } from '../shared/apollo'
import { MFieldConnector } from '../shared/mfield-adapter'
import { defined } from '../shared/typescript'
import { Alerts } from '../shared/alerts'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { useDebounceValue } from '../shared/use-debounced-value'

interface FormValues {
  email: string
  activeUntil: LocalDate | null
}

const intialValues: FormValues = {
  email: ``,
  activeUntil: null,
}

export const UserNewDialog = () => {
  const navigate = useNavigate()
  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }

  const [updateUser, updateUserMutation] = useUpdateUserMutation()
  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 300)
  const DEFAULT_PAGE_SIZE = 25
  const tenantUsersQuery = useSpecificTenantUsersQuery({
    variables: { filter: { tenant: { id: { eq: tenantId } } }, skip: 0, take: DEFAULT_PAGE_SIZE, search: debouncedSearch },
    fetchPolicy: 'cache-and-network'
  })

  const handleSave = async (form: FormValues) => {
    const created = await updateUser({
      variables: {
        tenantId,
        email: form.email!,
        roleIds: [],
        activeUntil: form.activeUntil?.toJSON(),
      },
    })
    tenantUsersQuery.refetch()
  
    if (created.data?.tenant?.updateUser) {
      alerts.push(alertText.created(`User`), `success`)
      navigate(`/organization/${organizationId}/tenant/${tenantId}/user/${created?.data?.tenant?.updateUser?.id}`, { replace: true })
    }

  }
  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/users`, { replace: true })
  }

  return (
    <>
      <Formik initialValues={intialValues} enableReinitialize onSubmit={handleSave}>
        <FormikDialog title="New User" submit="Create User" onClose={handleClose} open>
          <SavingSwitchPanel mutations={[updateUserMutation]}>
            <MutationErrorMessage mutation={updateUserMutation} />
            <Box minWidth={400} padding={2}>
              <Field component={MFieldConnector} name="email">
                <UserNewDialogInput />
              </Field>
            </Box>
          </SavingSwitchPanel>
        </FormikDialog>
      </Formik>
    </>
  )
}

const UserNewDialogInput = () => {
  const f = useMField()
  const [doUserQuery, userQuery] = useKnownEmailsLazyQuery()

  return (
    <Autocomplete
      options={Array.from(new Set(userQuery.data?.accounts?.map((x) => x?.email).filter(defined) ?? []))}
      freeSolo
      includeInputInList
      onInputChange={(_, text) => {
        doUserQuery({ variables: { text } })
        f.onChange?.(text)
      }}
      getOptionLabel={(option) => option ?? `-`}
      value={f.value?.toString() ?? ``}
      onChange={(_, text) => f.onChange?.(text)}
      renderInput={(params) => <TextField {...params} autoFocus type="email" label="Email" />}
    />
  )
}
