import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { usePermissionsForLicenseQuery, usePermissionsPerApplicationQuery, useSetLicensedPermissionsMutation } from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { useSelectiveSearch } from '../organization/SelectiveSearch'
import { MutationErrorMessage } from '../shared/apollo'
import { MFieldConnector } from '../shared/mfield-adapter'
import { MFieldMultiSelect } from '../shared/mfield-multiselect'
import { FormikDialog } from '../shared/react-formik-dialog'
import { defined } from '../shared/typescript'
import { useLocalState } from '../utils'

type ApplicationForm = {
  permissions: Array<string>
}

export const LicenseConfigureDialog = () => {
  const params = useParams() as { page: string }
  const originPage = params.page ?? 'licenses'

  const { t } = useTranslation()
  const navigate = useNavigate()
  const permissionsPerAppQuery = LicenseConfigureDialog.usePermissionsPerAppQuery()
  const permissionsPerApp = permissionsPerAppQuery.data
  const licPermissionQuery = usePermissionsForLicenseQuery({})
  const licPermissions = licPermissionQuery.data?.licensedPermissions

  const [search, setSearch] = useState(``)
  const suggestions = useSelectiveSearch(
    search,
    permissionsPerAppQuery.data,
    (x) => x.id,
    (x) => x.name,
    (x) => x.group
  )
  const [modify, modifyMutation] = useSetLicensedPermissionsMutation()
  const handleSave = async (form: ApplicationForm) => {
    await modify({
      variables: {
        permissions: form.permissions,
      },
    })
    handleClose()
  }

  const handleClose = () => {
    navigate(`/${originPage}`, { replace: true })
  }

  const [initialValues] = useLocalState<ApplicationForm>(
    () => ({
      permissions: licPermissions?.map((x) => x?.id).filter(defined) ?? [],
    }),
    [licPermissions]
  )

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSave}>
      <FormikDialog minWidth="sm" title="Configure" submit="Configure" onClose={handleClose} open>
        <SavingSwitchPanel mutations={[modifyMutation]}>
          <MutationErrorMessage mutation={modifyMutation} />
          <Field component={MFieldConnector} name="permissions" label={t(`Requires a license`)}>
            <MFieldMultiSelect items={permissionsPerApp ?? []} suggestions={suggestions} label="Permission" onSearch={(s) => setSearch(s)} search={search} />
          </Field>
        </SavingSwitchPanel>
      </FormikDialog>
    </Formik>
  )
}

LicenseConfigureDialog.usePermissionsPerAppQuery = () => {
  const { t } = useTranslation()
  const query = usePermissionsPerApplicationQuery()
  const permissions = query.data?.applications?.flatMap(
    (y) => y?.permissions?.filter(defined).map((x) => ({ id: x, name: t(x), group: t(y?.name ?? `-`) })) ?? []
  )
  return { ...query, data: permissions }
}
