import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export const RouterLink = Link

export const RouterLinkPlain = (props: LinkProps) => {
  const classes = useStyles()
  return <Link {...props} className={classes.never} />
}

export const LinkPlainText = (props: LinkProps) => {
  const classes = useStyles()
  return <Link {...props} className={classes.hoverUnderline} />
}

const useStyles = makeStyles((theme) => ({
  hoverUnderline: {
    color: `inherit`,
    textDecoration: `none`,
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: `underline`,
    },
  },
  never: {
    color: `inherit`,
    textDecoration: `none`,
  },
}))
