import styled from '@emotion/styled'
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { spacing } from '@mprise/react-ui'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsync, ButtonAsyncClickHandler } from '../button/async'
import {
  useDeleteRoleMutation,
  useEditRoleMutation,
  useSpecificTenantApplicationsQuery,
  useSpecificTenantQuery,
  useSpecificTenantRolesQuery,
} from '../graphql/generated'
import { IconBreadcrumbDivider } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { CardErrorContent } from '../shared/card-error-content'
import { Form, FormActions, FormCaption, FormContent } from '../shared/form'
import Protected from '../shared/protected-route'
import { RouterLink } from '../shared/router-link'
import { defined } from '../shared/typescript'
import { useLocalState } from '../utils'
import { PermissionListItem } from './components/permission-list-item'
import { PermissionsCard } from './components/permissions-card'
import { actions, initialState, reducer, RoleEditorState } from './edit-state'

export const RoleEditRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const { organizationId, tenantId, roleId } = useParams() as { organizationId: string; tenantId: string; roleId: string }

  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const tenant = tenantQuery.data?.tenants?.[0]
  const roleQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const roles = roleQuery.data?.tenants?.flatMap((x) => x?.roles)?.filter(defined) ?? []
  const role = roles.find((x) => x?.id === roleId)
  const applicationsQuery = useSpecificTenantApplicationsQuery({ variables: { tenantId } })
  const applications = useMemo(() => (applicationsQuery.data?.tenants?.flatMap((x) => x?.applications) ?? []).filter(defined), [applicationsQuery.data])

  const loading = tenantQuery.loading || roleQuery.loading || applicationsQuery.loading
  const error = tenantQuery.error || roleQuery.error || applicationsQuery.error
  const refresh = () => {
    tenantQuery.refetch()
    roleQuery.refetch()
    applicationsQuery.refetch()
  }

  const [remoteState] = useLocalState<RoleEditorState | undefined>(() => {
    if (role && applications) {
      return {
        id: role.id ?? `-`,
        name: role.name ?? `-`,
        applications:
          applications
            .filter(defined)
            .map((app) => ({
              id: app.id ?? ``,
              title: app.application?.name ?? ``,
              permissions:
                app.application?.permissions?.filter(defined).map((perm) => ({
                  id: perm,
                  checked: role.permissions?.map((x) => x?.id ?? ``).includes(perm) ?? false,
                })) ?? [],
            }))
            .filter((x) => x.permissions.length > 0) ?? [],
      }
    }
  }, [role, applications])

  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    dispatch(actions.load(remoteState ?? initialState))
  }, [remoteState])

  const [doSave, saveMutation] = useEditRoleMutation()
  const handleSave = useCallback<ButtonAsyncClickHandler>(
    async (e) => {
      e.preventDefault()

      const updated = await doSave({
        variables: {
          tenantId,
          roleId: state.id,
          name: state.name,
          permissions: state.applications
            .flatMap((x) => x.permissions)
            .filter((x) => x.checked)
            .map((x) => x.id),
        },
      })

      if (updated.data?.tenant?.editRole) {
        alerts.push(alertText.updated(`Role`), `success`)
        navigate(`/organization/${organizationId}/tenant/${tenantId}/roles`)
      }
    },
    [state, tenantId]
  )

  const [deleting, setDeleting] = useState(false)
  const handleDeleteRequest = () => setDeleting(true)
  const handleDeleteCancel = () => setDeleting(false)
  const [deleteRole, deleteRoleMutation] = useDeleteRoleMutation()
  const handleDeleteConfirm = async () => {
    await deleteRole({ variables: { tenantId, roleId } })
    navigate(`/organization/${organizationId}/tenant/${tenantId}/roles`)
  }

  const permissionColumns = RoleEditRoute.useColumns(state.applications)

  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_ROLE_ADMIN', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}>
    <Wrapper title={t('Role Details')}>
      <DeleteConfirmDialog open={deleting} displayName={remoteState?.name ?? `-`} onClose={handleDeleteCancel} onContinue={handleDeleteConfirm} />
      <Box margin={2}>
        <Card>
          <CardHeader
            title={
              <Breadcrumbs separator={<IconBreadcrumbDivider fontSize="small" />}>
                <Link color="textPrimary" component={RouterLink} to={`/organizations`}>
                  {tenant?.organization?.name ?? `Organization`}
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/organization/${organizationId}/tenants`}>
                  {tenant?.name ?? `Tenant`}
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/organization/${organizationId}/tenant/${tenantId}/roles`}>
                  {remoteState?.name ?? `Role`}
                </Link>
                <Link color="textPrimary" component={RouterLink} to={`/organization/${organizationId}/tenant/${tenantId}/role/${roleId}`}>
                  Permissions
                </Link>
              </Breadcrumbs>
            }
          />
          <SavingSwitchPanel mutations={[saveMutation, deleteRoleMutation]}>
            <CardContent>
              <Form>
                <FormCaption title={'Role'} />
                <CardErrorContent onRetry={refresh} error={error?.message} />
                <MutationErrorMessage mutation={saveMutation} />
                <MutationErrorMessage mutation={deleteRoleMutation} />
                <FormContent>
                  <TextField
                    margin="dense"
                    label="Name"
                    value={state.name}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={(e) => dispatch(actions.changeName(e.target.value))}
                    autoComplete="off"
                  />
                </FormContent>
                <FormActions>
                  <Button variant="text" color="primary" onClick={handleDeleteRequest}>
                    Delete
                  </Button>
                  <Box flex={1}></Box>
                  <Button variant="text" color="primary" component={RouterLink} to={`/organization/${organizationId}/tenant/${tenantId}/roles`}>
                    Cancel
                  </Button>
                  <ButtonAsync variant="contained" color="primary" type="submit" onClick={handleSave} disabled={loading} enablePortal>
                    Apply Changes
                  </ButtonAsync>
                </FormActions>
              </Form>
            </CardContent>
            <CardHeader title="Permissions" />
            <CardContent>
              <Box marginTop={4}>
                {state.applications.length === 0 && (
                  <Alert severity="info">
                    <Typography>
                      Tenant <strong>{tenant?.name}</strong> has no application assigned
                    </Typography>
                  </Alert>
                )}
                <Box margin={2}>
                  <RoleEditRoute.AppGrid style={{ ['--column-count']: permissionColumns.length } as any}>
                    {permissionColumns.map((column, x) => (
                      <RoleEditRoute.AppColumn key={x}>
                        {column.map((app) => (
                          <PermissionsCard
                            title={app.title}
                            checked={app.permissions.filter((x) => x.checked).map((x) => x.id)}
                            permissions={app.permissions.map((x) => x.id)}
                            onChange={({ list }) => dispatch(actions.toggleMultiple(list))}
                          >
                            {app.permissions.map(({ id, checked }) => (
                              <PermissionListItem key={id} permission={id} checked={checked} onChange={(args) => dispatch(actions.toggleOne(args))}>
                                {t(id)}
                              </PermissionListItem>
                            ))}
                          </PermissionsCard>
                        ))}
                      </RoleEditRoute.AppColumn>
                    ))}
                  </RoleEditRoute.AppGrid>
                </Box>
              </Box>
            </CardContent>
          </SavingSwitchPanel>
        </Card>
      </Box>
    </Wrapper>
    </Protected>
  )
}

RoleEditRoute.AppGrid = styled.div`
  display: grid;
  gap: ${spacing(5)};
  grid-template-columns: repeat(var(--column-count), 1fr);
`
RoleEditRoute.AppColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(5)};
  min-width: 0;
`
RoleEditRoute.useColumns = <T extends unknown>(items: T[]) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const count = lg ? 3 : sm ? 2 : 1

  const columns = Array(count)
    .fill(null)
    .map((_, i) => items.filter((_, j) => j % count === i))

  return columns
}

const DeleteConfirmDialog = ({
  open,
  displayName,
  onClose,
  onContinue,
}: {
  open: boolean
  displayName: string
  onClose: () => void
  onContinue: () => Promise<any>
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove role <code>{displayName}</code>? This action applies immediatly and cannot be reversed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <ButtonAsync onClick={() => onContinue()} variant="contained" color="secondary">
          Delete Role
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  )
}
