import { AppBar as MaterialAppBar, Box, IconButton, makeStyles, Toolbar } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { AppDefinition, MAppsButton, MEnvironment } from '@mprise/react-ui'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useAppsQuery } from '../graphql/generated'
import { TenantSelectButton } from '../tenant/select-button'

export const AppBar = ({ title, onToggleDrawer }: { title: string; onToggleDrawer: () => void }) => {
  const classes = useStyles()

  const appsQuery = useAppsQuery({
    fetchPolicy: `cache-first`,
  })
  const apps = (appsQuery.data?.my?.tenant?.applications ?? [])
    .map((x) => x?.application)
    .filter((x) => x?.id && x.name && x.publicUrl)
    .map(
      (x): AppDefinition => ({
        id: x!.id!,
        name: x!.name!,
        url: x!.publicUrl!,
        logoUrl: x?.logoUrl ?? 'https://work-dev.agriware.cloud/logo192.png', // fallback logo
      })
    )

  return (
    <>
      <Helmet title={title} />
      <MaterialAppBar position="sticky">
        <Toolbar className={classes.toolBar}>
          <IconButton aria-label="open drawer" edge="start" onClick={onToggleDrawer} className={classes.menuButton} color="inherit">
            <MenuIcon />
          </IconButton>
          <TenantSelectButton />
          <Box flex="1 1 auto" />
          <span className={classes.environment}> <MEnvironment/></span>
          <Box>{apps.length ? <MAppsButton apps={apps} /> : null}</Box>
        </Toolbar>
      </MaterialAppBar>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: 'flex',
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  environment: {
    color: 'rgba(0, 0, 0, 0.54)',
    borderRight: '1px solid rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    display: 'block',
    lineHeight: '1em',
    paddingRight: '.8rem'
  }
}))
