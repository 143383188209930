import { Box, Card, CardHeader, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { AppDefinition, MColor, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import React from 'react'
import { useAppsQuery } from '../graphql/generated'
import { Wrapper } from '../layout/wrapper'

export const AppsTiles = () => {
  const classes = AppsTiles.useStyles()
  const appsQuery = useAppsQuery({
    fetchPolicy: `cache-first`
  })
  const apps = (appsQuery.data?.my?.tenant?.applications ?? [])
    .map(x => x?.application)
    .filter(x => x?.id && x.name && x.publicUrl)
    .map(
      (x): AppDefinition => ({
        id: x!.id!,
        name: x!.name!,
        url: x!.publicUrl!,
        logoUrl: x?.logoUrl ?? 'https://work-dev.agriware.cloud/logo192.png' // fallback logo
      })
    )
  return (
    <Wrapper title='Apps'>
      <Box margin={2}>
        <Card>
          <CardHeader title='Apps' />
          <MFlexBlock wrap='wrap' gap={2} padding={2}>
            {apps.map(app => (
              <a key={app.id} className={classes.link} href={app.url} target='_blank'>
                <MFlexBlock width={50} height={35} bgColor={MColor.paper} variant='rounded' vertical justifyContent='space-evenly' alignItems='center'>
                  <img alt={`${app.name} Logo`} className={classes.logo} src={app.logoUrl} />
                  <MText className={classes.name} textVariant='small bold' textColor={MTextColor.shadow} block>
                    {app.name}
                  </MText>
                </MFlexBlock>
              </a>
            ))}
            {appsQuery.called && !appsQuery.loading && !apps.length && (
              <Alert severity='error'>You have no access to any application, please contact your system administrator to grant you access. </Alert>
            )}
          </MFlexBlock>
        </Card>
      </Box>
    </Wrapper>
  )
}
AppsTiles.useStyles = makeStyles(theme => ({
  link: {
    display: `block`,
    textDecoration: `none`
  },
  logo: {
    width: `5rem`,
    alignSelf: 'center'
  },
  name: {
    textAlign: `center`
  }
}))
