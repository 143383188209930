import { Box, Button, Typography } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import React from 'react'
import { useMe } from '../shared/useMe'
import { useAllTenantsQuery } from '../graphql/generated'
import { useTenantSelect } from './select'
import { actions } from './select-state'

export const TenantSelectButton = () => {
  const me = useMe()
  const { state, dispatch } = useTenantSelect()
  const tenantQuery = useAllTenantsQuery()
  const tenant = tenantQuery.data?.tenants?.find((x) => x?.id === state.selected?.tenantId)
  return me.isOrganizationAdmin ? (
    <Button variant="contained" endIcon={<ArrowDropDownIcon />} color="primary" disableElevation onClick={() => dispatch(actions.open())}>
      {tenant?.name ?? state.selected?.tenantId ?? `Select a tenant`}
    </Button>
  ) : (
    <Box paddingX={2}>
      <Typography color="inherit" variant="button">
        {me.tenantName}
      </Typography>
    </Box>
  )
}
