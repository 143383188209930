import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { MFlexBlock, MPortalDialog, MText, MTextColor } from '@mprise/react-ui'
import { ButtonAsyncClickHandler } from '../button/async'
import { namedOperations, useDeleteUserMutation, useSpecificTenantUsersQuery } from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'

export const UserDeleteDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId, tenantId, userId } = useParams() as { organizationId: string; tenantId: string; userId: string }

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()
  const [deleteUser, deleteUserMutation] = useDeleteUserMutation()

  const tenantUsersQuery = useSpecificTenantUsersQuery({
    variables: { filter: { tenant: { id: { eq: tenantId } } }, skip: 0, take: 25, search: '' },
    fetchPolicy: 'cache-and-network'
  })

  const save: ButtonAsyncClickHandler = async (e) => {
    e.preventDefault()
    const org = await deleteUser({
      variables: {
        tenantId: tenantId,
        userId: +userId
      },
    })
    tenantUsersQuery.refetch()
    if (org.data?.tenant?.deleteUser) {
      alerts.push(alertText.deleted('User'), 'success')
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/users`, { replace: true })
  }

  return (
    <>
      <MPortalDialog.Dialog minWidth="sm" open onClose={handleClose}>
        <SavingSwitchPanel mutations={[deleteUserMutation]}>
          <MPortalDialog.Form onSubmit={save}>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t(`Delete User`)}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MPortalDialog.Content>
              <Box paddingX={2}>
                <MutationErrorMessage mutation={deleteUserMutation} />
                <MText block textVariant="content" textColor={MTextColor.dark}>{`Press the button to delete the user.`}</MText>
              </Box>
            </MPortalDialog.Content>
            <MPortalDialog.Footer>
              <MFlexBlock justifyContent="flex-end">
                <MPortalDialog.SubmitButton>Delete User</MPortalDialog.SubmitButton>
              </MFlexBlock>
            </MPortalDialog.Footer>
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
    </>
  )
}
