import { Box, Button, CircularProgress, Divider, Grid, Paper } from '@material-ui/core'
import { MText, MTextColor } from '@mprise/react-ui'
import React from 'react'
import { RouterLink } from '../shared/router-link'
import Logo from '../layout/logo-normal.svg'

export const LockedLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid container style={{ height: `100vh` }}>
      <Grid item sm={4} style={{ backgroundColor: `#009a44` }} />
      <Grid item xs={12} sm={8} style={{ backgroundColor: `#ffffff`, boxShadow: `0 0 10px rgba(0,0,0,0.8)` }}>
        <Box margin={4}>
          <Box maxWidth={200} margin="auto">
            <img src={Logo} alt="Mprise Agriware Logo" />
          </Box>
        </Box>
        <Divider />
        <Box margin={4} marginX={{ sm: 8, lg: 24 }}>
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

LockedLayout.Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box marginY={4}>
      <MText block textVariant="header" textColor={MTextColor.header}>
        {children}
      </MText>
    </Box>
  )
}

LockedLayout.Loading = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box marginY={4}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box flexShrink={0} padding={1}>
          <CircularProgress />
        </Box>
        <Box flexShrink={0} padding={1}>
          <MText block>{children}</MText>
        </Box>
      </Box>
    </Box>
  )
}

export type LayoutAction =
  | {
      type: 'submit'
      children: React.ReactNode
    }
  | {
      type: 'url'
      url: string
      children: React.ReactNode
    }
  | {
      type: 'redirect'
      url: string
      children: React.ReactNode
    }

const renderLayoutAction = (action: LayoutAction, primary: boolean) => {
  if (action.type === 'submit') {
    return (
      <Button variant={primary ? `contained` : `outlined`} size="large" color={primary ? `primary` : `default`} fullWidth type="submit">
        {action.children}
      </Button>
    )
  }
  if (action.type === 'url') {
    return (
      <Button
        component={RouterLink}
        to={action.url}
        variant={primary ? `contained` : `outlined`}
        size="large"
        color={primary ? `primary` : `default`}
        fullWidth
      >
        {action.children}
      </Button>
    )
  }
  if (action.type === 'redirect') {
    return (
      <Button component="a" href={action.url} variant={primary ? `contained` : `outlined`} size="large" color={primary ? `primary` : `default`} fullWidth>
        {action.children}
      </Button>
    )
  }
  throw Error()
}

LockedLayout.Actions = ({ left, right }: { left?: LayoutAction; right?: LayoutAction }) => {
  return (
    <Box marginY={4}>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          {left && renderLayoutAction(left, false)}
        </Grid>
        <Grid item sm={6} xs={12}>
          {right && renderLayoutAction(right, true)}
        </Grid>
      </Grid>
    </Box>
  )
}

LockedLayout.Footer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box marginY={4}>
      <Paper variant="outlined">
        <Box margin={1}>
          <MText block textVariant="content" textColor={MTextColor.header}>
            {children}
          </MText>
        </Box>
      </Paper>
    </Box>
  )
}

LockedLayout.Row = ({ children }: { children: React.ReactNode }) => {
  return <Box marginY={4}>{children}</Box>
}

LockedLayout.Message = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box marginY={4}>
      <MText block textVariant="content" textColor={MTextColor.dark}>
        {children}
      </MText>
    </Box>
  )
}
