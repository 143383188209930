import { Badge, Card, Collapse, IconButton, List, Switch } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useCallback, useMemo, useState } from 'react'
import { FormCaption } from '../../shared/form'

export interface PermissionsCardProps {
  readonly title: string
  readonly checked: string[]
  readonly permissions: string[]
  readonly children: React.ReactNode
  readonly onChange: (args: { list: Array<{ permission: string; checked: boolean }> }) => void
}

export const PermissionsCard = ({ title, checked, permissions, children, onChange }: PermissionsCardProps) => {
  const checkedAll = useMemo(() => hasCheckedAll(permissions, checked), [permissions, checked])
  const checkedSome = useMemo(() => hasCheckedSome(permissions, checked), [permissions, checked])
  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChange({ list: permissions.map((permission) => ({ permission, checked: e.target.checked })) })
    },
    [permissions, checked, onChange]
  )
  const [open, setOpen] = useState(checked.length > 0)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  return (
    <Card>
      <FormCaption
        onClick={open ? undefined : handleOpen}
        title={
          <>
            <Badge badgeContent={checked.length} color="primary" invisible={open || checked.length === 0} style={{ paddingRight: `1rem` }}>
              {title}
            </Badge>
          </>
        }
        icon={
          open ? (
            <IconButton onClick={handleClose}>
              <ExpandLessIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleOpen}>
              <ExpandMoreIcon />
            </IconButton>
          )
        }
        action={<Switch color={checkedAll ? `secondary` : `default`} checked={checkedSome} onChange={handleChange} />}
      />
      <Collapse in={open}>
        <List dense>{children}</List>
      </Collapse>
    </Card>
  )
}

const hasCheckedAll = (permissions: string[], checked: string[]) => permissions.every((x) => checked.includes(x))
const hasCheckedSome = (permissions: string[], checked: string[]) => permissions.some((x) => checked.includes(x))
