import { Box, Grid, TextField } from '@material-ui/core'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { namedOperations, SpecificTenantRolesQuery, useCreateServiceMutation, useSpecificTenantRolesQuery } from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { MultiSelectListBox } from '../shared/multiselect-listbox'
import { defined, NotOptional } from '../shared/typescript'
import { SetProperty, useLocalState } from '../utils'

type Role = NotOptional<NotOptional<NotOptional<NotOptional<NotOptional<SpecificTenantRolesQuery['tenants']>[0]>['roles']>[0]>>

interface ServiceForm {
  displayName: string
  roles: Array<{ id: string; name: string }>
}

export const ServiceNewDialog = () => {
  const { t } = useTranslation()
  const { organizationId, tenantId } = useParams() as { organizationId: string; tenantId: string }
  const navigate = useNavigate()

  const [form, setForm] = useLocalState<ServiceForm>(
    () => ({
      displayName: ``,
      roles: [],
    }),
    [organizationId, tenantId]
  )

  const [createService, createServiceMutation] = useCreateServiceMutation({ refetchQueries: [namedOperations.Query.SpecificTenant] })

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const roleQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const roles = roleQuery.data?.tenants?.flatMap((x) => x?.roles ?? []) ?? []
  const roleSearch = ServiceNewDialog.useRoleSearch({ tenantId })

  const save: ButtonAsyncClickHandler = async (e) => {
    e.preventDefault()

    const created = await createService({
      variables: {
        tenantId,
        name: form.displayName,
        roleIds: form.roles.map((x) => x.id),
      },
    })

    const serviceId = created.data?.tenant?.createService?.id
    if (serviceId) {
      alerts.push(alertText.created(`Service`), `success`)
      navigate(`/organization/${organizationId}/tenant/${tenantId}/service/${serviceId}/tokens`, { replace: true })
    }
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/services`, { replace: true })
  }

  return (
    <MPortalDialog.Dialog minWidth="sm" open onClose={handleClose}>
      <SavingSwitchPanel mutations={[createServiceMutation]}>
        <MPortalDialog.Form onSubmit={save}>
          <MPortalDialog.Header onClose={handleClose}>
            <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              {t(`New Service`)}
            </MFlexBlock>
          </MPortalDialog.Header>
          <MPortalDialog.Content>
            <Box paddingX={2}>
              <MutationErrorMessage mutation={createServiceMutation} />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={form.displayName}
                    onChange={(e) => setForm(SetProperty(`displayName`, e.target.value))}
                    autoFocus
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiSelectListBox
                    label="Roles"
                    items={roles.map((x) => ({
                      id: x?.id ?? `-`,
                      name: x?.name ?? `-`,
                      secondary: `${x?.permissions?.length ?? 0} permissions`,
                    }))}
                    selected={form?.roles?.map((x) => x?.id ?? `TODO`) ?? []}
                    {...roleSearch}
                    onChange={(v) =>
                      setForm(
                        SetProperty(
                          `roles`,
                          v.map((x) => ({ id: String(x), name: `TODO` }))
                        )
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </MPortalDialog.Content>
          <MPortalDialog.Footer>
            <MFlexBlock justifyContent="flex-end">
              <MPortalDialog.SubmitButton>Create Service</MPortalDialog.SubmitButton>
            </MFlexBlock>
          </MPortalDialog.Footer>
        </MPortalDialog.Form>
      </SavingSwitchPanel>
    </MPortalDialog.Dialog>
  )
}

ServiceNewDialog.useRoleSearch = ({ tenantId }: { tenantId: string }) => {
  const query = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const [search, setSearch] = useState(``)

  const matchesSearch = makeWildcard(search)
  const suggesions =
    query.data?.tenants
      ?.flatMap((x) => x?.roles)
      .filter(defined)
      .map((x) => ({ id: x.id ?? ``, name: x.name ?? `` }))
      .filter((x) => matchesSearch(x.name)) ?? []

  return {
    search,
    onSearch: setSearch,
    suggestions: suggesions,
  }
}

const makeWildcard = (text: string) => {
  const toWildcard = (text: string) =>
    text
      .replace(/[-[\]{}()+.,\\^$|#\s]/g, `\\$&`)
      .replace(/\?/g, `.`)
      .replace(/\*/g, `.*`)

  const toMatchSomewhere = (text: string) => `(?=.*${text})`

  const r = new RegExp(`^${text.split(/\s+/).map(toWildcard).map(toMatchSomewhere).join(``)}`, `i`)
  return (text: string) => !!text.match(r)
}
