import { MField, MText } from '@mprise/react-ui'
import { FieldProps } from 'formik'
import React from 'react'

export const MFieldConnector = ({
  children,
  scrollableX,
  label,
  ...f
}: FieldProps & { label?: React.ReactNode; scrollableX?: boolean; children: React.ReactNode }) => (
  <MField
    name={f.field.name}
    label={label ? <MText block>{label}</MText> : null}
    value={f.field.value}
    vertical
    scrollableX={scrollableX}
    onChange={(value: any) => {
      f.form.setFieldValue(f.field.name, value)
    }}
  >
    {children}
  </MField>
)
