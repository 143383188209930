import { CircularProgress, Fade } from '@material-ui/core'
import { MColor, MFlexBlock, MText, px } from '@mprise/react-ui'
import React from 'react'
import { SwitchTransition } from 'react-transition-group'

export const LoadingSwitchPanel = ({ loading, children }: { loading: boolean | React.ReactNode; children: React.ReactNode }) => {
  return (
    <SwitchTransition>
      <Fade key={Number(loading)}>
        {loading ? (
          <MFlexBlock justifyContent="center" alignItems="center" padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color="inherit" size={px(48)} />
            </MText>
            {!loading || loading === true ? null : (
              <MText block textColor={MColor.primary}>
                {loading}
              </MText>
            )}
          </MFlexBlock>
        ) : (
          <div>{children}</div>
        )}
      </Fade>
    </SwitchTransition>
  )
}
