import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MColor, MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsync, ButtonAsyncClickHandler } from '../button/async'
import {
  Maybe,
  TenantRole,
  TenantRolePermission,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useCosmosResourcesQuery,
  useSpecificTenantRolesQuery,
  useSpecificTenantUsersQuery,
  useUpdateCosmosResourceMutation,
  useUpdateUserMutation,
  usePostgresResourcesQuery,
  useUpdatePostgresResourceMutation,
} from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage, QueryErrorMessage } from '../shared/apollo'
import { MultiSelectListBox } from '../shared/multiselect-listbox'
import { RouterLink } from '../shared/router-link'
import { defined, Optional } from '../shared/typescript'
import { useMe } from '../shared/useMe'
import { SetProperty, useLocalState } from '../utils'

type UserForm = {
  email: string
  activeUntil: Maybe<string>
  roles: Array<Pick<TenantRole, 'id' | 'name'>>
  resource: Maybe<string>
  microserviceResource: Maybe<string>
}

export const UserEditDialog = () => {
  const me = useMe()
  const navigate = useNavigate()
  const { organizationId, userId, tenantId } = useParams() as { organizationId: string; tenantId: string; userId: string }

  const isUserAdmin = true //TODO  me.isUserAdmin
  const isMyself = me.tenantUserId === userId //TODO subjectId === userId
  const denyEdit = false //TODO !me.isUserAdmin
  const tenantRolesQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const tenantUsersQuery = useSpecificTenantUsersQuery({
    variables: {
      skip: 0,
      take: 1,
      search: '',
      filter: {
        tenant: { id: { eq: tenantId } },
        userId: { eq: userId },
      },
    },
  })
  const users = tenantUsersQuery.data?.users?.items?.filter(defined) ?? []
  const user = users?.find(x => x?.id === userId)

  const roles = tenantRolesQuery.data?.tenants?.flatMap(x => x?.roles ?? []) ?? []
  const resourcesQuery = useCosmosResourcesQuery({ variables: { tenantId } })
  const [updateCosmosResource] = useUpdateCosmosResourceMutation()

  const microserviceResourcesQuery = usePostgresResourcesQuery({ variables: { tenantId } })
  const [updateMicroserviceResource] = useUpdatePostgresResourceMutation()

  const [form, setForm] = useLocalState<UserForm>(
    () => ({
      email: user?.account?.email ?? ``,
      activeUntil: user?.activeUntil ?? null,
      roles: user?.roles?.filter(defined) ?? [],
      resource:
        resourcesQuery.data?.cosmosResources?.find(res => res?.externalUserId === user?.account?.subjectId)?.name ||
        null,
      microserviceResource:
        microserviceResourcesQuery.data?.postgresResources?.find(
          res => res?.externalUserId === user?.account?.subjectId
        )?.name || null,
    }),
    [user, resourcesQuery, microserviceResourcesQuery]
  )

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [updateUser, updateUserMutation] = useUpdateUserMutation()
  const [overwriteResource, setOverwriteResource] = useState(false)

  let resourceId =
    resourcesQuery.data?.cosmosResources?.find(res => res?.externalUserId === user?.account?.subjectId)?.id || null
  let selectedResource = resourcesQuery.data?.cosmosResources?.find(r => r!.name === form.resource)
  let existingResource =
    selectedResource &&
    selectedResource?.externalUserId &&
    selectedResource.externalUserId !== user?.account?.subjectId!

  let previousMsResourceId =
    microserviceResourcesQuery.data?.postgresResources?.find(res => res?.externalUserId === user?.account?.subjectId)
      ?.id || null
  let selectedMsResource = microserviceResourcesQuery.data?.postgresResources?.find(
    r => r!.name === form.microserviceResource
  )
  let existingMsResource =
    selectedMsResource &&
    selectedMsResource?.externalUserId &&
    selectedMsResource.externalUserId !== user?.account?.subjectId!

  const save: ButtonAsyncClickHandler = async e => {
    e.preventDefault()

    if ((existingResource || existingMsResource) && !overwriteResource) {
      alerts.push('Selected resource already assigned to another User. Please check confirm box to continue.', 'error')
      return
    }

    const newResourceId = selectedResource?.id!

    // Clear resource
    if (!selectedResource && resourceId) {
      const updated = await updateCosmosResource({
        variables: { tenantId, resourceId, newResourceId: '', subjectId: user?.account?.subjectId! },
      })

      if (!updated.data?.updateCosmosResource) {
        alerts.push('Error when unassigning resource.', 'error')
        handleClose()
      }
    }

    // New resource
    if (!resourceId && selectedResource) {
      const updated = await updateCosmosResource({
        variables: { tenantId, resourceId: newResourceId, newResourceId, subjectId: user?.account?.subjectId! },
      })

      if (!updated.data?.updateCosmosResource) {
        alerts.push('Error when assigning resource.', 'error')
        handleClose()
      }
    }

    // Update resource
    if (resourceId && selectedResource) {
      const deleted = await updateCosmosResource({
        variables: { tenantId, resourceId, newResourceId: '', subjectId: user?.account?.subjectId! },
      })
      const updated = await updateCosmosResource({
        variables: { tenantId, resourceId: newResourceId, newResourceId, subjectId: user?.account?.subjectId! },
      })

      if (!deleted.data?.updateCosmosResource || !updated.data?.updateCosmosResource) {
        alerts.push('Error when unassigning resource.', 'error')
        handleClose()
      }
    }

    // Microservice/Postgres resource
    const newMsResourceId = selectedMsResource?.id ?? null
    if ((previousMsResourceId || newMsResourceId) && previousMsResourceId !== newMsResourceId) {
      // Clear current userId from previous resource
      if (previousMsResourceId) {
        const updated = await updateMicroserviceResource({
          variables: { tenantId, resourceId: previousMsResourceId, subjectId: '' },
        })

        if (!updated.data?.updatePostgresResource) {
          alerts.push('Error when unassigning resource.', 'error')
          handleClose()
        }
      }
      // Add current userId as externalUserId to new resource
      if (newMsResourceId) {
        const updated = await updateMicroserviceResource({
          variables: { tenantId, resourceId: newMsResourceId, subjectId: user?.account?.subjectId! },
        })

        if (!updated.data?.updatePostgresResource) {
          alerts.push('Error when assigning resource.', 'error')
          handleClose()
        }
      }
    }

    const updated = await updateUser({
      variables: {
        tenantId: tenantId,
        email: form.email!,
        roleIds: form.roles!.map(x => x?.id!),
        activeUntil: form.activeUntil,
      },
    })

    if (updated.data?.tenant?.updateUser) {
      alerts.push(alertText.updated('User'), 'success')
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/users`, { replace: true })
  }

  const [resetPassword, setResetPassword] = useState<'none' | 'question' | 'process'>('none')
  const onCancelPasswordReset = () => setResetPassword('none')
  const onStartPasswordReset = () => setResetPassword('question')
  const onContinuePasswordReset = () => setResetPassword('process')

  const [changePassword, setChangePassword] = useState(false)
  const onCancelChangePassword = () => setChangePassword(false)
  const onStartChangePassword = () => setChangePassword(true)

  const roleSearch = UserEditDialog.useRoleSearch({ tenantId })

  return (
    <>
      <ChangePasswordDialog
        open={changePassword}
        subjectId={user?.account?.subjectId ?? `-`}
        onClose={onCancelChangePassword}
      />
      <PasswordResetConfirmDialog
        onContinue={onContinuePasswordReset}
        onClose={onCancelPasswordReset}
        open={resetPassword === 'question'}
        displayName={form.email ?? ''}
      />
      <PasswordResetProcessDialog
        onClose={onCancelPasswordReset}
        open={resetPassword === 'process'}
        email={form.email ?? '-'}
      />

      <MPortalDialog.Dialog minWidth='sm' open onClose={handleClose}>
        <SavingSwitchPanel mutations={[updateUserMutation]}>
          <MPortalDialog.Form onSubmit={save}>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {'Edit User'}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MPortalDialog.Content>
              <Box paddingX={2} paddingTop={2}>
                <QueryErrorMessage query={[tenantRolesQuery, tenantUsersQuery]} />
                <MutationErrorMessage mutation={updateUserMutation} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      type='email'
                      label='Email'
                      fullWidth
                      disabled
                      value={form.email}
                      onChange={v => setForm(SetProperty('email', v.target.value))}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      label='Valid Period'
                      placeholder='User Active Until'
                      format='yyyy-MM-dd'
                      value={form.activeUntil ?? null}
                      disabled={denyEdit}
                      onChange={v => setForm(SetProperty('activeUntil', v as any /*TODO*/))}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={resourcesQuery.data?.cosmosResources?.map(x => x!.name)?.filter(defined) ?? []}
                      includeInputInList
                      value={form.resource ?? null}
                      onChange={(_, text) => setForm(SetProperty('resource', text as string))}
                      renderInput={params => <TextField {...params} autoFocus type='resource' label='Resource' />}
                      ListboxProps={{ style: { maxHeight: 500, overflow: 'auto' } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={
                        microserviceResourcesQuery.data?.postgresResources?.map(x => x!.name)?.filter(defined) ?? []
                      }
                      includeInputInList
                      value={form.microserviceResource ?? null}
                      onChange={(_, text) => setForm(SetProperty('microserviceResource', text as string))}
                      renderInput={params => <TextField {...params} type='resource' label='Resource MS Apps' />}
                      ListboxProps={{ style: { maxHeight: 500, overflow: 'auto' } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MultiSelectListBox
                      label='Roles'
                      items={roles.map(x => ({
                        id: x?.id ?? `-`,
                        name: `${x?.name ?? `-`}`,
                        secondary: `${x?.permissions?.length ?? 0} permissions`,
                      }))}
                      disabled={denyEdit}
                      selected={form?.roles?.map(x => x?.id ?? ``) ?? []}
                      onChange={v =>
                        setForm(
                          SetProperty(
                            'roles',
                            v.map(x => ({ id: String(x), name: 'TODO' }))
                          )
                        )
                      }
                      {...roleSearch}
                    />
                  </Grid>
                  {(existingResource || existingMsResource) && !overwriteResource && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            checked={overwriteResource}
                            onChange={() => setOverwriteResource(!overwriteResource)}
                          />
                        }
                        label='Click here to confirm the selected resource; it is already assigned to another user. Alternatively, pick a different resource.'
                        name='confirmChangeResource'
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </MPortalDialog.Content>
            <Box display='flex' justifyContent='flex-end' bgcolor={MColor.paper} padding={2}>
              {isMyself && (
                <Button
                  variant='contained'
                  color='primary'
                  size='medium'
                  onClick={onStartChangePassword}
                  style={{ marginRight: 'auto' }}
                >
                  Change Password
                </Button>
              )}
              {!isMyself && isUserAdmin && (
                <Button
                  variant='contained'
                  color='primary'
                  size='medium'
                  onClick={onStartPasswordReset}
                  style={{ marginRight: 'auto' }}
                >
                  Reset Password
                </Button>
              )}
              {(isMyself || denyEdit) && (
                <Button
                  variant='contained'
                  color='secondary'
                  size='medium'
                  component={RouterLink}
                  replace
                  to={`/organization/${organizationId}/tenant/${tenantId}/users`}
                  style={{ marginRight: '8px' }}
                >
                  Back
                </Button>
              )}
              <Button
                variant='contained'
                color='primary'
                size='medium'
                onClick={save}
                type='submit'
                disabled={denyEdit}
              >
                Apply Changes
              </Button>
            </Box>
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
    </>
  )
}

const PasswordResetConfirmDialog = ({
  open,
  displayName,
  onClose,
  onContinue,
}: {
  open: boolean
  displayName: string
  onClose: () => void
  onContinue: () => void
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to reset the password for <code>{displayName}</code>? This action applies immediately
          and cannot be reversed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color='primary'>
          Cancel
        </Button>
        <Button onClick={() => onContinue()} color='primary'>
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PasswordResetProcessDialog = ({
  open,
  email,
  onClose,
}: {
  open: boolean
  email: string
  onClose: () => void
}) => {
  const classes = useStyles()
  const [resetPassword, resetPasswordMutation] = useResetPasswordMutation()
  useEffect(() => {
    if (open) {
      resetPassword({ variables: { email } })
    }
  }, [open])
  const loading = resetPasswordMutation.loading
  const error = resetPasswordMutation.error

  return (
    <Dialog open={open}>
      {/* {loading && <LinearProgress variant="query" />} */}
      <DialogTitle>Reset Password</DialogTitle>
      <Collapse in={!loading && !!error}>
        <DialogContent>
          <DialogContentText>Something went wrong, please try again.</DialogContentText>
          <Typography variant='caption'>Error message</Typography>
          <code className={classes.code}>{error?.message}</code>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Collapse>
      <Collapse in={!loading && !error}>
        <DialogContent>
          <DialogContentText>Email has been sent!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color='primary'>
            OK
          </Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  )
}

const ChangePasswordDialog = ({ open, onClose }: { open: boolean; subjectId: string; onClose: () => void }) => {
  const [oldPassword, setOldPassword] = useState(``)
  const [newPassword, setNewPassword] = useState(``)
  const [newPasswordRepeat, setNewPasswordRepeat] = useState(``)
  const [changePassword, changePasswordMutation] = useChangePasswordMutation()
  const onChangePassword = async () => {
    const changed = await changePassword({ variables: { oldPassword, newPassword } })
    if (changed.data?.success) {
      onClose()
    }
  }

  useEffect(() => {
    setOldPassword(``)
    setNewPassword(``)
    setNewPasswordRepeat(``)
  }, [open])

  const isValid = oldPassword && newPassword && newPassword === newPasswordRepeat

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>Fill in your current and new password to continue.</DialogContentText>
        <MutationErrorMessage mutation={changePasswordMutation} />
        <TextField
          margin='dense'
          label='Old password'
          type='password'
          fullWidth
          autoFocus
          value={oldPassword}
          onChange={({ target }) => setOldPassword(target.value)}
        />
        <TextField
          margin='dense'
          label='New password'
          type='password'
          fullWidth
          value={newPassword}
          onChange={({ target }) => setNewPassword(target.value)}
        />
        <TextField
          margin='dense'
          label='New password (repeat)'
          type='password'
          fullWidth
          value={newPasswordRepeat}
          onChange={({ target }) => setNewPasswordRepeat(target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color='primary'>
          Cancel
        </Button>
        <ButtonAsync onClick={onChangePassword} color='primary' disabled={!isValid}>
          Change password
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.up(`sm`)]: {
      minWidth: theme.breakpoints.width(`sm`) * 0.75,
    },
  },
  code: {
    display: `block`,
    userSelect: `all`,
    overflow: `auto`,
    backgroundColor: grey[100],
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  sample: {
    border: '1px solid red',
  },
}))

UserEditDialog.useRoleSearch = ({ tenantId }: { tenantId: string }) => {
  const query = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const [search, setSearch] = useState(``)

  const matchesSearch = makeWildcard(search)
  const suggesions =
    query.data?.tenants
      ?.flatMap(x => x?.roles)
      .filter(defined)
      .map(x => ({
        id: x.id ?? ``,
        name: `${x.name ?? ``}${
          minAvailable(x?.permissions) === null ? `` : ` (${minAvailable(x?.permissions)} seats available)`
        }`,
      }))
      .filter(x => matchesSearch(x.name)) ?? []

  return {
    search,
    onSearch: setSearch,
    suggestions: suggesions,
  }
}

const makeWildcard = (text: string) => {
  const toWildcard = (text: string) =>
    text
      .replace(/[-[\]{}()+.,\\^$|#\s]/g, `\\$&`)
      .replace(/\?/g, `.`)
      .replace(/\*/g, `.*`)

  const toMatchSomewhere = (text: string) => `(?=.*${text})`

  const r = new RegExp(`^${text.split(/\s+/).map(toWildcard).map(toMatchSomewhere).join(``)}`, `i`)
  return (text: string) => !!text.match(r)
}

const minAvailable = (input: Optional<Array<Optional<Pick<TenantRolePermission, 'licenseAvailable'>>>>) =>
  input?.reduce<null | number>((s, n) => min(s, n?.licenseAvailable ?? null), null) ?? null

const min = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return null
  } else if (a === null || b === null) {
    return a ?? b
  } else {
    return Math.min(a, b)
  }
}
