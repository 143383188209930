import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useMField } from '@mprise/react-ui'
import { useUniqueId } from '@mprise/react-ui/dist/internals'
import React from 'react'

export const MFieldAutoComplete = <T extends unknown>({ items, label, group }: { items: T[]; label: (item: T) => string; group?: (item: T) => string }) => {
  const f = useMField()
  const uniqueId = useUniqueId(`autocomplete`)
  // return (
  //   <FormControl>
  //     <InputLabel htmlFor={uniqueId}>Grouping</InputLabel>
  //     <Select id={uniqueId} value={f.value ?? null} onChange={(_, v) => f.onChange?.(v)}>
  //       <MenuItem value="">
  //         <em>None</em>
  //       </MenuItem>
  //       <ListSubheader>Category 2</ListSubheader>
  //     </Select>
  //   </FormControl>
  // )
  return (
    <Autocomplete<T, false, false, false>
      id={f.id ?? uniqueId}
      selectOnFocus
      options={items}
      getOptionLabel={label}
      groupBy={group}
      value={f.value ?? null}
      onChange={(_, v) => f.onChange?.(v)}
      renderInput={(params) => <TextField {...params} name={f.name ?? uniqueId} autoComplete="off" autoCapitalize="off" autoCorrect="off" />}
    />
  )
}
