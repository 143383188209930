import { Box, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, TextField } from '@material-ui/core'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import { MAvatar } from '@mprise/react-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAllTenantsQuery } from '../graphql/generated'
import { useTenantSelect } from './select'
import { actions, selectTenantSearch } from './select-state'

export const TenantSelectDialog = () => {
  const { state, dispatch } = useTenantSelect()
  const tenantQuery = useAllTenantsQuery({ skip: state.dialog })
  const [search, setSearch] = useState(``)
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => setSearch(e.target.value), [])
  const navigate = useNavigate()
  const HandleSelect = (tenant: { id?: string | null; organizationId?: string | null }) => {
    return () => {
      dispatch(actions.select({ organizationId: tenant.organizationId ?? ``, tenantId: tenant.id ?? `` }))
      navigate(`/organization/${tenant.organizationId}/tenant/${tenant.id}/users`)
    }
  }
  useEffect(() => {
    if (tenantQuery.data) {
      dispatch(actions.load(tenantQuery.data?.tenants?.map((x) => ({ id: x!.id!, displayName: x!.name!, organizationId: x?.organization?.id })) ?? []))
    }
  }, [tenantQuery.data])
  return (
    <Dialog open={state.dialog} onClose={() => dispatch(actions.select(state.selected ?? { organizationId: ``, tenantId: `` }))}>
      <DialogTitle>Select a tenant</DialogTitle>
      <DialogContent>
        <TextField placeholder="Search" variant="outlined" fullWidth autoFocus value={search} onChange={handleSearch} autoComplete="off" />
        <Box minWidth={400} marginX={-3}>
          <List>
            {selectTenantSearch(state, search).map((tenant) => (
              <ListItem key={tenant.id} button onClick={HandleSelect(tenant)}>
                <ListItemAvatar>
                  <MAvatar.Badge seed={tenant.id ?? ``}>
                    <GroupWorkIcon />
                  </MAvatar.Badge>
                </ListItemAvatar>
                <ListItemText primary={tenant.displayName} />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
