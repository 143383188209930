import { Grid, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { MFlexBlock, MPortalDialog } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { useEditServiceMutation, useSpecificTenantQuery, useSpecificTenantRolesQuery, useSpecificTenantServicesQuery } from '../graphql/generated'
import { SavingSwitchPanel } from '../organization/saving-switch-panel'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { CardErrorContent } from '../shared/card-error-content'
import { FormContent } from '../shared/form'
import { MultiSelectListBox } from '../shared/multiselect-listbox'
import { defined } from '../shared/typescript'
import { SetProperty, useLocalState } from '../utils'

interface ServiceForm {
  displayName: string
  roles: Array<{ id: string; name: string }>
}

export const ServiceEditDialog = () => {
  const { organizationId, tenantId, serviceId } = useParams() as { organizationId: string; tenantId: string; serviceId: string }

  const tenantQuery = useSpecificTenantQuery({ variables: { tenantId } })
  const servicesQuery = useSpecificTenantServicesQuery({ variables: { tenantId } })
  const services = servicesQuery.data?.tenants?.[0]?.services?.filter(defined) ?? []
  const service = services.find(x => x?.id === serviceId)
  const roleQuery = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const roles = roleQuery.data?.tenants?.flatMap(x => x?.roles ?? []) ?? []

  const navigate = useNavigate()
  const [form, setForm] = useLocalState<ServiceForm>(
    () => ({
      displayName: service?.name ?? ``,
      roles: service?.roles?.filter(defined).map(x => ({ id: x.id ?? ``, name: `TODO` })) ?? []
    }),
    [service]
  )
  const [editService, editServiceMutation] = useEditServiceMutation()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const save: ButtonAsyncClickHandler = async e => {
    e.preventDefault()

    const edited = await editService({
      variables: {
        tenantId,
        serviceId,
        name: form.displayName,
        roleIds: form.roles?.map(x => x?.id ?? ``) ?? []
      }
    })

    if (edited.data?.tenant?.editService) {
      alerts.push(alertText.updated(`Service`), `success`)
      handleClose()
    }
  }
  const handleClose = () => {
    navigate(`/organization/${organizationId}/tenant/${tenantId}/services`, { replace: true })
  }

  const roleSearch = ServiceEditDialog.useRoleSearch({ tenantId })

  return (
    <MPortalDialog.Dialog minWidth='sm' open onClose={handleClose}>
      <SavingSwitchPanel mutations={[editServiceMutation]}>
        <MPortalDialog.Form onSubmit={save}>
          <MPortalDialog.Header onClose={handleClose}>
            <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              {service?.name ?? `-`}
            </MFlexBlock>
          </MPortalDialog.Header>
          <MPortalDialog.Content>
            <CardErrorContent error={tenantQuery.error?.message} />
            {tenantQuery.error && (
              <FormContent>
                <Alert severity='error'>{tenantQuery.error?.message}</Alert>
              </FormContent>
            )}
            <MutationErrorMessage mutation={editServiceMutation} />
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField fullWidth InputLabelProps={{ shrink: true }} label='Service ID' value={serviceId} disabled />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label='Name'
                  value={form.displayName}
                  onChange={e => setForm(SetProperty(`displayName`, e.target.value))}
                  autoFocus
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectListBox
                  label='Roles'
                  items={roles.map(x => ({
                    id: x?.id ?? `-`,
                    name: x?.name ?? `-`,
                    secondary: `${x?.permissions?.length ?? 0} permissions`
                  }))}
                  selected={form?.roles?.map(x => x?.id ?? `TODO`) ?? []}
                  {...roleSearch}
                  onChange={v =>
                    setForm(
                      SetProperty(
                        `roles`,
                        v.map(x => ({ id: String(x), name: `TODO` }))
                      )
                    )
                  }
                />
              </Grid>
            </Grid>
          </MPortalDialog.Content>
          <MPortalDialog.Footer>
            <MFlexBlock justifyContent='flex-end'>
              <MPortalDialog.SubmitButton>Edit Service</MPortalDialog.SubmitButton>
            </MFlexBlock>
          </MPortalDialog.Footer>
        </MPortalDialog.Form>
      </SavingSwitchPanel>
    </MPortalDialog.Dialog>
  )
}

ServiceEditDialog.useRoleSearch = ({ tenantId }: { tenantId: string }) => {
  const query = useSpecificTenantRolesQuery({ variables: { tenantId } })
  const [search, setSearch] = useState(``)

  const matchesSearch = makeWildcard(search)
  const suggesions =
    query.data?.tenants
      ?.flatMap(x => x?.roles)
      .filter(defined)
      .map(x => ({ id: x.id ?? ``, name: x.name ?? `` }))
      .filter(x => matchesSearch(x.name)) ?? []

  return {
    search,
    onSearch: setSearch,
    suggestions: suggesions
  }
}

const makeWildcard = (text: string) => {
  const toWildcard = (text: string) =>
    text
      .replace(/[-[\]{}()+.,\\^$|#\s]/g, `\\$&`)
      .replace(/\?/g, `.`)
      .replace(/\*/g, `.*`)

  const toMatchSomewhere = (text: string) => `(?=.*${text})`

  const r = new RegExp(
    `^${text
      .split(/\s+/)
      .map(toWildcard)
      .map(toMatchSomewhere)
      .join(``)}`,
    `i`
  )
  return (text: string) => !!text.match(r)
}
