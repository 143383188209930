import { useState } from 'react'
import { useSelectiveSearch } from '../organization/SelectiveSearch'

export const knownLoginMethods = [
  { id: `pwd`, name: `Password` },
  { id: `aad`, name: `Azure AD` },
]

export const useLoginMethodSearch = () => {
  const [search, setSearch] = useState(``)
  const suggestions = useSelectiveSearch(search, knownLoginMethods, (x) => x.name)

  return {
    search,
    onSearch: setSearch,
    suggestions: suggestions,
  }
}
