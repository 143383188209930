import { LinearProgress } from '@material-ui/core'
import React, { Suspense } from 'react'

export const Wrapper = ({ children }: { title: string; children: React.ReactNode }) => {
  return (
    <main>
      <Suspense fallback={<LinearProgress />}>{children}</Suspense>
    </main>
  )
}
