import { Box, Card, CardHeader } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { FormattedDate, MFlex } from '@mprise/react-ui'
import React from 'react'
import { useLicenseTemplatesQuery } from '../graphql/generated'
import { IconConfigure } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { CardErrorContent } from '../shared/card-error-content'
import Protected from '../shared/protected-route'
import { defined } from '../shared/typescript'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'

export const LicenseTemplateListRoute = () => {
  const licenseQuery = useLicenseTemplatesQuery()
  const rows = licenseQuery.data?.licenseTemplates?.filter(defined).map((x) => ({
    url: `/licensetemplate/${x.id}`,
    id: x.id,
    name: x.name,
    permissions: x.permissions?.length,
    createdDate: new Date(x.createdDate /* HACK: remove date constructor when string is working again */),
    updatedDate: new Date(x.updatedDate /* HACK: remove date constructor when string is working again */),
  }))

  const loading = licenseQuery.loading
  const error = licenseQuery.error
  const refresh = () => {
    licenseQuery.refetch()
  }

  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_LICENSE_ADMIN','IDENTITYSERVER_ORGANIZATION_ADMIN']}>
    <Wrapper title="License Templates">
      <Box margin={2}>
        <Card>
          <CardHeader title="License Templates" />
          <CardErrorContent onRetry={refresh} error={error?.message} />
          <DataTable
            toolbar={
              <MFlex gap={2}>
                <TableRouteButton startIcon={<AddIcon />} to={`/licensetemplates/new`} children="Add New Template" />
                <TableRouteButton startIcon={<IconConfigure />} to={`/licensetemplates/configure`} children="Configure Permissions" />
              </MFlex>
            }
            columns={[
              { field: `name`, headerName: `Name`, flex: 1 },
              { field: `permissions`, headerName: `Permissions`, flex: 1 },
              { field: 'createdDate', headerName: `Created`, flex: 1, renderCell: (row) => <FormattedDate date={row.row.createdDate} /> },
              { field: 'updatedDate', headerName: `Last Change`, flex: 1, renderCell: (row) => <FormattedDate date={row.row.updatedDate} /> },
            ]}
            data={rows ?? []}
            refresh={refresh}
            loading={loading}
          />
        </Card>
      </Box>
    </Wrapper>
    </Protected>
  )
}
