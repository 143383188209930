import { MFieldInput } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { namedOperations, useCreateLicenseTemplateMutation } from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MFieldConnector } from '../shared/mfield-adapter'
import { FormikDialog } from '../shared/react-formik-dialog'
import { useLocalState } from '../utils'

export const LicenseTemplateNewDialog = () => {
  const navigate = useNavigate()

  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()

  const [createLicense] = useCreateLicenseTemplateMutation({ refetchQueries: [namedOperations.Query.LicenseTemplates] })
  const handleSave = async (form: typeof initialValues) => {
    if (form.name) {
      const created = await createLicense({
        variables: {
          name: form.name,
          permissions: form.permissions,
        },
      })

      if (created.data?.createLicenseTemplate) {
        alerts.push(alertText.created(`License Template`), `success`)
        navigate(`/licensetemplate/${created?.data?.createLicenseTemplate?.id}`, { replace: true })
      }
    }
  }

  const handleClose = () => {
    navigate(`/licensetemplates`, { replace: true })
  }
  const { t } = useTranslation()

  const [initialValues] = useLocalState(
    {
      name: ``,
      permissions: [],
    },
    []
  )

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSave}>
        <FormikDialog title="New License Template" submit="Create" onClose={handleClose} open>
          <Field component={MFieldConnector} name="name" label={t(`Template Name`)}>
            <MFieldInput autoComplete="off" autoFocus />
          </Field>
        </FormikDialog>
      </Formik>
    </>
  )
}
