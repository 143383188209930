import Alert from '@material-ui/lab/Alert'
import React, { useEffect, useState } from 'react'
import { LockedLayout } from './locked/layout'

export const Logout = () => {
  const [error, setError] = useState(``)
  useEffect(() => {
    const execute = async () => {
      const logoutId = getLogoutId(window.location.href)
      const res = await fetch(`/api/logout`, {
        method: `POST`,
        redirect: `follow`,
        headers: {
          'content-type': `application/json`,
        },
        body: JSON.stringify({
          logoutId,
        }),
      })
      if (res.redirected) {
        window.location.href = res.url
      } else if (res.ok) {
        const model = await res.json()
        window.location.href = model.postLogoutRedirectUrl ?? window.location.origin
      } else {
        setError(`Unauthorized`)
      }
    }
    execute()
  })

  if (error) {
    return (
      <LockedLayout>
        <LockedLayout.Message>
          <Alert severity="error">{error}</Alert>
        </LockedLayout.Message>
      </LockedLayout>
    )
  } else {
    return (
      <LockedLayout>
        <LockedLayout.Loading>Logging out...</LockedLayout.Loading>
      </LockedLayout>
    )
  }
}

const getLogoutId = (url: string) => {
  const params = Array.from(new URL(url).searchParams.entries())
  for (const [k, v] of params) {
    if (k.toLowerCase() === `logoutid`) {
      return v
    }
  }
}
