import { Box } from '@material-ui/core'
import { MFlexBlock, MPortalDialog, MText, MTextColor } from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonAsyncClickHandler } from '../button/async'
import { MyOrganizationsQuery, namedOperations, useDeleteOrganizationMutation } from '../graphql/generated'
import { Alerts } from '../shared/alerts'
import { MutationErrorMessage } from '../shared/apollo'
import { NotOptional } from '../shared/typescript'
import { SavingSwitchPanel } from './saving-switch-panel'
type OrganizationForm = NotOptional<NotOptional<MyOrganizationsQuery['organizations']>[0]>
const emptyOrganization: OrganizationForm = {
  id: ``,
  name: ``,
  admins: [],
}
export const OrganizationDeleteDialog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId } = useParams() as { organizationId: string }
  const [form, setForm] = useState(emptyOrganization)
  const alerts = Alerts.useAlert()
  const alertText = Alerts.useTranslation()
  const [deleteOrganization, deleteOrganizationMutation] = useDeleteOrganizationMutation()
  const save: ButtonAsyncClickHandler = async (e) => {
    e.preventDefault()
    const org = await deleteOrganization({
      refetchQueries: [namedOperations.Query.MyOrganizations],
      variables: {
        organizationId: organizationId,
      },
    })
    if (org.data?.organization?.delete) {
      alerts.push(alertText.updated(`Organization deleted`), `success`)
      handleClose()
    }
  }
  const handleClose = () => {
    navigate(`/organizations`, { replace: true })
  }
  return (
    <>
      <MPortalDialog.Dialog minWidth="sm" open onClose={handleClose}>
        <SavingSwitchPanel mutations={[deleteOrganizationMutation]}>
          <MPortalDialog.Form onSubmit={save}>
            <MPortalDialog.Header onClose={handleClose}>
              <MFlexBlock justifyContent="flex-start" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {t(`Delete Organization`)}
              </MFlexBlock>
            </MPortalDialog.Header>
            <MPortalDialog.Content>
              <Box paddingX={2}>
                <MText block textVariant="content" textColor={MTextColor.dark}>{`Press the button to delete the organization.`}</MText>
                <MutationErrorMessage mutation={deleteOrganizationMutation} />
              </Box>
            </MPortalDialog.Content>
            <MPortalDialog.Footer>
              <MFlexBlock justifyContent="flex-end">
                <MPortalDialog.SubmitButton>Delete Organization</MPortalDialog.SubmitButton>
              </MFlexBlock>
            </MPortalDialog.Footer>
          </MPortalDialog.Form>
        </SavingSwitchPanel>
      </MPortalDialog.Dialog>
    </>
  )
}

